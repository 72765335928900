import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getFlowManager } from '../../../../../libs/flow-manager';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { getUserService } from '../../../../../libs/user-service';

export default class Bookmark extends Component {
    constructor(name, root) {
        super(name, root);
        this.ctaEl = this._dEl('cta');
        this.favorite = null;
        this.flowManager = getFlowManager();
        this.trackingManager = getTrackingManager();

        this._addEventListeners();
        this._checkBookmark();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            () => {
                this._setBookmark();
            },
            this.ctaEl
        );
    }

    async _setBookmark() {
        //control if user is logged, if not open popup
        if ((await getUserService().getUser()) == null) {
            this.flowManager.startFlow({
                flowName: 'login-popup',
                flowSteps: [{ name: 'login-popup' }],
            });
            return;
        }

        if (this.favorite) {
            await getUserService().removeComplementaryData(this.root.dataset.path);
        } else {
            await getUserService().addComplementaryData(this.root.dataset.path);
        }

        const el = this.root.closest(`[data-card-type]`);
        if (el) {
            this._track(el.dataset.cardType);
        }

        this._checkBookmark();
    }

    async _checkBookmark() {
        this.cta =  getRegister().getClass(this.ctaEl);
        const data = await getUserService().checkComplementaryData(this.root.dataset.path);
        if (data) {
            this.favorite = data;
            this.cta.setIcon('ec-icon-bookmark-on');
        } else {
            this.favorite = null;
            this.cta.setIcon('ec-icon-bookmark-off');
        }
    }

    _track(type) {
        switch (type) {
            case 'ADVICE':
                this.trackingManager.track(this.root, {
                    event: 'ConsiglioPreferito',
                    CustomLink: (!this.favorite ? 'Aggiunta' : 'Rimozione') + ' consiglio dai preferiti',
                });
                break;
            case 'RECIPE':
                this.trackingManager.track(this.root, {
                    event: 'RicettaPreferita',
                    CustomLink: !this.favorite ? 'Aggiunta' : 'Rimozione' + ' ricetta dai preferiti',
                });
                break;
        }
    }
}



import './style.scss';
import Component from '../../../../../../libs/components/component';
import { getFirstChild } from '../../../../../../libs/utils';

export default class L1Layout extends Component {
    constructor(name, root) {
        super(name, root);
        this.items = this._dEl('item', true);
        this.metas = this._dEl('meta', true);
        this._addItemListeners();
        this._addEventListeners();
    }

    _addItemListeners() {
        this._addListener('hideLayoutItem', (event) => {
            const item = event.target.closest(this._el('item', true));
            if (!item) return;

            if (event.data.hide) {
                item.classList.add(this._elMod('item', 'hidden'));
            } else {
                item.classList.remove(this._elMod('item', 'hidden'));
            }
            event.stopPropagation();
        });
        this._addListener('removeLayoutItem', (event) => {
            const item = event.target.closest(this._el('item', true));
            if (!item) return;

            const child = getFirstChild(item);
            if (child != event.target) return;

            item.remove();
            event.stopPropagation();
        });
    }

    _addEventListeners() {
        this._addListener('insertFirstLayoutItem', (event) => {
            event.preventDefault();
            this._insertFirstLayoutItem(event.data);
            event.stopPropagation();
        });

        this._addListener('rl1flyerSwitchSpaceBottom', (event) => {
            const { action, htmlTarget } = event.data;
            if (!action || !htmlTarget) return;

            if (action === 'hide') {
                this.spacedBottom = htmlTarget.classList.value
                    .split(' ')
                    .find((element) => element.includes('rl1-layout__item--spaced-bottom'));

                if (this.spacedBottom != null) htmlTarget.classList.remove(this.spacedBottom);
            } else if (action === 'show') {
                if (
                    this.spacedBottom != null &&
                    !htmlTarget.classList.value.includes('rl1-layout__item--spaced-bottom')
                )
                    htmlTarget.classList.add(this.spacedBottom);
            }
            event.stopPropagation();
        });
    }

    _insertFirstLayoutItem({ content, spacingMods, shrinkDsk, shrinkDskContest }) {
        if (!content) return;
        const section = document.createElement('section');
        section.classList.add(this._el('item'));
        if (spacingMods) {
            for (const mod of spacingMods) {
                section.classList.add(this._elMod('item', mod));
            }
        }
        if (shrinkDsk) section.classList.add(this._elMod('item', 'shrinkDsk'));
        if (shrinkDskContest) section.classList.add(this._elMod('item', 'shrinkDskContest'));
        section.appendChild(content);
        this.root.prepend(section);
    }
}

import './style.scss';
import { getApiProvider } from '../../../../../libs/api-provider';
import ModalComponent from '../../../../../libs/components/modal-component';
import { getRegister } from '../../../../../libs/register';
import { getUserService } from '../../../../../libs/user-service';
import { getFlowManager } from '../../../../../libs/flow-manager';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getPoiType, storeData } from '../../../../../libs/pdv-utils';
import { closeLoader, emptyElement, openLoader } from '../../../../../libs/utils';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getStoreManager } from '../../../../../libs/store-manager';

export default class SelectPosStep2 extends ModalComponent {
    constructor(name, root) {
        super(name, root);
        this.flowManager = getFlowManager();
        this.userService = getUserService();
        this.register = getRegister();
        this.api = getApiProvider();
        this.storeManager = getStoreManager();

        this.RES_ITEM = this._el('resItem');
        this.BREAKPOINT_L = BREAKPOINTS.l;
        this.MAP_SELECTOR = '.rt251-select-pos-step2-map';
        this.SHOW_MAP_MOB = this._mod('showMapMob');
        this.NO_RESULTS = this._mod('noRes');
        this.SEARCH_EMPTY = this._mod('searchEmpty');
        this.PAD_BOX_SHADOW = 7;
        this.CARD_TITLE_SELECTOR = '.rt021-store-card__name';
        this.address = this._dEl('address');
        this.wrapper = this._dEl('wrapper');
        this.numRes = this._dEl('resHeadingFound')?.querySelector('span');
        this.resList = this._dEl('resList');
        this.mapMob = this._dEl('mapMob');
        this.id = this.root.getAttribute('id');
        this.selectBtn = this._dEl('select');
        this.showMapBtn = this._dEl('showMap');
        this.showListBtn = this._dEl('showList');
        this.stores = [];
        this.selectedStore = null;
        this.extendedVersion = this.root.hasAttribute('data-extended-version');

        /* init */
        this._showSearchEmpty();

        this._addEventListeners();
    }

    /* override */
    _onOpen() {
        super._onOpen();
        this._initMapMob();
        if (this.map) {
            this.map.init(this);
            /* add resize event handler only after initializing the map */
            if (this.extendedVersion) {
                this._addListener(
                    'resize',
                    () => {
                        const modalObj = this.modal;
                        if (!modalObj || !modalObj.rightContent) return;
                        if (window.innerWidth >= this.BREAKPOINT_L) {
                            modalObj.rightContent.append(this.mapEl);
                            this.mapEl = modalObj.getRoot().querySelector(this.MAP_SELECTOR);
                            modalObj.showRight();
                        } else {
                            this.mapMob?.append(this.mapEl);
                            this.mapEl = this.mapMob?.querySelector(this.MAP_SELECTOR);
                            modalObj.hideRight();
                            /* condition to avoid showing the map wrongfully when you started with the list in mobile, 
                            then rotated the screen and showed the map in desktop, and then rotated back into mobile */
                            if (this.root.classList.contains(this.SHOW_MAP_MOB)) this._showMobileMap();
                        }
                        if (this.selectedStoreId) this.selectPos(this.selectedStoreId);
                    },
                    window
                );
            }
        }
        if (this.selectedStoreId) this.selectPos(this.selectedStoreId);
    }

    /* override */
    _onClose() {
        super._onClose();
        if (this.map) this.map.destroy();
    }

    /* override */
    _onCancel() {
        super._onCancel();
        this.flowManager.complete();
    }


    _initMapMob() {
        if (!this.extendedVersion) return;
        this.mapEl = this.modal.getRoot().querySelector(this.MAP_SELECTOR);
        if (this.mapEl) {
            this.map = this.register.getClass(this.mapEl);
            if (window.innerWidth < this.BREAKPOINT_L) {
                this.mapMob.appendChild(this.mapEl);
                this.mapEl = this.mapMob?.querySelector(this.MAP_SELECTOR);
            }
        }
    }

    _addEventListeners() {
        this._addListener(
            'rcInputChanged',
            (event) => {
                this._loadPoi(event.data.value);
            },
            this.address
        );
        this._addListener(
            'click',
            async () => {
                await this.userService.setLastVisitedStore(this.selectedStore.anacanId);
                this._emit('selectedStore', this.selectedStore);
                this.flowManager.appendDataToFlow({
                    selectedStore: this.selectedStore,
                });
                this.flowManager.complete();
            },
            this.selectBtn
        );
        if (this.showMapBtn)
            this._addListener(
                'click',
                () => {
                    if (this.map) {
                        window.innerWidth >= this.BREAKPOINT_L ? this.modal.showRight() : this._showMobileMap();
                        this.map.panSelected();
                    }
                },
                this.showMapBtn
            );
        if (this.showListBtn)
            this._addListener(
                'click',
                () => {
                    this._showMobileMap(false);
                    this.selectPos(this.selectedStoreId);
                },
                this.showListBtn
            );
        this._addListener(
            'resize',
            () => {
                const selectBtnObj = this.register.getClass(this.selectBtn);
                window.innerWidth < this.BREAKPOINT_L ? selectBtnObj.setSize('small') : selectBtnObj.setSize('medium');
            },
            window
        );
        this._addListener(
            'clickedStoreCard',
            (event) => {
                const data = event.data;
                if (!data.storeId) return;
                this.setSelectedStoreId(data.storeId);
                this.selectPos(this.selectedStoreId, false);
                /* select on map */
                this.map?.selectPoi(data.storeId);
            },
            this.resList
        );
    }

    _showMobileMap(show = true) {
        if (show) {
            if (!this.root.classList.contains(this.SHOW_MAP_MOB)) this.root.classList.add(this.SHOW_MAP_MOB);
        } else {
            this.root.classList.remove(this.SHOW_MAP_MOB);
        }
    }

    setSelectedStoreId(anacanId) {
        this.selectedStoreId = anacanId;
    }

    selectPos(anacanId, scrollTo = true) {
        const storeEl = this.resList.querySelector(`[data-store-id="${anacanId}"]`);
        if (!storeEl) {
            console.warn(`cannot select store ${anacanId}`);
            return;
        }

        this.selectedResItem = this.resList.querySelector(`.${this.RES_ITEM}[data-store-id='${anacanId}']`);
        this.selectedStore = this.stores.filter((store) => store.anacanId == storeEl.dataset.storeId)[0];
        this._updateSelectedRes(scrollTo);

        this._enableSelectBtn();
    }

    _updateSelectedRes(scrollTo = true) {
        if (!this.selectedResItem || !this.selectedResItem.offsetTop) return;
        if (scrollTo) this.wrapper.scrollTop = this.selectedResItem.offsetTop - this.PAD_BOX_SHADOW;
        const resObj = this.register.getClass(this.selectedResItem);
        resObj.unselectCards();
        resObj.setSelected();
    }

    async _loadPoi(address) {
        if (!address || !address.geometry || !address.types) return;

        const data = {
            latitudine: `${address.geometry.location.lat()}`,
            longitudine: `${address.geometry.location.lng()}`,
            raggioRicerca: '15',
        };

        try {
            openLoader('main');
            this.stores = await this.api.retrievePointOfService(data);
            await this._addResults(this.stores);
        } catch (error) {
            console.error(error);
            this._showNoResults();
        } finally {
            closeLoader('main');
        }
    }

    async _addResults(results) {
        if (!results || results.length <= 0) {
            this._emptyResults();
            this._showNoResults();
            return;
        }
        this._emptyResults();

        for (const poi of results) {
            await this._addResultToList(poi);
        }
        this._disableStoreTitlesClick();

        this._updateNumRes(results.length);
        this._showResults();

        await this._addResultsToMap();
    }

    async _addResultsToMap() {
        if (!this.map) return;
        await this.map.addResults(this.stores);
    }

    _disableStoreTitlesClick() {
        [...this.resList.querySelectorAll(this.CARD_TITLE_SELECTOR)]?.forEach((storeTitle) => {
            this._addListener(
                'click',
                (e) => {
                    e.preventDefault();
                },
                storeTitle
            );
        });
    }

    _updateNumRes(numRes) {
        this.numRes.innerText = numRes;
    }

    async _addResultToList(poi) {
        const storeCardHtml = (await import('../rt021-store-card/rt021-store-card.html')).default;
        const storeCardData = storeData(poi, `${poi.anacanId}-store-card`);
        storeCardData.extraClasses = this.RES_ITEM;
        storeCardData.storeType = getPoiType(poi);
        storeCardData.simplified = true;
        storeCardData.hideFavorite = true;
        const storeCard = runTemplate(storeCardHtml, storeCardData);
        this.resList.appendChild(storeCard);
    }

    _showNoResults() {
        this.root.classList.remove(this.SEARCH_EMPTY);
        this.root.classList.add(this.NO_RESULTS);
    }

    _showSearchEmpty() {
        this.root.classList.remove(this.NO_RESULTS);
        this.root.classList.add(this.SEARCH_EMPTY);
    }

    _showResults() {
        this.root.classList.remove(this.SEARCH_EMPTY);
        this.root.classList.remove(this.NO_RESULTS);
    }

    _emptyResults() {
        emptyElement(this.resList);
    }

    _enableSelectBtn() {
        const selectBtnObj = this.register.getClass(this.selectBtn);
        selectBtnObj.setStatus('enabled');
    }
}



import './style.scss';
import Component from '../../../../../libs/components/component';
import { getDictionary } from '../../../../../libs/dictionary-provider';
import { BREAKPOINTS } from '../../../../../libs/constants';

export default class CardBanner extends Component {
    constructor(name, root) {
        super(name, root);
        this.dictionary = getDictionary();

        this.fullDateFormatter = new Intl.DateTimeFormat('it-IT', { day: 'numeric', month: 'long', year: 'numeric' });
        this.shortDateFormatter = new Intl.DateTimeFormat('it-IT', { day: 'numeric', month: 'long' });
        this.dayFormatter = new Intl.DateTimeFormat('it-IT', { day: 'numeric' });

        this.validFrom = this.root.dataset.validFrom;
        this.validTo = this.root.dataset.validTo;
        this.validity = this._dEl('validity');
        if (this.validity) this._setBannerValidity();

        this.bgGradientFrom = this.root.dataset.bgGradientFrom;
        this.bgGradientTo = this.root.dataset.bgGradientTo;
        this.bgColor = this.root.dataset.bgColor;
        if ((this.bgGradientFrom && this.bgGradientTo) || this.bgColor) this._updateBackground();

        this.textColor = this.root.dataset.textColor;
        this.title = this._dEl('title');
        this.description = this._dEl('description');
        this.link = this._dEl('link');
        if (this.textColor) this._setTextColor();

        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'resize',
            () => {
                this._updateBackground();
            },
            window
        );
    }

    _setTextColor() {
        const color = `${this.textColor}`;
        if (this.title) {
            this.title.style.color = color;
        }
        if (this.description) {
            this.description.style.color = color;
        }
        if (this.validity) {
            this.validity.style.color = color;
        }
        if (this.link) {
            this.link.style.color = color;
            this.link.style.borderColor = color;
        }
    }

    _updateBackground() {
        if (this.bgGradientFrom && this.bgGradientTo) {
            this.root.style.background = `linear-gradient(to ${
                window.innerWidth < BREAKPOINTS.l ? 'bottom' : 'right'
            }, ${this.bgGradientFrom}, ${this.bgGradientTo})`;
            return;
        }
        if (this.bgColor) {
            this.root.style.backgroundColor = `${this.bgColor}`;
        }
    }

    async _setBannerValidity() {
        if (!this.validity) return;
        this.validity.innerText = await this._getValidity(this.validFrom, this.validTo);
    }

    _getValidity(validFrom, validTo) {
        const from = this.dictionary.get('From the');
        const to = this.dictionary.get('to the');
        const until = this.dictionary.get('Until');

        if (!validFrom && !validTo) {
            return this.dictionary.get('Always valid');
        }
        if (!validFrom && validTo) {
            let eDate = new Date(parseInt(validTo));
            return `${until} ${this.fullDateFormatter.format(eDate)}`;
        }
        if (!validTo && validFrom) {
            let sDate = new Date(parseInt(validFrom));
            return `${from} ${this.fullDateFormatter.format(sDate)}`;
        }
        let sDate = new Date(parseInt(validFrom));
        let eDate = new Date(parseInt(validTo));
        if (sDate.getFullYear() !== eDate.getFullYear()) {
            return `${from} ${this.fullDateFormatter.format(sDate)} ${to} ${this.fullDateFormatter.format(eDate)}`;
        }
        if (sDate.getMonth() !== eDate.getMonth()) {
            return `${from} ${this.shortDateFormatter.format(sDate)} ${to} ${this.fullDateFormatter.format(eDate)}`;
        }

        return `${from} ${this.dayFormatter.format(sDate)} ${to} ${this.fullDateFormatter.format(eDate)}`;
    }
}



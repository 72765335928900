import ModalComponent from '../../../../../libs/components/modal-component';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { formToJSON, jsonToForm } from '../../../../../libs/form-to-json';
import { getRegister } from '../../../../../libs/register';
import { getStoreManager } from '../../../../../libs/store-manager';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { nextTick } from '../../../../../libs/utils';
import './style.scss';

export default class StoreLocatorFilters extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        // services
        this.register = getRegister();
        this.trackingManager = getTrackingManager();
        this.storeManager = getStoreManager();

        this.form = this._dEl('form');
        this.fAccInsignia = this._dEl('filtersAccordionInsignia');
        this.fAccDepartment = this._dEl('filtersAccordionDepartment');
        this.fAccService = this._dEl('filtersAccordionService');
        this.fInsSpecTitle = this._dEl('insigniaSpecializedTitle');
        this.reset = this._dEl('reset');
        this.apply = this._dEl('apply');
        this.storeLocator = document.querySelector('.rt052-store-locator');
        if (!this.storeLocator) return;

        this._doLogic();
    }

    _doLogic() {
        this._refreshStoreLocator();
        this._refreshModal();

        this._disableReset();
        this._disableApply();

        this._addEventListeners();
        this._addStoreListeners();
    }

    _addEventListeners() {
        this._addListener(
            'rcInputChanged',
            async () => {
                await this._refreshStoreLocator();
                if (!this.storeManager.get('prefiltersChanged')) {
                    this._emit('prefiltersChanged', true);
                    this.storeLocatorObj.searchAlert.dispatchEvent(
                        new CustomEvent('closeSearchAlert', { bubbles: false })
                    );
                }
                // refilter at every input change and mask filters accordingly to the results
                await this._refreshSearchWithFilters();

                // update ctas
                const numChecked = Array.from(this.form.querySelectorAll('input')).filter((i) => i.checked).length;
                if (numChecked <= 0) {
                    this._disableApply();
                    this._disableReset();
                } else {
                    this._enableApply();
                    this._enableReset();
                }
                this.register.getClass(this.storeLocatorObj.reset).setStatus(numChecked <= 0 ? 'disabled' : 'enabled');
                this.register.getClass(this.storeLocatorObj.apply).setStatus('enabled');
            },
            this.form
        );

        this._addListener(
            'click',
            async () => {
                await this._resetFilters();
                await this._refreshSearchWithFilters();
                if (this.modalObj.isOpen()) this.modalObj.close();
            },
            this.reset
        );

        this._addListener(
            'click',
            () => {
                if (this.modalObj.isOpen()) this.modalObj.close();
                this._trackApplyFilter();
            },
            this.apply
        );

        this._addListener(
            'rt17HeightChanged',
            async (event) => {
                this.trackingManager.track(this.root, {
                    event: 'EsplodiFiltro',
                    CustomLink: 'Esplodi Filtro',
                    funnel: {
                        stepFunnel: 'Filtro',
                    },
                    filtro: {
                        nomeFiltro: 'Insegne',
                    },
                    negozioInfo: {
                        indirizzoRicerca: 'vars.currentSearchAddress',
                    },
                });
                await this._refreshStoreLocator();
                this.storeLocatorObj.manageAccordion('filtersAccordionInsignia', event.data.isOpen);
            },
            this.fAccInsignia
        );

        this._addListener(
            'rt17HeightChanged',
            async (event) => {
                this.trackingManager.track(this.root, {
                    event: 'EsplodiFiltro',
                    CustomLink: 'Esplodi Filtro',
                    funnel: {
                        stepFunnel: 'Filtro',
                    },
                    filtro: {
                        nomeFiltro: 'Reparti',
                    },
                    negozioInfo: {
                        indirizzoRicerca: 'vars.currentSearchAddress',
                    },
                });
                await this._refreshStoreLocator();
                this.storeLocatorObj.manageAccordion('filtersAccordionDepartment', event.data.isOpen);
            },
            this.fAccDepartment
        );

        this._addListener(
            'rt17HeightChanged',
            async (event) => {
                this.trackingManager.track(this.root, {
                    event: 'EsplodiFiltro',
                    CustomLink: 'Esplodi Filtro',
                    funnel: {
                        stepFunnel: 'Filtro',
                    },
                    filtro: {
                        nomeFiltro: 'Altri Servizi',
                    },
                    negozioInfo: {
                        indirizzoRicerca: 'vars.currentSearchAddress',
                    },
                });
                await this._refreshStoreLocator();
                this.storeLocatorObj.manageAccordion('filtersAccordionService', event.data.isOpen);
            },
            this.fAccService
        );

        this._addListener(
            'resize',
            async () => {
                if (window.innerWidth < BREAKPOINTS.l) {
                    /* mobile */
                    await this._refreshStoreLocator();
                    await this._refreshModal();
                    if (this.storeLocatorObj.hasFiltersOpen()) {
                        this.storeLocatorObj._closeFiltersPane();
                        this.modalObj.open();
                    }
                } else {
                    /* desktop */
                    if (this.modalObj.isOpen()) this.modalObj.close();
                }
            },
            window
        );
    }

    _addStoreListeners() {
        this._addStoreListener('maskStoreLocatorFilters', (path, data) => {
            this._maskFilters(data.stores);
        });
    }

    async _refreshSearchWithFilters() {
        await this._updateFilters();

        // if a selected address is missing, manually search with what's in the input
        if (!this.storeLocatorObj.selectedAddress) {
            this.register.getClass(this.storeLocatorObj.address)._manualSearch();
            return;
        }
        this.storeLocatorObj._loadPoi(this.storeLocatorObj.selectedAddress);
    }

    async _updateFilters() {
        /* get raw filters data and group it */
        let filters = this._getEmptyFiltersCopy();

        const filtersData = formToJSON(this.form);
        [filtersData].forEach((data) => {
            Object.keys(data).forEach((key) => {
                const prefix = key.split('-')[0];
                if (!filters[prefix]) return;
                filters[prefix].values.push(...data[key].split(','));
                filters[prefix].count++;
            });
        });

        this._updateFiltersCount(filters);
        // update store locator filters tab
        await this._refreshStoreLocator();
        this.storeLocatorObj.setForm(formToJSON(this.form));
        this.storeLocatorObj._updateFiltersCount(filters);

        this._emit('storeLocatorFilters', filters);
    }

    _updateFiltersCount(filters) {
        /* update single filters count on accordions */
        this.register
            .getClass(this.fAccInsignia)
            .setBadgeText(`${filters.insignia.count + filters.insigniaSpecialized.count || ''}`);
        this.register.getClass(this.fAccDepartment).setBadgeText(`${filters.department.count || ''}`);
        this.register.getClass(this.fAccService).setBadgeText(`${filters.service.count || ''}`);
    }

    async _resetFilters() {
        /* reset filters */
        jsonToForm(this.form, {});
        this._resetFiltersCount();
        await this._refreshStoreLocator();
        jsonToForm(this.storeLocatorObj.form, {});
        jsonToForm(this.storeLocatorObj.filtersOpening, {});
        this.storeLocatorObj._resetFiltersCount();

        /* reset applied filters */
        this._emit('storeLocatorFilters', {});
        this._disableReset();
        this.storeLocatorObj._disableReset();
        this._disableApply();
        this.storeLocatorObj._disableApply();
    }

    _resetFiltersCount() {
        /* reset single filters count on accordions */
        this.register.getClass(this.fAccInsignia).setBadgeText('');
        this.register.getClass(this.fAccDepartment).setBadgeText('');
        this.register.getClass(this.fAccService).setBadgeText('');
    }

    _getEmptyFiltersCopy() {
        return {
            insignia: {
                values: [],
                count: 0,
            },
            insigniaSpecialized: {
                values: [],
                count: 0,
            },
            department: {
                values: [],
                count: 0,
            },
            service: {
                values: [],
                count: 0,
            },
            opening: {
                values: [],
                count: 0,
            },
        };
    }

    _initFilters() {
        this._emit('storeLocatorFilters', this._getEmptyFiltersCopy());
    }

    async _maskFilters(stores) {
        const filters = this.storeManager.get('storeLocatorFilters');

        // get insignia specialized ids
        const insigniaSpecializedServiceIds = new Set(
            this._dEl(`f-insigniaSpecialized`, true)
                .map((item) => item.querySelector('input').value.split(','))
                .reduce((_, i) => [..._, ...i])
                .map((s) => parseInt(s))
        );

        // construct available filters from stores
        const availableFilters = {
            insignia: new Set(),
            insigniaSpecialized: new Set(),
            department: new Set(),
            service: new Set(),
            opening: new Set(),
        };
        stores.forEach((store) => {
            if (window.insigniaToId[store.descrizioneInsegna])
                availableFilters.insignia.add(window.insigniaToId[store.descrizioneInsegna]);
            for (const dId of store.repartiId) {
                availableFilters.department.add(dId);
            }
            for (const sId of store.serviziId) {
                if (insigniaSpecializedServiceIds.has(sId)) {
                    availableFilters.insigniaSpecialized.add(sId);
                    continue;
                }
                availableFilters.service.add(sId);
            }
        });

        // hide filters if not available
        const hideIfNotAvailable = (item, availableSet) => {
            const values = item.querySelector('input').value.split(',');
            let hide = true;
            for (const v of values) {
                if (availableSet.has(parseInt(v))) {
                    hide = false;
                    break;
                }
            }
            hide ? item.classList.add('f-hidden') : item.classList.remove('f-hidden');
        };
        Object.keys(filters)
            .filter((key) => key !== 'opening')
            .forEach((type) => {
                this._dEl(`f-${type}`, true).forEach((item) => {
                    hideIfNotAvailable(item, availableFilters[type]);
                });
                const allHiddenOfType = this._dEl(`f-${type}`, true)
                    .map((item) => item.classList.contains('f-hidden'))
                    .reduce((_, hidden) => _ && hidden);
                // check if all insignia filters have been hidden
                if (type === 'insignia') {
                    allHiddenOfType
                        ? this.fInsSpecTitle.classList.add(this._elMod('insigniaSpecializedTitle', 'noMarginTop'))
                        : this.fInsSpecTitle.classList.remove(this._elMod('insigniaSpecializedTitle', 'noMarginTop'));
                }
                // check if all insigniaSpecialized filters have been hidden
                if (type === 'insigniaSpecialized') {
                    this.fInsSpecTitle.style.display = allHiddenOfType ? 'none' : 'block';
                }
                // hide accordion if all inner filters have been hidden
                switch (type) {
                    case 'insignia': {
                        const allHiddenOfInsignia =
                            this._dEl(`f-insignia`, true)
                                .map((item) => item.classList.contains('f-hidden'))
                                .reduce((_, hidden) => _ && hidden) &&
                            this._dEl(`f-insigniaSpecialized`, true)
                                .map((item) => item.classList.contains('f-hidden'))
                                .reduce((_, hidden) => _ && hidden);
                        allHiddenOfInsignia
                            ? this.fAccInsignia.classList.add('acc-hidden')
                            : this.fAccInsignia.classList.remove('acc-hidden');
                        break;
                    }
                    case 'department': {
                        allHiddenOfType
                            ? this.fAccDepartment.classList.add('acc-hidden')
                            : this.fAccDepartment.classList.remove('acc-hidden');
                        break;
                    }
                    case 'service': {
                        allHiddenOfType
                            ? this.fAccService.classList.add('acc-hidden')
                            : this.fAccService.classList.remove('acc-hidden');
                        break;
                    }
                    case 'insigniaSpecialized':
                    default:
                        break;
                }
            });

        // update accordions heights
        (
            await Promise.allSettled(
                [this.fAccInsignia, this.fAccDepartment, this.fAccService].map((el) => this.register.getClass(el))
            )
        )
            .map((promise) => promise.value)
            .forEach((accObj) => accObj.updateContentHeight());
    }

    _trackApplyFilter() {
        let filters = this.storeManager.get('storeLocatorFilters');
        let nomeFiltro = new Array();
        if (filters.insignia.count > 0 || filters.insigniaSpecialized.count > 0) nomeFiltro.push('Insegne');
        if (filters.department.count > 0) nomeFiltro.push('Reparti');
        if (filters.service.count > 0) nomeFiltro.push('Altri Servizi');

        // skip tracking of applied filters if no filters applied
        if (nomeFiltro.length <= 0) return;

        this.trackingManager.track(this.root, {
            event: 'ApplicaFiltro',
            CustomLink: 'Applica Filtro',
            funnel: {
                stepFunnel: 'Filtro',
            },
            negozioInfo: {
                indirizzoRicerca: 'vars.currentSearchAddress',
            },
            filtro: {
                NomeFiltro: nomeFiltro.join('|'),
                Insegne:
                    filters.insignia.count > 0
                        ? [
                              ...new Set(
                                  this.storeManager
                                      .get('storeLocatorFilters')
                                      .insignia.values.map(
                                          (value) =>
                                              this.root.querySelector(`.rt023-insignia-card__input[value="${value}"]`)
                                                  ?.parentElement.dataset.label
                                      )
                                      .concat(
                                          this.storeManager
                                              .get('storeLocatorFilters')
                                              .insigniaSpecialized.values.map(
                                                  (value) =>
                                                      this.root.querySelector(
                                                          `.rt023-insignia-card__input[value*="${value}"][name^="insigniaSpecialized"]`
                                                      )?.parentElement.dataset.label
                                              )
                                      )
                              ),
                          ].join('|')
                        : '',
                Reparti:
                    filters.department.count > 0
                        ? [
                              ...new Set(
                                  filters.department.values.map(
                                      (value) =>
                                          this.root.querySelector(`.rt003-checkbox__input[value*="${value}"]`)
                                              ?.parentElement.dataset.label
                                  )
                              ),
                          ].join('|')
                        : '',
                AltriServizi:
                    filters.service.count > 0
                        ? [
                              ...new Set(
                                  filters.service.values.map(
                                      (value) =>
                                          this.root.querySelector(
                                              `.rt003-checkbox__input[value*="${value}"][name^="service"]`
                                          )?.parentElement.dataset.label
                                  )
                              ),
                          ].join('|')
                        : '',
            },
        });
    }

    _enableReset() {
        this.register.getClass(this.reset).setStatus('enabled');
    }

    _disableReset() {
        this.register.getClass(this.reset).setStatus('disabled');
        this.reset.blur();
    }

    _enableApply() {
        this.register.getClass(this.apply).setStatus('enabled');
    }

    _disableApply() {
        this.register.getClass(this.apply).setStatus('disabled');
        this.apply.blur();
    }

    setForm(json) {
        // translate prop names from _dsk to _mob suffix
        for (const prop in json) {
            if (!prop.endsWith('_dsk')) continue;
            json[`${prop.substring(0, prop.indexOf('_dsk'))}_mob`] = json[prop];
        }
        jsonToForm(this.form, json);
    }

    manageAccordion(name, isOpen) {
        const el = this._dEl(name);
        if (!el) return;
        const elObj = this.register.getClass(el);
        isOpen ? elObj.openContent() : elObj.closeContent();
    }

    async _refreshStoreLocator() {
        if (!this.storeLocatorObj) {
            await nextTick();
            this.storeLocatorObj = this.register.getClass(this.storeLocator);
        }
    }

    _refreshModal() {
        if (!this.modalObj) {
            this.modalObj = this.modal;
        }
    }

    /* override */
    async _onClose() {
        super._onClose();
        await this._refreshStoreLocator();
        if (window.innerWidth >= BREAKPOINTS.l) {
            this.storeLocatorObj._openFiltersPane();
        }
    }
    /* override */
    async _onCancel() {
        super._onCancel();
        await this._refreshStoreLocator();
        if (window.innerWidth >= BREAKPOINTS.l) {
            this.storeLocatorObj._openFiltersPane();
        }
    }
}



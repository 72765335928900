import './style.scss';
import Component from '../../../../../libs/components/component';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class Lancio extends Component {
    constructor(name, root) {
        super(name, root);
        this.carousel = this._dEl('carousel');
        this.pagination = this._dEl('pagination');
        this.track = this._dEl('track');
        this.swiper = null;
        this.slide = this._dEl('slideContainer');
        this.variant = this.slide ? this.slide.dataset.variant : 'variant1';
        this.updateHeight();
        this._init();
    }

    async _init() {
        await this._initSwiper();
        this._addEventListeners();
    }

    async _initSwiper() {
        const slideCounts = this._dEl('slideContainer', true).length;
        if (this.pagination && slideCounts <= 1) {
            this.pagination.classList.add(this._elMod('pagination', 'hidden'));
        } else {
            if (this.variant == 'variant3' || this.variant == 'variant5' || this.variant == 'variant4') {
                this.leftAll = this._dEl('left', true);
                this.leftAll.forEach((element) => {
                    element.classList.add(this._elMod('left', 'pagination'));
                });
            }
        }

        const options = {
            wrapperClass: this._el('track'),
            slideClass: this._el('slideContainer'),
            slidesPerView: 'auto',
            autoHeight: true,
            observer: true,
            observeParents: true,
            mousewheel: {
                sensitivy: 3,
                releaseOnEdges: true,
                forceToAxis: true,
                thresholdDelta: 2,
            },
            on: {
                slideChange: function (swiper) {
                    swiper.updateAutoHeight(100);
                },
                paginationRender: function (swiper, paginationEl) {
                    if (!paginationEl) return;

                    [...paginationEl.querySelectorAll('button')].forEach((button, index) => {
                        button.setAttribute('type', 'button');
                        button.setAttribute('aria-label', `Slide ${index + 1}`);
                    });
                },
            },
        };

        if (this.variant == 'variant3' || this.variant == 'variant4' || this.variant == 'variant5') {
            options['breakpoints'] = {
                1024: {
                    direction: 'vertical',
                },
            };
        }

        if (this.pagination) {
            options['pagination'] = {
                el: this._dEl('pagination', true),
                bulletElement: 'button',
                bulletClass:
                    this.variant == 'variant3' || this.variant == 'variant4' || this.variant == 'variant5'
                        ? this._el('bulletVertical')
                        : this._el('bullet'),
                bulletActiveClass:
                    this.variant == 'variant3' || this.variant == 'variant4' || this.variant == 'variant5'
                        ? this._elMod('bulletVertical', 'active')
                        : this._elMod('bullet', 'active'),
                clickable: true,
            };

            if (this.variant == 'variant2') {
                this.pagination.classList.add(this._elMod('pagination', 'variant2'));
            }

            if (this.variant == 'variant3' || this.variant == 'variant4' || this.variant == 'variant5') {
                this.pagination.classList.add(this._elMod('pagination', 'vertical'));
            }

            if (this.variant == 'variant3' || this.variant == 'variant5') {
                this.track.classList.add(this._elMod('track', 'vertical3'));
            }

            if (this.variant == 'variant4') {
                this.track.classList.add(this._elMod('track', 'vertical4'));
            }
        }

        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.carousel, options);
    }

    _addEventListeners() {
        this._addListener(
            'resize',
            () => {
                this.updateHeight();
                this.swiper.update();
            },
            window
        );
    }

    updateHeight() {
        if (window.innerWidth < BREAKPOINTS.l) {
            this.left = this._dEl('left', true).map((e) => {
                return e.scrollHeight;
            });
            this.right = this._dEl('right', true).map((e) => {
                return e.scrollHeight;
            });

            if (this.variant == 'variant4' || this.variant == 'variant2' || this.variant == 'variant1') {
                this.leftHeight = Math.max(...this.left);
                const height = this.leftHeight;
                this.track.style.height = `${height}px`;
            } else if (this.variant == 'variant3' || this.variant == 'variant5') {
                this.sum = new Array();
                for (let i = 0; i < this.left.length; i++) {
                    this.sum[i] = this.left[i] + this.right[i];
                }
                this.heightTot = Math.max(...this.sum);
                const height = this.heightTot;
                this.track.style.height = `${height}px`;
            }
        }
    }

    //delete all
    dispose() {
        super.dispose();
        this.swiper.destroy(true, true);
    }
}



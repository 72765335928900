/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let label = args[1]['label'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n\n    ");
    let rt2 = $.template('src/main/webpack/repository/apps/conad-corporate/templates/rt002-cta/rt002-cta.html');
    $.dom.text($n,"\n    ");
    yield $.call(rt2["render"], [$n, {"extraClasses": "rt061-anchors__anchor", "label": label, "variant": "link", "size": "small", }]);
    $.dom.text($n,"\n\n");
  };
  $.template('global', 'render', render);
  (function _template_src_main_webpack_repository_apps_conad_corporate_templates_rt002_cta_rt002_cta_html(){ 
    let render;
    render = function* _template_src_main_webpack_repository_apps_conad_corporate_templates_rt002_cta_rt002_cta_html_render(args) { 
      let uniqueid = args[1]['uniqueId'] || '';
      let extraclasses = args[1]['extraClasses'] || '';
      let label = args[1]['label'] || '';
      let icon = args[1]['icon'] || '';
      let link = args[1]['link'] || '';
      let variant = args[1]['variant'] || '';
      let type = args[1]['type'] || '';
      let size = args[1]['size'] || '';
      let status = args[1]['status'] || '';
      let enabledtype = args[1]['enabledType'] || '';
      let semantictype = args[1]['semanticType'] || '';
      let socialtype = args[1]['socialType'] || '';
      let arialabel = args[1]['ariaLabel'] || '';
      let $t, $n = args[0];
      $.dom.text($n,"\n\n    ");
      const ctaid = $.exec("format", uniqueid ? "{0}-cta" : "{1}", {"format": [uniqueid, ""], });
      if (ctaid) {
      }
      $.dom.text($n,"\n    ");
      const variantclass = $.exec("format", "rt002-cta-{0}", {"format": [(variant) || ("primary")], });
      if (variantclass) {
      }
      $.dom.text($n,"\n    ");
      const typeclass = $.exec("format", "{0}--{1}", {"format": ["rt002-cta", (type) || ("none")], });
      if (typeclass) {
      }
      $.dom.text($n,"\n    ");
      const sizeclass = $.exec("format", "{0}--{1}", {"format": ["rt002-cta", (size) || ("medium")], });
      if (sizeclass) {
      }
      $.dom.text($n,"\n    ");
      const statusclass = $.exec("format", status ? "{0}--{1}" : "{2}", {"format": [variantclass, status, ""], });
      if (statusclass) {
      }
      $.dom.text($n,"\n    ");
      const enabledclass = $.exec("format", ((enabledtype) === ("neutralDark")) || ((enabledtype) === ("neutralLight")) ? "{0}--{1}" : "{2}", {"format": [variantclass, enabledtype, ""], });
      if (enabledclass) {
      }
      $.dom.text($n,"\n    ");
      const semanticclass = $.exec("format", ((((semantictype) === ("1")) || ((semantictype) === ("2"))) || ((semantictype) === ("3"))) || ((semantictype) === ("4")) ? "{0}{1}" : "{2}", {"format": [variantclass, semantictype, ""], });
      if (semanticclass) {
      }
      $.dom.text($n,"\n    ");
      const socialclass = $.exec("format", (((socialtype) === ("google")) || ((socialtype) === ("facebook"))) || ((socialtype) === ("apple")) ? "{0}--{1}" : "{2}", {"format": [variantclass, socialtype, ""], });
      if (socialclass) {
      }
      $.dom.text($n,"\n    ");
      const finalclasses = $.exec("format", ((variant) === ("social")) && (socialtype) ? "{0} {1}" : "{1} {2} {3} {4} {5}", {"format": [socialclass, sizeclass, typeclass, statusclass, enabledclass, semanticclass], });
      if (finalclasses) {
      }
      $.dom.text($n,"\n\n        ");
      const var_tagVar2 = yield $.xss(link ? "a" : "button", "elementNameNoFallback");
      if (var_tagVar2) {
        $t = $.dom.create(var_tagVar2,false,false);
      }
      if (!var_tagVar2) {
        $t = $.dom.create("button",false,false);
      }
      const var_attrMap3 = link;
      const var_attrName_class5 = "class";
      const var_mapContains_class6 = var_attrMap3["class"];
      if (!$.col.empty(var_mapContains_class6)) {
        $.dom.attr($t, var_attrName_class5, var_mapContains_class6, 'attribute');
      }
      if ((var_mapContains_class6) == (null)) {
        const var_attrValue7 = ("rt002-cta ") + (variantclass) + (" ") + (finalclasses) + (" ") + (extraclasses);
        if (!$.col.empty(var_attrValue7)) {
          $.dom.attr($t, 'class', var_attrValue7, 'attribute');
        }
      }
      const var_attrName_data_variant8 = "data-variant";
      const var_mapContains_data_variant9 = var_attrMap3["data-variant"];
      if (!$.col.empty(var_mapContains_data_variant9)) {
        $.dom.attr($t, var_attrName_data_variant8, var_mapContains_data_variant9, 'attribute');
      }
      if ((var_mapContains_data_variant9) == (null)) {
        const var_attrValue10 = variant;
        if (!$.col.empty(var_attrValue10)) {
          $.dom.attr($t, 'data-variant', var_attrValue10, 'attribute');
        }
      }
      const var_attrName_data_status11 = "data-status";
      const var_mapContains_data_status12 = var_attrMap3["data-status"];
      if (!$.col.empty(var_mapContains_data_status12)) {
        $.dom.attr($t, var_attrName_data_status11, var_mapContains_data_status12, 'attribute');
      }
      if ((var_mapContains_data_status12) == (null)) {
        const var_attrValue13 = status;
        if (!$.col.empty(var_attrValue13)) {
          $.dom.attr($t, 'data-status', var_attrValue13, 'attribute');
        }
      }
      $.dom.attr($t, 'data-component', null, 'attribute');
      $.dom.attr($t, 'data-lazy-init', null, 'attribute');
      const var_attrValue_id0 = (link) && (link["id"]) ? link["id"] : ctaid;
      if (!$.col.empty(var_attrValue_id0)) {
        $.dom.attr($t, 'id', var_attrValue_id0, 'attribute');
      }
      const var_attrValue_data_icon1 = icon;
      if (!$.col.empty(var_attrValue_data_icon1)) {
        $.dom.attr($t, 'data-icon', var_attrValue_data_icon1, 'attribute');
      }
      const var_attrValue_aria_label4 = (arialabel) || (label);
      if (!$.col.empty(var_attrValue_aria_label4)) {
        $.dom.attr($t, 'aria-label', var_attrValue_aria_label4, 'attribute');
      }
      const var_ignoredAttributes14 = {"class": true, "data-variant": true, "data-status": true, "aria-label": true, "data-component": true, "data-lazy-init": true, "id": true, "data-icon": true, };
      for (const var_attrIndex17 of $.col.keys(var_attrMap3)) {
        const var_attrName15 = $.col.get(var_attrMap3, var_attrIndex17);
        const var_attrNameEscaped16 = yield $.xss(var_attrName15, "attributeName");
        if (var_attrNameEscaped16) {
          if (!var_ignoredAttributes14[var_attrName15]) {
            const var_attrContent18 = var_attrMap3[var_attrName15];
            if (!$.col.empty(var_attrContent18)) {
              $.dom.attr($t, var_attrNameEscaped16, var_attrContent18, 'attribute');
            }
          }
        }
      }
      $n = $.dom.push($n,$t);
      $.dom.text($n,"\n            ");
      const var_testVariable19 = (type) !== ("right");
      if (var_testVariable19) {
        $t = $.dom.create("span",false,false);
        const var_attrValue20 = ("rt002-cta__icon ") + (variantclass) + ("__icon ") + ((icon) || (""));
        if (!$.col.empty(var_attrValue20)) {
          $.dom.attr($t, 'class', var_attrValue20, 'attribute');
        }
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n            ");
      $t = $.dom.create("span",false,false);
      const var_attrValue21 = ("rt002-cta__label ") + (variantclass) + ("__label");
      if (!$.col.empty(var_attrValue21)) {
        $.dom.attr($t, 'class', var_attrValue21, 'attribute');
      }
      $n = $.dom.push($n,$t);
      const var_22 = yield $.xss(label, "html");
      $.dom.append($n, var_22);
      $n = $.dom.pop($n);
      $.dom.text($n,"\n            ");
      const var_testVariable23 = (type) === ("right");
      if (var_testVariable23) {
        $t = $.dom.create("span",false,false);
        const var_attrValue24 = ("rt002-cta__icon ") + (variantclass) + ("__icon ") + ((icon) || (""));
        if (!$.col.empty(var_attrValue24)) {
          $.dom.attr($t, 'class', var_attrValue24, 'attribute');
        }
        $n = $.dom.push($n,$t);
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n        ");
      if (var_tagVar2) {
        $n = $.dom.pop($n);
      }
      if (!var_tagVar2) {
        $n = $.dom.pop($n);
      }
      $.dom.text($n,"\n\n    \n");
    };
    $.template('src/main/webpack/repository/apps/conad-corporate/templates/rt002-cta/rt002-cta.html', 'render', render);
  })();

  return $.run(function* () {
    let $t, $n = $.dom.start();
    return $.dom.end();

  });
};

/*global Yumpu*/
import { buildWaitForEvent } from './utils';

export class ExtApiHelper {
    constructor() {
        this.s7viewersReady = buildWaitForEvent('s7ViewersReady');
        this.s7viewersLoaded = 0;
        this.yumpuReady = buildWaitForEvent('yumpuReady');
        this.yumpuLoaded = 0;
    }

    async getS7ViewersApi() {
        switch (this.s7viewersLoaded) {
            case 0:
                this._downloadS7ViewersApi();
                await this.s7viewersReady(document.body);
                return window.s7viewers;
            case 1:
                await this.s7viewersReady(document.body);
                return window.s7viewers;
            case 2:
                return window.s7viewers;
            default:
                console.error('Could not retrieve s7viewersApi');
                break;
        }
    }

    async getYumpuApi() {
        switch (this.yumpuLoaded) {
            case 0:
                this._downloadYumpuApi();
                await this.yumpuReady(document.body);
                return this._getYumpuObj();
            case 1:
                await this.yumpuReady(document.body);
                return this._getYumpuObj();
            case 2:
                return this._getYumpuObj();
            default:
                console.error('Could not retrieve yumpuApi');
                break;
        }
    }

    async getLeaflet() {
        // import css
        await import('leaflet/dist/leaflet.css');
        return (await import('leaflet')).L;
    }

    async getSwiper() {
        const swiperModule = await import('swiper');
        const Swiper = swiperModule.default;
        Swiper.use([swiperModule.Pagination, swiperModule.Navigation, swiperModule.Mousewheel, swiperModule.Scrollbar]);
        return Swiper;
    }

    async getPerfectScrollbar() {
        const perfectModule = await import('perfect-scrollbar');
        return perfectModule.default;
    }

    _downloadS7ViewersApi() {
        if (window.s7viewers) {
            // s7viewers already loaded
            this.s7viewersLoaded = 2;
            document.body.dispatchEvent(new CustomEvent('s7ViewersReady'));
            return;
        }
        // load first script tag in page
        const firstScriptTag = document.getElementsByTagName('script')[0];

        // s7viewers not loaded, launch download
        const tag = document.createElement('script');
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        tag.onload = () => {
            this.s7viewersLoaded = 2;
            console.log('--- Loaded external API Scene7');
            document.body.dispatchEvent(new CustomEvent('s7ViewersReady'));
        };
        tag.src = 'https://s7e5a.scene7.com/s7viewers/html5/js/InteractiveImage.js';
        this.s7viewersLoaded = 1;
    }

    _downloadYumpuApi() {
        if (window.Yumpu) {
            // yumpu already loaded
            document.body.dispatchEvent(new CustomEvent('yumpuReady'));
            return;
        }
        // load first script tag in page
        const firstScriptTag = document.getElementsByTagName('script')[0];

        // yumpu not loaded, launch download
        const tag = document.createElement('script');
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        tag.onload = () => {
            window.YumpuObj = new Yumpu();
            this.yumpuLoaded = 2;
            console.log('--- Loaded external API Yumpu');
            document.body.dispatchEvent(new CustomEvent('yumpuReady'));
        };
        tag.src = 'https://players.yumpu.com/hub.js';
        this.yumpuLoaded = 1;
    }

    _getYumpuObj() {
        if (!window.YumpuObj) {
            window.YumpuObj = new Yumpu();
        }
        return window.YumpuObj;
    }
}

/**
 * Get the current ExtApiHelper
 * @returns {ExtApiHelper}
 */
export const getExtApiHelper = () => {
    if (!window.rcExtApiHelper) {
        window.rcExtApiHelper = new ExtApiHelper();
    }
    return window.rcExtApiHelper;
};

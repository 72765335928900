import { runTemplate } from '../../../../../../libs/htl-runtime/HTMLRuntime';
import { htmlMapPin } from './html-map-pin';

export class ClusterPinRenderer {
    constructor(map, register, mapPinCall) {
        this.map = map;
        this.register = register;
        this.mapPinCall = mapPinCall;
    }

    /* override */
    render({ count, position, markers } /*, stats*/) {
        // deselect all markers of the cluster
        for (const marker of markers) {
            this.register.getClass(marker.getElem()).setSelected(false);
        }

        const elem = runTemplate(this.mapPinCall, { aggregator: true, textContent: `${count}` });
        const clusterMapPin = htmlMapPin({
            position: position,
            elem: elem,
            map: this.map,
        });
        this.register.getClass(elem).setMapParams(this.map, position, clusterMapPin);
        return clusterMapPin;
    }
}

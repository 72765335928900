import './style.scss';
import Component from '../../../../../libs/components/component';
import * as ctaHtml from '../rt002-cta/rt002-cta.html';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getTrackingManager } from '../../../../../libs/tracking-manager';

export default class CardPromotional extends Component {

    constructor(name, root) {
        super(name, root);
        this.aggCode = this.root.dataset.aggCode;
        this.aggLink = this.root.dataset.linkAgg;
        this.ctaLabel = this.root.dataset.ctaLabel;
        this.trackingManager = getTrackingManager();

        this.bottom = this._dEl('bottom', false);

        if(this.aggLink)
            this._addCtaLink();
    }

    _addCtaLink(){
        const link = `${this.aggLink}?aggregatorCode=${this.aggCode}`;
        const args = {
            extraClasses: this._el('cta'),
            label: this.ctaLabel,
            variant: 'secondary',
            enabledType: 'neutralDark',
            size: 'small',
            type: 'none',
            link: {
                href: link 
                //target: '',
            },
        };
        this.cta = runTemplate(ctaHtml, args);
        this._addListener('click',
            () => {
                this.trackingManager.track(this.root, {
                        funnel: {
                            stepFunnel: "Interazioni con prodotti disaggregato"
                        },
                        event: 'CardPromozionale',
                        CustomLink: 'Click Card Promozionale Scopri Di Piu',
                        prodottoInfo: {
                            componente: "Card Promozionale",
                            marchio: "data.brand",
                            sottomarchio: "",
                            categoria: "data.category",
                            sottocategoria: "data.subCategory",
                            meccanicaPromozionale: "data.meccanica"
                        }
                    });
            },
            this.cta);
        this.bottom.append(this.cta);
    }
}



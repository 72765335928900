import './style.scss';
import ValidableComponent from '../../../../../libs/components/validable-component';

export default class DatePicker extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        // constants
        this.currentYear = parseInt(new Date().getFullYear());

        this.input = this._dEl('input');
        this.inputTitle = this.input.title;
        this.optional = this._dEl('optional');
        this.showCalendar = this._dEl('showCalendar');
        this.error = this._dEl('error');

        this._init();
    }

    async _init() {
        const { Datepicker } = await import('vanillajs-datepicker');
        const itModule = await import('vanillajs-datepicker/locales/it');
        Object.assign(Datepicker.locales, itModule.default);

        this.datepicker = new Datepicker(this.input, {
            format: 'd-m-yyyy',
            showOnClick: false,
            showOnFocus: false,
            autohide: true,
            orientation: 'auto auto',
            language: document.documentElement.lang || 'en',
        });

        this._setAdditionalOptions();
        this.input.removeAttribute('title');

        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    _addEventListeners() {
        this._addListener(
            ['keyup', 'change', 'input'],
            () => {
                this._checkState();
                if (this.root.classList.contains(this.ERROR)) {
                    this._invalidField();
                }
                this._changedInput();
            },
            this.input
        );

        this._addListener(
            'click',
            (event) => {
                event.preventDefault();
                this.datepicker.show();
            },
            'showCalendar'
        );

        this._addListener(
            'focusout',
            () => {
                if (this.datepicker.active) return;
                if (this.input.required && this.input.value == '') {
                    this.setState('error');
                    this._requireField();
                }
                this._checkState();
            },
            this.input
        );

        /* hide default browser error popup on enter pressed */
        this._addListener(
            'invalid',
            () => {
                this._checkState();
                if (this.root.classList.contains(this.ERROR)) {
                    this._invalidField();
                }
            },
            this.root,
            true
        );
    }

    _setAdditionalOptions() {
        if (this.root.hasAttribute('data-future-disabled')) {
            const futureDisabled = (date) => {
                return date.getTime() < new Date().getTime();
            };
            this.datepicker.setOptions({
                beforeShowDay: futureDisabled,
                beforeShowMonth: futureDisabled,
                beforeShowYear: futureDisabled,
                beforeShowDecade: futureDisabled,
            });
        }
        if (this.root.hasAttribute('data-start-from-decades')) {
            this.datepicker.setOptions({
                startView: 3,
            });
        }
    }

    _requireField() {
        this.error.innerText = 'Campo obbligatorio';
    }

    _invalidField() {
        this.error.innerText = 'Campo non valido';
    }

    /* override */
    _checkState() {
        if (!this._isDateValid(this.getValue())) {
            this.setState('error');
            this.input.title = this.inputTitle;
        } else if (this.getValue() && this._isDateValid(this.getValue())) {
            this.setState('valid');
            this.input.title = '';
        } else {
            this.setState('');
        }
    }

    /* override */
    isValid() {
        return super.isValid() && this._isDateValid(this.getValue());
    }

    _isDateValid(value) {
        if (!this._getInput().required && value == '') return true;
        if (this._getInput().required && !value) return false;
        try {
            const split = value.split('-');
            const date = new Date(
                split
                    .map((s) => (s.length == 1 ? `0${s}` : s))
                    .reverse()
                    .join('-')
            );
            if (this.root.hasAttribute('data-future-disabled') && !(date.getTime() < new Date().getTime()))
                return false;
            const dd = parseInt(split[0], 10);
            const mm = parseInt(split[1], 10);
            const yyyy = parseInt(split[2], 10);
            /* if flag excludeMinors is active, exclude the latest 18 years */
            if (this.root.hasAttribute('data-exclude-minors') && yyyy > this.currentYear - 18) {
                return false;
            }
            /* Create list of days of a month [assume there is no leap year by default] */
            const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            if (mm == 1 || mm > 2) {
                if (dd > monthDays[mm - 1]) return false;
            }
            if (mm == 2) {
                let leapYear = (!(yyyy % 4) && yyyy % 100) || !(yyyy % 400);
                if (!leapYear && dd >= 29) return false;
                if (leapYear && dd > 29) return false;
            }
            return true;
        } catch (error) {
            return false;
        }
    }

    setRequired(required = true) {
        const prev = this.input.required;
        if (required === prev || (required !== true && required !== false)) return;
        this.input.required = required === true;
        required
            ? this.optional.classList.add(this._elMod('optional', 'hidden'))
            : this.optional.classList.remove(this._elMod('optional', 'hidden'));
        this.setState('');
        //this._checkState();
        this._changedInput();
    }
}



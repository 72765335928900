import './style.scss';
import Component from '../../../../../libs/components/component';
import { getUserService } from '../../../../../libs/user-service';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { getApiProvider } from '../../../../../libs/api-provider';
import { emptyElement, shuffleArray } from '../../../../../libs/utils';
import richtextHtml from '../rt001-richtext/rt001-richtext.html';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class ThumbSlider extends Component {
    constructor(name, root) {
        super(name, root);
        this.TRACKING_SLIDE_LATERALE = {
            event: 'SlideLaterale',
            CustomLink: 'Click Slide laterale',
        };

        this.variant = this.root.dataset.variant;
        this.itemsLength = this.root.dataset.itemsLength;
        this.loaderEndpoint = this.root.dataset.loaderEndpoint;

        this.apiProvider = getApiProvider();
        this.trackingManager = getTrackingManager();

        this.track = this._dEl('track', false);
        this.prev = this._dElMod('btn', 'prev');
        this.next = this._dElMod('btn', 'next');
        this.carousel = this._dEl('right');
        this.slides = this._dEl('slideContainer', true);
        this.logo = this._dEl('logo');
        this.userSuggestedProducts = false;

        this.swiper = null;
        this._init();
    }

    _init() {
        if (this.variant == 'flyers') {
            this._getFlyerProducts();
        } else if (!this.slides || this.slides.length <= 0) {
            this._removeMainComponent();
            return;
        } else {
            this._initSwiper();
        }

        this._addEventListeners();
    }

    _showComponents() {
        this._dEl('title')?.classList.remove(this._elMod('title', 'hide'));
        this._dEl('text')?.classList.remove(this._elMod('text', 'hide'));
        this._dEl('cta')?.classList.remove(this._elMod('cta', 'hide'));
        this._dEl('ctaMob')?.classList.remove(this._elMod('ctaMob', 'hide'));
    }

    async _initSwiper() {
        const options = {
            wrapperClass: this._el('track'),
            slideClass: this._el('slideContainer'),
            slidesPerView: 'auto',
            spaceBetween: 16,
            slidesOffsetBefore: 16,
            slidesOffsetAfter: 16,

            breakpoints: {
                1024: {
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                },
            },
        };

        options['scrollbar'] = {
            el: this._dEl('scrollbar'),
            dragClass: this._el('scrollbarDrag'),
            dragSize: 165,
            hide: false,
            draggable: true,
        };

        if (this.prev && this.next) {
            options['navigation'] = {
                nextEl: this.next,
                prevEl: this.prev,
                disabledClass: this._elMod('btn', 'disabled'),
                hiddenClass: this._elMod('btn', 'hidden'),
            };
        }

        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.carousel, options);
    }

    _addEventListeners() {
        /* click on prev of the side slider*/
        this._addListener(
            'click',
            () => {
                this.trackingManager.track(this.root, this.TRACKING_SLIDE_LATERALE);
            },
            this.prev
        );

        /* click on next of the side slider*/
        this._addListener(
            'click',
            () => {
                this.trackingManager.track(this.root, this.TRACKING_SLIDE_LATERALE);
            },
            this.next
        );
    }

    async _getFlyerProducts() {
        try {
            const url = this.loaderEndpoint;
            const pattern = /\.(html)/g;
            let user = await getUserService().getUser();
            let currentPageStoreAnacan = '';
            let store = await getUserService().getStore();
            let pageStore = false;
            let urlTemp = url;
            let differentSuggestedStore = false;
            let response = '';
            let bassiFissi = false;
            let headerComponentPageStore = document.querySelector('.rt057-store-header');

            if (headerComponentPageStore) {
                currentPageStoreAnacan = headerComponentPageStore.dataset.storeId;
                pageStore = true;
            }

            if ((!user && pageStore) || (user && pageStore && store.anacanId != currentPageStoreAnacan)) {
                store = await this.apiProvider.getPointOfServiceByAnacanId({ anacanId: currentPageStoreAnacan });
                differentSuggestedStore = true;
            }

            if (store && store.anacanId) {
                let flyersFromStore = store.volantini;

                if (flyersFromStore) {
                    let hasDisaggregated = false;
                    flyersFromStore.forEach(function (flyer) {
                        if (flyer.hasDisaggregated) {
                            hasDisaggregated = true;
                        }
                    });

                    if (hasDisaggregated) {
                        if (user && !differentSuggestedStore) {
                            this.userSuggestedProducts = true;
                            urlTemp = url.replace(
                                pattern,
                                `.anacan-${store.anacanId}.html?userPersonalizedProduct=` + user.utenzaId
                            );
                            response = await this.apiProvider.loaderGet(urlTemp, {});
                            bassiFissi = false;
                        }

                        if (response == '') {
                            this.userSuggestedProducts = false;
                            urlTemp = url.replace(pattern, `.anacan-${store.anacanId}.html`);
                            response = await this.apiProvider.loaderGet(urlTemp, {});
                            bassiFissi = false;
                        }
                    }
                }
            }

            if (response == '') {
                if (pageStore) {
                    this._removeMainComponent();
                    return;
                } else {
                    let randomNumber = Math.floor(Math.random() * 3) + 1; //reduce to 3 random
                    urlTemp = url.replace(pattern, `.${randomNumber}.html`);
                    response = await this.apiProvider.loaderGet(urlTemp, {});
                    bassiFissi = true;
                }
            }

            if (!bassiFissi) {
                this._appendResults(response, bassiFissi, store.anacanId);
            } else {
                this._appendResults(response, bassiFissi, null);
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.root.classList.remove(this._mod('hidden'));
            this._initSwiper();
        }
    }

    _appendResults(html, bassiFissi, anacanId) {
        if (html == '' || html.startsWith('<!-- *********** THREAD REPORT')) {
            return;
        }

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const tr = doc.querySelector(this._el('track', true));
        const cards = Array.from(doc.querySelectorAll(this._el('slideContainer', true)));
        const container = this._dEl('right');
        const trackContainer = this._dEl('track');
        let disaggregatedId = tr.dataset.volDisaggregatedId;

        if (!bassiFissi && disaggregatedId) {
            this._emit('PropsCurrentFlyer', { anacanId, disaggregatedId });
        }

        if (trackContainer) {
            emptyElement(trackContainer);
            if (this.userSuggestedProducts) {
                trackContainer.append(...cards);
            } else {
                trackContainer.append(...shuffleArray(cards));
            }
        } else {
            const scrollBarContainer = this._dEl('scrollbar');
            container.insertBefore(tr, scrollBarContainer);
        }

        const ctaEl = this._dEl('cta');
        if (tr.dataset && tr.dataset.ctaLink && JSON.parse(tr.dataset.ctaLink)) {
            const link = JSON.parse(tr.dataset.ctaLink);
            ctaEl.href = link.href;
            this._dEl('ctaMob').href = link.href;

            let ctaLabel = tr.dataset.flyerCtaLabel;
            if (this.userSuggestedProducts) {
                ctaLabel = tr.dataset.flyerSuggestedCtaLabel;
            }

            if (ctaLabel) {
                ctaEl.querySelector('span.rt002-cta__label').innerHTML = ctaLabel;
                ctaEl.setAttribute('data-item-path', link.href);
                this._dEl('ctaMob').querySelector('span.rt002-cta__label').innerHTML = ctaLabel;
                this._dEl('ctaMob').setAttribute('data-item-path', link.href);
            }

            this._dEl('text').remove();
            let text = tr.dataset.flyerText;
            if (this.userSuggestedProducts) {
                text = tr.dataset.flyerSuggestedText;
            }
            if (text) {
                const subtitle = this._buildRichTextText(text);
                this._dEl('left').insertBefore(subtitle, ctaEl);
            }

            this._dEl('title').remove();
            let title = tr.dataset.flyerTitle;
            if (this.userSuggestedProducts) {
                title = tr.dataset.flyerSuggestedTitle;
            }
            if (title) {
                const flyerTitle = this._buildRichTextTitle(title);
                this._dEl('left').insertBefore(flyerTitle, this._dEl('separator'));
            }
            this._showComponents();
        }
    }

    _buildRichTextTitle(text = '') {
        const args = {
            extraClasses: `${this._el('title')} ${this.logo ? '' : this._elMod('title', 'noLogo')}`,
            text: text,
            textIsRich: true,
        };
        return runTemplate(richtextHtml, args);
    }

    _buildRichTextText(text = '') {
        const args = {
            extraClasses: this._el('text'),
            text: text,
            textIsRich: true,
        };
        return runTemplate(richtextHtml, args);
    }

    //delete all
    dispose() {
        super.dispose();
        this.swiper.destroy(true, true);
    }
}



import ModalComponent from '../../../../../libs/components/modal-component';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getDictionary } from '../../../../../libs/dictionary-provider';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';
import { formToJSON } from '../../../../../libs/form-to-json';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getRegister } from '../../../../../libs/register';
import { getStoreManager } from '../../../../../libs/store-manager';
import { isObjectEmpty } from '../../../../../libs/utils';
import './style.scss';

export default class FiltersModal extends ModalComponent {
    constructor(name, root) {
        super(name, root);
        this.register = getRegister();
        this.dictionary = getDictionary();
        this.storeManager = getStoreManager();

        this.wrapper = this._dEl('wrapper');
        this.ACC_OPEN = this._mod('accOpen');
        this.ACCORDION_OPEN = this._elMod('accordion', 'open');
        this.ACCORDION_SHOW_COUNT = this._elMod('accordion', 'showCount');

        this._getElements();
        this._addStoreListeners();
    }

    async _doLogic() {
        this._getElements();
        this._checkApplyResetCtas();
        await this._initScrollbars();
        this._removeAllListeners();
        this._addEventListeners();
    }

    _getElements() {
        this.accordions = this._dEl('accordion', true);
        this.headings = this._dEl('heading', true);
        this.counts = this._dEl('count', true);
        this.contents = this._dEl('content', true);
        this.forms = this._dEl('form', true);
        this.scrollbars = [];
        this.resets = this._dEl('reset', true);
        this.applies = this._dEl('apply', true);
    }

    async _initScrollbars() {
        for (let i = 0; i < this.accordions.length; i++) {
            const PerfectScrollbar = await getExtApiHelper().getPerfectScrollbar();
            const scrollbar = new PerfectScrollbar(this.forms[i], {
                swipeEasing: true,
                suppressScrollX: true,
            });
            this.scrollbars.push(scrollbar);
        }
    }

    _checkApplyResetCtas() {
        for (let i = 0; i < this.accordions.length; i++) {
            //check and disable buttons if necessary
            const data = formToJSON(this.forms[i]);
            if (isObjectEmpty(data)) {
                this.register.getClass(this.applies[i]).setStatus('disabled');
                this.register.getClass(this.resets[i]).setStatus('disabled');
            }
        }
    }

    _addEventListeners() {
        for (let i = 0; i < this.accordions.length; i++) {
            this._addListener(
                'click',
                () => {
                    /* open accordion, update content max-height */
                    this.accordions[i].classList.toggle(this.ACCORDION_OPEN);
                    this.contents[i].style.maxHeight = `${
                        !this._isAccordionOpen(this.accordions[i]) ? 0 : this.wrapper.clientHeight - 56 - 30
                    }px`;
                    /* hide/show topBar */
                    this._isAccordionOpen(this.accordions[i])
                        ? this.root.classList.add(this.ACC_OPEN)
                        : this.root.classList.remove(this.ACC_OPEN);
                },
                this.headings[i]
            );
            this._addListener(
                'click',
                () => {
                    /* close modal */
                    this.modal.close();
                    this.root.classList.remove(this.ACC_OPEN);
                    /* reset filters */
                    this._emit('appliedFilters', {});
                    /* emit listing filters */
                    this._emit('rt111Filters', { filters: {} });
                },
                this.resets[i]
            );
            this._addListener(
                'click',
                () => {
                    /* close modal */
                    this.modal.close();
                    this.root.classList.remove(this.ACC_OPEN);
                    /* add filters and emit */
                    const filters = Object.keys({ ...formToJSON(this.forms[i]) });
                    const appliedFilters = {
                        ...this.storeManager.get('appliedFilters'),
                    };
                    const category = this.accordions[i].dataset.filterCategory;
                    if (appliedFilters[category]) delete appliedFilters[category];
                    appliedFilters[category] = filters;
                    this._emit('appliedFilters', appliedFilters);

                    /* emit listing filters */
                    const json = {};
                    Object.keys(appliedFilters).forEach((k) => {
                        const value = appliedFilters[k];
                        if (value) json[k] = value;
                    });
                    this._emit('rt111Filters', { filters: json });
                },
                this.applies[i]
            );
        }

        this._addListener(
            'resize',
            () => {
                const modal = this.modal;
                if (window.innerWidth < BREAKPOINTS.l) {
                    /* mobile */
                    if (this.forceClosed) {
                        this.forceClosed = false;
                        modal.open();
                    }
                } else {
                    /* desktop */
                    if (modal.isOpen()) {
                        this.forceClosed = true;
                        modal.close();
                    }
                }
            },
            window
        );

        for (let i = 0; i < this.accordions.length; i++) {
            this._addListener(
                'rcInputChanged',
                () => {
                    this.register.getClass(this.applies[i]).setStatus('enabled');
                    this.register.getClass(this.resets[i]).setStatus('enabled');
                },
                this.accordions[i]
            );
        }
    }

    _addStoreListeners() {
        this._addStoreListener('reloadFilters', (path, data) => {
            this._reloadFilters(data);
        });
    }

    async _reloadFilters(data) {
        if (!Array.isArray(data)) {
            console.warn('Missing filters array to reload');
            return;
        }
        for (const acc of this.accordions) {
            acc.remove();
        }

        const filterAccordionHtml = (await import('./partials/filter-accordion.html')).default;

        /* reload filter accordions */
        for (const filter of data) {
            if (!filter.value || filter.value.length <= 0) continue;
            const accordionData = {
                uniqueId: this.root.id,
                filterId: `filter-${filter.category}`,
                title: filter.title,
                filterCategory: filter.category,
                count: filter.count,
                filters: filter.value,
                labels: {
                    reset: this.dictionary.get('Reset'),
                    apply: this.dictionary.get('Apply'),
                },
            };
            const accordionEl = runTemplate(filterAccordionHtml, accordionData);
            this.wrapper.appendChild(accordionEl);
        }
        /* restart logic */
        this._doLogic();
    }

    _isAccordionOpen(accordion) {
        return accordion.classList.contains(this.ACCORDION_OPEN);
    }

    _onCancel() {
        this.flowManager.complete();
    }
}



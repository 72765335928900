import './style.scss';
import Component from '../../../../../../libs/components/component';
import { getFlowManager } from '../../../../../../libs/flow-manager';
import { getTrackingManager } from '../../../../../../libs/tracking-manager';
import { getUserService } from '../../../../../../libs/user-service';
import { getInsurancesManager } from '../../../../../../libs/insurances-manager';
import { delay, getCookieByName, getNextSibling, writeCookieWithName } from '../../../../../../libs/utils';
import { getDictionary } from '../../../../../../libs/dictionary-provider';

export default class Page extends Component {
    constructor(name, root) {
        super(name, root);
        this.body = document.body;
        this.blockCounter = 0;
        this.trackingManager = getTrackingManager();
        this.userService = getUserService();
        this.flowManager = getFlowManager();
        this.insurancesManager = getInsurancesManager();
        this._addEventListeners();
        this._addStoreListeners();

        this.coopPages = window.coopPages || [];
        const coopPagesRegex = window.coopPagesRegex || [];
        this.coopPagesRegex = coopPagesRegex.map((item) => new RegExp(item));
        this.bookProviderUrlPatterns = window.bookProviderUrlPatterns || [];

        // metas move
        this._moveMetas();
    }

    _addEventListeners() {
        ////////////////////////////////////////
        ///////////////////////////////////////
        //////////////// TRACKING INTERCEPTOR
        ////////////////////////////////////////
        this._addListener(
            'click',
            (event) => {
                const linkEl = event.target.closest('[data-link-tracking]');
                if (!linkEl) return;
                const options = JSON.parse(linkEl.dataset.linkTracking);
                this.trackingManager.trackLink(linkEl, options);
            },
            this.root,
            { capture: true }
        );

        ////////////////////////////////////////
        ///////////////////////////////////////
        //////////////// CLICK INTERCEPTOR
        ////////////////////////////////////////
        this._addListener(
            'click',
            (event) => {
                const target = event.target.closest('a[href]');
                if (!target) return;

                //////////////////////// CHECK BOOK
                if (this._bookLink(event, target)) {
                    return;
                }

                //////////////////////// CHECK COOP
                if (this._coopLink(event, target)) {
                    return;
                }
            },
            this.root
        );
    }

    _addStoreListeners() {
        this._addStoreListener('blockScroll', (path, data) => {
            if (data > 0) this.setBlockScroll();
            else this.removeBlockScroll();
        });

        // change meta at page change
        this._addStoreListener('currentPage', () => {
            this._moveMetas();
            // launch tracking page view
            if (!this.storeManager.get('trackingPageViewDisabled')) this.trackingManager.trackPageView();
            // check insurance links
            this._setInsuranceLinks();
             // check refresh popup
            this._checkRefreshPopup();
        });
    }

    async _checkRefreshPopup() {
        let user = await getUserService().getUser();
        if (!user) return;

        if (!user.refresh) return;

        let cookieRefresh = getCookieByName('ecRefreshBanner');
        if (cookieRefresh) return;

        // get conad domain
        let domainParts = location.hostname.split('.');
        let domain = location.hostname;
        if (domainParts.length >= 2) {
            domain = '.' + domainParts.slice(-2).join('.');
        }

        // write cookie
        let currentDate = new Date();
        let expiringDate = new Date(currentDate.getTime() + 172800000);
        writeCookieWithName('ecRefreshBanner', 'true', domain, expiringDate.toUTCString());

        this.flowManager.startFlow({ flowName: 'refresh-popup', flowSteps: [{ name: 'refresh-popup' }] });
    }

    _bookLink(event, target) {
        let bookLink = null;
        for (const pattern of this.bookProviderUrlPatterns) {
            const split = pattern.split('{{anacanId}}');
            const elLink =
                (!['', 'http://', 'https://'].includes(split[0]) && target.closest(`a[href^='${split[0]}']`)) ||
                (split[1] !== '' && target.closest(`a[href$='${split[1]}']`));

            if (elLink) {
                bookLink = elLink;
                break;
            }
        }
        if (!bookLink) return false;

        // open books reservation modal
        event.preventDefault();
        this._goToBooksReservation(bookLink);
        return true;
    }

    _coopLink(event, target) {
        let link = null;
        const href = target.getAttribute('href');
        if (!href) return false;

        let cndValid = false;
        // check stanard coop pages
        for (const page of this.coopPages) {
            if (page == href) {
                link = target;
                break;
            }
        }
        // check regex
        for (const regex of this.coopPagesRegex) {
            if (href.match(regex)) {
                cndValid = true;
                link = target;
                break;
            }
        }
        if (!link) return false;

        // redirect to coop
        event.preventDefault();
        this._redirectToCoop(link, cndValid);
        return true;
    }

    async _redirectToCoop(link, cndValid = false) {
        const urlParams = new URLSearchParams(window.location.search);
        const user = await this.userService.getUser();
        const store = await this.userService.getStore();
        const coop = urlParams.get('coop') || user?.newMappedCooperative || store?.newMappedCooperative;
        if (coop || cndValid) {
            const url = new URL(link.href, window.origin);
            url.searchParams.set('coop', coop?.toLowerCase() || 'cnd');
            window.open(url.href, link.target);
            return;
        }

        //start flow
        this.flowManager.startFlow({
            flowName: 'select-pos',
            flowSteps: [{ name: 'select-pos-step1' }, { name: 'select-pos-step-2' }],
            onComplete: (flowName, data) => {
                if (data?.selectedStore) {
                    const coop = data.selectedStore.newMappedCooperative;
                    /* set coop in query string */
                    const url = new URL(link.href, window.origin);
                    url.searchParams.set('coop', coop?.toLowerCase());
                    window.open(url.href, link.target);
                }
            },
        });
        return true;
    }

    async _goToBooksReservation(link) {
        this.flowManager.startFlow({
            flowName: 'books-reservation-popup',
            flowSteps: [{ name: 'books-reservation-popup' }],
        });
        await delay(8000);
        this._emit('closeBooksPopup', {});
        window.location = link.href;
    }

    async _setInsuranceLinks() {
        const ctas = Array.from(this.root.querySelectorAll('[href="#carrello-protetto"]'));
        if (!ctas || ctas.length < 1) {
            return;
        }
        for (let i = 0; i < ctas.length; i++) {
            ctas[i].classList.add('!hidden');
        }
        const link = await this.insurancesManager.getLink();
        for (let i = 0; i < ctas.length; i++) {
            ctas[i].setAttribute('href', link.url);
            const label = ctas[i].querySelector('span.rt002-cta__label');
            label.innerHTML = getDictionary().get(link.label);
            ctas[i].classList.remove('!hidden');
        }
    }

    _moveMetas() {
        this.metas = Array.from(this.root.querySelectorAll('.rl1-layout__meta'));
        for (const meta of this.metas) {
            const target = getNextSibling(meta);
            if (target) {
                for (let key in meta.dataset) {
                    if (Object.hasOwn(meta.dataset, key)) {
                        target.dataset[key] = meta.dataset[key];
                    }
                }
            }
            meta.remove();
        }
    }

    toggleBlockPageScroll() {
        const hasBlock = this.body.classList.contains('block-scroll');
        if (hasBlock) this.removeBlockScroll();
        else this.setBlockScroll();
    }

    setBlockScroll() {
        if (this.blockCounter > 0) {
            this.blockCounter++;
            return;
        }
        const bodyWidth = this.body.clientWidth;
        this.body.classList.add('block-scroll');
        const margin = this.body.clientWidth - bodyWidth;
        //set body margin
        this.body.style.marginRight = `${margin}px`;
        //set fixed margin
        const fixedEls = this.root.querySelectorAll('.is-fixed');
        for (const fix of fixedEls) {
            fix.style.marginRight = `${margin}px`;
        }
        this.blockCounter++;
    }

    removeBlockScroll() {
        if (this.blockCounter <= 0) return;
        this.blockCounter--;
        //remove block scroll only if block counter = 0 so there is only a 1 modal/popup open on page
        if (this.blockCounter > 0) return;

        this.body.classList.remove('block-scroll');
        //set body margin
        this.body.style.marginRight = 0;
        //set fixed margin
        const fixedEls = this.root.querySelectorAll('.is-fixed');
        for (const fix of fixedEls) {
            fix.style.marginRight = 0;
        }
    }
}

import './style.scss';
import Component from '../../../../../libs/components/component';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class CardElevRecipesComponent extends Component {
    constructor(name, root) {
        super(name, root);

        this.swiper = null;
        this.slide = this._dEl('slideContainer');
        this.track = this._dEl('track', false);
        if (!this.slide) {
            this._removeMainComponent();
            return;
        }
        this.variant = this.slide.dataset.variant;
        this._initSwiper();
    }

    async _initSwiper() {
        const options = {
            wrapperClass: this._el('track'),
            slideClass: this._el('slideContainer'),
            slidesPerView: 'auto',
            spaceBetween: 16,
            watchOverflow: true,
            observer: true,
            observeParents: true,
        };

        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.root, options);
    }

    //delete all
    dispose() {
        super.dispose();
        this.swiper?.destroy(true, true);
    }
}

import { getDictionary } from './dictionary-provider';

/* poi types: fuel, margherita, pet */
const DEFAULT_POI_TYPE = 'margherita';

/**
 *
 * @param {PDV} pdv
 * @returns The pdv distance
 */
export const getPdvDistance = (pdv) => {
    return pdv.distanza
        ? `${pdv.distanza.toLocaleString('it-IT', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
          })} km`
        : null;
};

/**
 * Get the types of pdv
 * @param {*} pdv
 * @returns
 */
export const getPoiType = (pdv) => {
    const isSpecialized = pdv.specialized;
    if (!isSpecialized) return DEFAULT_POI_TYPE;

    switch (pdv.descrizioneInsegna) {
        case 'OTTICO CONAD':
            return 'eye';
        case 'PARAFARMACIA CONAD':
            return 'pharmacy';
        case 'CONAD SELF 24h':
            return 'fuel';
        case 'PET STORE CONAD':
            return 'pet';
    }
    // default poi type
    return DEFAULT_POI_TYPE;
};

///////////////////////////////////////////////////
//////////// STORE
///////////////////////////////////////////////////
export const storeData = (pdv, uniqueId = null) => {
    const dictionary = getDictionary();

    const title = pdv.pdvTitle;
    const address = pdv.pdvAddress;
    let opening = null;
    if (pdv.pdvDayOpen) {
        opening = pdv.pdvOpening;
    } else if (pdv.pdvCurrentDay) {
        opening = dictionary.get('Closed');
    }

    const open = pdv.pdvOpen;
    const insignia = pdv.descrizioneInsegna;
    const distance = getPdvDistance(pdv);

    const link = pdv.pdvLink;

    const ctaDirections = {
        label: dictionary.get('How to arrive'),
        link: pdv.pdvDirectionsLink,
    };
    const ctaSecondary = {
        label: dictionary.get('Store card'),
        link: link,
    };
    let ctaPrimary = null;
    if (pdv.volantiniCount > 0) {
        try {
            ctaPrimary = {
                label: dictionary.get('Go to flyer'),
                link: {
                    href: '#',
                    target: '_blank',
                },
            };
        } catch (error) {
            ctaPrimary = null;
        }
    }

    const storeLink = {
        label: title,
        link: link,
    };

    let finalData = {
        title: title,
        name: title,
        address: address,
        subtitle: address,
        opening: opening,
        open: open,
        ctaDirections: ctaDirections,
        ctaSecondary: ctaSecondary,
        ctaPrimary: ctaPrimary,
        link: link,
        storeId: pdv.anacanId,
        storeLink: storeLink,
        coopId: pdv.codiceCooperativa,
        insignia: insignia,
        distance: distance,

        //labels
        linkLabel: dictionary.get('Go to store card'),
        flyersLabel: dictionary.get('Look up flyers'),
        storeSavedLabel: dictionary.get('Store saved!'),
        favoriteLabel: dictionary.get('Favorite'),
        maxStoresLabel: dictionary.get('You reached the maximum number of saved stores'),
        saveAsFav: dictionary.get('Save as favorite'),
        save: dictionary.get('Save store'),
        inStoreLabel: dictionary.get('In store:'),
    };
    if (uniqueId) finalData.uniqueId = uniqueId;

    return finalData;
};

import { getRegister } from "../../../../../../libs/register";

export const getModalObjByName = (name) => {
    const modal = document.querySelector(`.ru002-modal[data-name=${name}]`);
    if (!modal) return null;
    const modalObj =  getRegister().getClass(modal);
    if (!modalObj) return null;
    return modalObj;
}

export const getCurrentModal = (element) => {
    const modalEl = element.closest('.ru002-modal');
    if (!modalEl) return;
    const modalObj =  getRegister().getClass(modalEl);
    if (!modalObj) return;
    return modalObj;
}
import './style.scss';
import Component from '../../../../../libs/components/component';
import { getDictionary } from '../../../../../libs/dictionary-provider';

export default class Cta extends Component {
    constructor(name, root) {
        super(name, root);

        // constants
        this.VARIANT = {
            primary: 'primary',
            secondary: 'secondary',
            tertiary: 'tertiary',
            semantic: 'semantic',
            link: 'link',
            social: 'social',
        };
        this.TYPE = {
            left: 'left',
            none: 'none',
            right: 'right',
            circle: 'circle',
            square: 'square',
        };
        this.SIZE = {
            small: 'small',
            medium: 'medium',
            large: 'large',
        };
        this.STATUS = {
            enabled: 'enabled',
            disabled: 'disabled',
            success: 'success',
        };
        this.ENABLED_TYPE = {
            neutralDark: 'neutralDark',
            neutralLight: 'neutralLight',
        };
        this.SEMANTIC_TYPE = {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
        };
        this.SOCIAL_TYPE = {
            google: 'google',
            facebook: 'facebook',
            apple: 'apple',
        };

        this.variant = this.root.dataset.variant;
        this.initialIcon = this.root.dataset.icon;
        this.link = this.root.dataset.link;
        this.icons = this._dEl('icon', true);
        this.label = this._dEl('label');
    }

    setStatus(status = this.STATUS.enabled) {
        if (status === this.getStatus()) return;
        if (status === this.STATUS.enabled) {
            this.root.classList.remove(this._status(this.variant, this.root.dataset.status));
            this.root.removeAttribute('data-status');
        }
        if (!this.variant) return;
        if (!Object.values(this.VARIANT).includes(this.variant)) return;
        if (!Object.values(this.STATUS).includes(status)) return;
        this.root.classList.remove(this._status(this.variant, this.root.dataset.status));
        if (status === this.STATUS.success) {
            this._resetEnabled();
            if (this.initialIcon) {
                //this.root.setAttribute('data-icon', 'check');
                this.icons.forEach((icon) => {
                    icon.classList.remove(`ec-icon-${this.initialIcon}`);
                    icon.classList.add('ec-icon-check');
                });
            }
        }
        this.root.setAttribute('data-status', status);
        this.root.classList.add(this._status(this.variant, this.root.dataset.status));
    }

    getStatus() {
        return this.root.dataset.status || this.STATUS.enabled;
    }

    setSize(size) {
        if (!size || !Object.keys(this.SIZE).includes(size)) return;
        Object.keys(this.SIZE)
            .filter((s) => s !== size)
            .forEach((s) => this.root.classList.remove(this._mod(this.SIZE[s])));
        this.root.classList.add(this._mod(this.SIZE[size]));
    }

    setIcon(icon) {
        if (!icon) return;
        this.root.setAttribute('data-icon', icon);
        this.icons.forEach((iconEl) => {
            const prev = Array.from(iconEl.classList).filter((clazz) => clazz.includes('ec-icon'))[0];
            if (prev) iconEl.classList.remove(prev);
            iconEl.classList.add(icon);
        });
    }

    setLabel(label) {
        if (label == null || label == undefined || label == '') return;
        const translated = getDictionary().get(label);
        if (this.root.hasAttribute('aria-label')) this.root.setAttribute('aria-label', translated);
        this.label.innerText = translated;
    }

    _status(variant, status) {
        if (!Object.values(this.VARIANT).includes(variant)) return;
        if (!Object.values(this.STATUS).includes(status)) return;
        return `${this.getName()}-${variant}--${status}`;
    }

    _enabledType(variant, enabledType) {
        if (!Object.values(this.VARIANT).includes(variant)) return;
        if (!Object.values(this.ENABLED_TYPE).includes(enabledType)) return;
        return `${this.getName()}-${variant}--${enabledType}`;
    }

    _resetEnabled() {
        this.root.classList.remove(this._enabledType(this.variant, this.ENABLED_TYPE.neutralDark));
        this.root.classList.remove(this._enabledType(this.variant, this.ENABLED_TYPE.neutralLight));
    }

    _var(variant, elName, all = false) {
        if (all) {
            return Array.from(this.root.querySelectorAll(`.${this.getSelector()}-${variant}__${elName}`));
        } else {
            return this.root.querySelector(`.${this.getSelector()}-${variant}__${elName}`);
        }
    }
}



import { getApiProvider } from '../../../../../libs/api-provider';
import Component from '../../../../../libs/components/component';
import { closeLoader, emptyElement, htmlToElement, openLoader } from '../../../../../libs/utils';
import './style.scss';

export default class ExcelToMapSearchStores extends Component {
    constructor(name, root) {
        super(name, root);
        this.apiProvider = getApiProvider();
        this._doLogic();
    }

    async _doLogic() {
        this.loaderEndpoint = this.root.dataset.loaderEndpoint;
        this.btnRegions = this._dEl('btnRegions');
        this.list = this._dEl('list');
        this.regions = this._dEl('region', true);
        this.map = this._dEl('map');
        await this._loadMap();
        if (this.map) this.regionMaps = [...this.map.querySelectorAll('path.regionMap')];
        this._addEventListeners();
    }

    _addEventListeners() {
        const selectRegion = (self) => {
            return (r) => {
                if (r != self) r.classList.remove('selected');
                else r.classList.add('selected');
            };
        };

        /* regions map */
        this.regionMaps?.forEach((region) => {
            this._addListener(
                'mouseenter',
                () => {
                    const regionCode = region.dataset.regionCode;
                    this.list
                        .querySelector(`${this._el('region', true)}[data-region-code="${regionCode}"]`)
                        ?.classList.add(this._elMod('region', 'mapHovered'));
                },
                region
            );
            this._addListener(
                'mouseleave',
                () => {
                    const regionCode = region.dataset.regionCode;
                    this.list
                        .querySelector(`${this._el('region', true)}[data-region-code="${regionCode}"]`)
                        ?.classList.remove(this._elMod('region', 'mapHovered'));
                },
                region
            );
            this._addListener(
                'click',
                () => {
                    const regionCode = region.dataset.regionCode;
                    const regionListItem = this.list.querySelector(
                        `${this._el('region', true)}[data-region-code="${regionCode}"]`
                    );
                    const regionName = regionListItem?.innerText.trim();
                    this.regions.forEach(selectRegion(regionListItem));
                    this.regionMaps.forEach(selectRegion(region));
                    this._loadSearchSection({
                        nomeRegione: regionName,
                    });
                },
                region
            );
        });

        /* regions list */
        this._addListener(
            'click',
            () => {
                this.btnRegions.classList.toggle('open');
            },
            this.btnRegions
        );
        this.regions?.forEach((region) => {
            this._addListener(
                'mouseenter',
                () => {
                    const regionCode = region.dataset.regionCode;
                    this.map.querySelector(`path[data-region-code="${regionCode}"]`)?.classList.add('hovered');
                },
                region
            );
            this._addListener(
                'mouseleave',
                () => {
                    const regionCode = region.dataset.regionCode;
                    this.map.querySelector(`path[data-region-code="${regionCode}"]`)?.classList.remove('hovered');
                },
                region
            );
            this._addListener(
                'click',
                () => {
                    const regionCode = region.dataset.regionCode;
                    const regionName = region.innerText.trim();
                    const regionPath = this.map.querySelector(`path[data-region-code="${regionCode}"]`);
                    this.regions.forEach(selectRegion(region));
                    this.regionMaps.forEach(selectRegion(regionPath));
                    this._loadSearchSection({
                        nomeRegione: regionName,
                    });
                },
                region
            );
        });
    }

    async _loadMap() {
        const url = this.root.dataset.mapPath;
        try {
            const html = await this.apiProvider.textGet(url);
            this._appendMap(html);
        } catch (error) {
            console.error(error);
        }
    }

    _appendMap(data) {
        /* parse map */
        const parser = new DOMParser();
        const map = parser.parseFromString(data, 'image/svg+xml');
        this.map.append(map.firstChild);
    }

    async _loadSearchSection({ nomeRegione }) {
        const url = this.loaderEndpoint;
        try {
            openLoader('main');
            const html = await this.apiProvider.loaderGet(url, { nomeRegione });
            this._updateSearchSection(html);
        } catch (error) {
            console.error(error);
        } finally {
            closeLoader('main');
        }
    }

    _updateSearchSection(html) {
        this.searchSection?.remove();
        const element = htmlToElement(html);
        this.root.append(element);
        this.searchSection = this._dEl('searchSection');
    }

    /* override */
    dispose() {
        super.dispose();
        emptyElement(this.map);
        this.searchSection?.remove();
    }
}



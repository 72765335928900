import './style.scss';
import Component from '../../../../../libs/components/component';
import { getUserService } from '../../../../../libs/user-service';
import { emptyElement, htmlToElement } from '../../../../../libs/utils';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getApiProvider } from '../../../../../libs/api-provider';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class CardElevNewsComponent extends Component {
    constructor(name, root) {
        super(name, root);

        this.loaderEndpoint = this.root.dataset.loaderEndpoint;
        if (!this.loaderEndpoint || this.loaderEndpoint == '') {
            console.warn('Missing loader endpoint for news slider.');
            // keep going with normal flow (user not logged)
        }
        this.track = this._dEl('track');
        // no need to reassign (even if slides get replaced, variant stays the same)
        this.slide = this._dEl('slideContainer');
        this.variant = this.slide.dataset.variant;
        this.swiper = null;
        this.apiProvider = getApiProvider();

        this._init();
    }

    async _init() {
        await this._initSwiper();
        this._initSlides();

        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'resize',
            () => {
                this._manageSwiperMobile();
            },
            window
        );
    }

    async _initSlides() {
        const user = await getUserService().getUser();
        if (!user) return;
        // user logged -> reload news filtered by consorzio and coop
        this.swiper?.destroy();
        this.swiper = null;
        await this._loadSlides({
            page: 1,
            cooperative: ['cnd', user.newMappedCooperative?.trim().toLowerCase()],
        });
        this._initSwiper();
    }

    async _initSwiper() {
        const options = {
            wrapperClass: this._el('track'),
            slideClass: this._el('slideContainer'),
            slidesPerView: 'auto',
            spaceBetween: 16,
            watchOverflow: true,
            observer: true,
            observeParents: true,
        };

        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this._dEl('carousel'), options);
    }

    _manageSwiperMobile() {
        if (window.innerWidth < BREAKPOINTS.m) {
            this.swiper.slideTo(0);
            this.swiper.disable();
        } else {
            this.swiper.enable();
        }
    }

    async _loadSlides({ page, cooperative }) {
        const url = this.loaderEndpoint;
        try {
            const html = await this.apiProvider.loaderGet(url, { page, cooperative });
            this._appendSlides(html);
        } catch (error) {
            console.error(error);
        }
    }

    _appendSlides(html) {
        emptyElement(this.track);
        const element = htmlToElement(html);
        const slides = [...element.querySelectorAll(this._el('slideContainer', true))];
        for (const slide of slides) {
            this.track.appendChild(slide);
        }
    }

    //delete all
    dispose() {
        super.dispose();
        this.swiper?.destroy(true, true);
    }
}

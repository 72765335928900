export const htmlMapPin = ({ OverlayView = window.google.maps.OverlayView, ...args }) => {
    class HTMLMapPin extends OverlayView {
        constructor() {
            super();
            this.position = args.position;
            this.elem = args.elem;
            this.setMap(args.map);
        }

        onAdd() {
            if (!this.div) {
                /* create div */
                this.div = document.createElement('div');
                this.div.style.position = 'absolute';
                if (this.elem) {
                    this.div.appendChild(this.elem);
                }
                /* append div to overlay */
                this.getPanes().overlayImage.appendChild(this.div);
                this.visible = true;
            }
        }

        onRemove() {
            if (!this.div) return;
            this.div.parentNode?.removeChild(this.div);
            this.div = null;
        }

        draw() {
            const point = this.getProjection().fromLatLngToDivPixel(this.position);
            if (point) {
                this.div.style.left = `${point.x}px`;
                this.div.style.top = `${point.y}px`;
            }
        }

        getPosition() {
            return this.position;
        }

        getVisible() {
            return this.visible;
        }

        setVisible(visible) {
            this.visible = visible;
        }

        getDraggable() {
            return false;
        }

        getElem() {
            return this.elem;
        }
    }

    return new HTMLMapPin();
};
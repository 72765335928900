import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { openLoader } from '../../../../../libs/utils';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { getApiProvider } from '../../../../../libs/api-provider';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class SearchResults extends Component {
    constructor(name, root) {
        super(name, root);
        this.register = getRegister();
        this.trackingManager = getTrackingManager();
        this.apiProvider = getApiProvider();
        this._doLogic();
    }

    async _doLogic() {
        await this._getElements();
        this._initSearch();
        this._initLoadMore();
        this.trackingManager.track(this.root, {
            event: 'pageView',
            funnel: {
                termineRicerca: this._getQuery(),
                numeroRisultati: this.root.dataset.results,
            },
        });
        this._addEventListeners();
    }

    async _getElements() {
        /* loader data */
        this.loaderEndpoint = this.root.dataset.loaderEndpoint;
        let initLengths = null;
        let downloadLengths = null;
        let totalLengths = null;
        try {
            initLengths = JSON.parse(this.root.dataset.initLengths);
            downloadLengths = JSON.parse(this.root.dataset.downloadLengths);
            totalLengths = JSON.parse(this.root.dataset.totalLengths);
        } catch (error) {
            console.warn('Could not parse lengths');
        }
        /* sections */
        this.section = {};
        this._dEl('section', true)?.forEach((section) => {
            if (!section.dataset.articleType) return;
            this.section[section.dataset.articleType] = {
                section: section,
                cards: section.querySelector(this._el('cards', true)),
                loadMore: section.querySelector(this._el('loadMore', true)),
                currentSize: section.querySelectorAll(this._el('card', true)).length,
                initLength: parseInt(initLengths[section.dataset.articleType]),
                downloadLength: parseInt(downloadLengths[section.dataset.articleType]),
                totalLength: parseInt(totalLengths[section.dataset.articleType]),
            };
        });
        /* filters */
        this.filters = this._dEl('filter', true);
        if (this.filters && this.filters.length >= 1) {
            this.track = this._dEl('filtersTrack');
            this.filterObjs = (await Promise.allSettled(this.filters.map((f) => this.register.getClass(f)))).map(
                (p) => p.value
            );
            this.filterAllObj = this.filterObjs.filter((fo) => fo.getInput().value == 'all')[0];
            this.filterAll = this?.filterAllObj?.root;
            await this._initScrollbarFilters();
        }
        /* search */
        this.search = this._dEl('search');
        this.searchObj = this.register.getClass(this.search);
    }

    _initSearch() {
        this.query = this._getQuery();
        this.searchObj?.setInputValue(this.query);
    }

    _initLoadMore() {
        Object.values(this.section).forEach((s) => {
            if (s.currentSize < s.totalLength) return;
            s.loadMore.classList.add(this._elMod('loadMore', 'hidden'));
        });
    }

    _addEventListeners() {
        this._addListener(
            'rt10SearchQuery',
            (e) => {
                if (!e.data.value) return;
                const searchQuery = e.data.value;
                const url = new URL(window.location);
                url.searchParams.set('query', searchQuery);
                openLoader('main');
                window.location = url.href;
            },
            this.search
        );

        if (this._dEl('filters')) {
            this._addListener(
                'rcInputChanged',
                (e) => {
                    const value = this.register.getClass(e.target).getInput().value;
                    const selected = value == e.data.value;
                    /* handle the filter ALL */
                    if (value == 'all') {
                        if (selected) {
                            /* deselect other filters (WITHOUT triggering the rcInputChanged event) */
                            this.filterObjs
                                .filter((fo) => fo.getInput().value != 'all')
                                .forEach((fo) => fo.unselect(false));
                            /* show all sections */
                            this._dEl('section', true).forEach((s) => s.classList.add(this._elMod('section', 'show')));
                        }
                        if (!selected) {
                            /* keep it selected (WITHOUT triggering the rcInputChanged event) */
                            this.filterAllObj?.select(false);
                        }
                        return;
                    }
                    /* selecting any other filter -> deselect the filter ALL (WITHOUT triggering the rcInputChanged event) */
                    if (selected) {
                        this.filterAllObj?.unselect(false);
                    }
                    /* mask sections iff the filter ALL is not changed */
                    this._maskSections();
                },
                this._dEl('filters')
            );
        }

        if (this.track) {
            this._addListener(
                'ps-x-reach-end',
                () => {
                    this.root.classList.add(this._mod('noFade'));
                },
                this.track
            );
            this._addListener(
                'ps-scroll-left',
                () => {
                    this.root.classList.remove(this._mod('noFade'));
                },
                this.track
            );
        }

        Object.values(this.section)?.forEach((s) => {
            if (!s.loadMore) return;
            this._addListener(
                'click',
                () => {
                    this._downloadResults(s.section.dataset.articleType);
                },
                s.loadMore
            );
        });
    }

    _maskSections() {
        const allUnselected = this.filterObjs.map((fo) => !!fo.getValue()).every((x) => !x);
        if (allUnselected) {
            /* select the filter ALL (WITHOUT triggering the rcInputChanged event) */
            this.filterAllObj?.select(false);
            /* show all sections */
            this._dEl('section', true).forEach((s) => s.classList.add(this._elMod('section', 'show')));
            return;
        }
        /* show/hide sections accordingly */
        this.filterObjs
            .filter((fo) => fo.getInput().value != 'all')
            .forEach((fo) => {
                const articleType = fo.getInput().value;
                if (!fo.isSelected()) {
                    this.section[articleType]?.section.classList.remove(this._elMod('section', 'show'));
                } else {
                    this.section[articleType]?.section.classList.add(this._elMod('section', 'show'));
                }
            });
    }

    _getQuery() {
        const urlParams = new URLSearchParams(window.location.search);
        const query = urlParams.get('query');
        if (query) return query;
        return '';
    }

    async _downloadResults(articleType) {
        const url = this.loaderEndpoint;
        const section = this.section[articleType];
        const data = {
            query: this.query,
            articleType: articleType,
            from: section.currentSize,
            to: section.currentSize + section.downloadLength,
        };
        try {
            const html = await this.apiProvider.loaderGet(url, data);
            this._appendResults(html, articleType);
        } catch (error) {
            console.error(error);
        }
    }

    _appendResults(results, articleType) {
        const section = this.section[articleType];
        /* parse new results */
        const parser = new DOMParser();
        const doc = parser.parseFromString(results, 'text/html');
        const loadMoreText = doc.querySelector(this._el('loader', true))?.dataset.loadMoreText;
        const newResults = doc.querySelectorAll(this._el('card', true));
        const countResults = newResults.length;

        /* hide/show loadMore */
        if (section.currentSize != 0 && countResults < section.downloadLength) {
            /* no more results to download */
            section?.loadMore?.classList.add(this._elMod('loadMore', 'hidden'));
        } else {
            /* possibly more results to download */
            section?.loadMore?.classList.remove(this._elMod('loadMore', 'hidden'));
        }

        /* append new results */
        section.cards.append(...newResults);
        /* update current size */
        section.currentSize += newResults.length;
        /* update load more text */
        section.loadMore.innerText = loadMoreText;
        /* hide/show load more */
        if (section.currentSize >= section.totalLength) {
            section.loadMore?.classList.add(this._elMod('loadMore', 'hidden'));
        }
    }

    async _initScrollbarFilters() {
        this.psFilters?.destroy();
        this.psFilters = null;
        const PerfectScrollbar = await getExtApiHelper().getPerfectScrollbar();
        this.psFilters = new PerfectScrollbar(this.track, {
            swipeEasing: true,
            suppressScrollY: true,
            wheelPropagation: false,
            useBothWheelAxes: true,
            minScrollbarLength: 32,
        });
    }
}



import './style.scss';
import Component from '../../../../../libs/components/component';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class SlideMdd extends Component {
    constructor(name, root) {
        super(name, root);

        this.carousel = this._dEl('carousel');
        this.swiper = null;
        this.pagination = this._dEl('pagination');
        this.prev = this._dElMod('btn', 'prev');
        this.next = this._dElMod('btn', 'next');
        this.slide = this._dEl('slideContainer');
        this.track = this._dEl('track', false);

        this._init();
    }

    async _init() {
        await this._initSwiper();
    }

    async _initSwiper() {
        const slideCounts = this._dEl('slideContainer', true).length;
        if (this.pagination && slideCounts <= 1) {
            this.pagination.classList.add(this._elMod('pagination', 'hidden'));
        }

        const options = {
            wrapperClass: this._el('track'),
            slideClass: this._el('slideContainer'),
            slidesPerView: 'auto',
            spaceBetween: 16,
        };

        options['scrollbar'] = {
            el: this._dEl('scrollbar'),
            dragClass: this._el('scrollbarDrag'),
            dragSize: 160,
            hide: false,
            draggable: true,
        };

        options['navigation'] = {
            nextEl: this.next,
            prevEl: this.prev,
            disabledClass: this._elMod('btn', 'disabled'),
            hiddenClass: this._elMod('btn', 'hidden'),
        };

        options['pagination'] = {
            el: this._dEl('pagination', true),
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return (
                    '<span class="' + currentClass + '"></span>' + ' di ' + '<span class="' + totalClass + '"></span>'
                );
            },
        };

        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.carousel, options);
    }

    //delete all
    dispose() {
        super.dispose();
        this.swiper.destroy(true, true);
    }
}



import './style.scss';
import Component from '../../../../../libs/components/component';

export default class CardElevRecipes extends Component {
    constructor(name, root) {
        super(name, root);

        // this.title = this._dEl('title');
        // this.abstract = this._dEl('abstract');
        // if (this.title?.innerText && this.title.innerText.length > 66) {
        //     this.title.innerHTML = this.title.innerText.substring(0, 65) + '...';
        // }
        // if (this.abstract?.innerText && this.abstract.innerText.length > 270) {
        //     this.abstract.innerHTML = this.abstract.innerText.substring(0, 269) + '...';
        // }

        this.level = this._dEl('levelLabel');
        this.icons = this._dEl('iconChef', true);
        this.chef2 = this.icons[1];
        this.chef3 = this.icons[2];
        this._initIcons();
    }

    _initIcons() {
        if (this.level) {
            switch (true) {
                case this.level.innerText == 'Facile': {
                    this.chef2.classList.add(this._elMod('icon', 'desel'));
                    this.chef3.classList.add(this._elMod('icon', 'desel'));
                    break;
                }
                case this.level.innerText == 'Media' || this.level.innerText == 'Medio': {
                    this.chef3.classList.add(this._elMod('icon', 'desel'));
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }
}



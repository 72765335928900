import './style.scss';
import Component from '../../../../../libs/components/component';
import { getUserService } from '../../../../../libs/user-service';

export default class LoginBanner extends Component {
    constructor(name, root) {
        super(name, root);
        this.userService = getUserService();
        this._doLogic();
    }

    async _doLogic() {
        this._initLinks();
        await this._checkUserLogged();
    }

    _initLinks() {
        const links = this._dEl('link', true);
        if (!links) return;
        links.forEach((link) => {
            if (!link.hasAttribute('href')) return;
            const url = new URL(link.href);
            url.searchParams.set('cb', window.location);
            link.setAttribute('href', url.href);
        });
    }

    async _checkUserLogged() {
        const user = await this.userService.getUser();
        if (!user) return;
        /* hide self */
        const event = new CustomEvent('hideLayoutItem', { bubbles: true });
        event.data = {
            hide: true,
        };
        this.root.dispatchEvent(event);
    }
}



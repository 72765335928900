import { getApiProvider } from '../../../../../libs/api-provider';
import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getGMapsHelper } from '../../../../../libs/gmaps-helper';
import { getStoreManager } from '../../../../../libs/store-manager';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { getUserService } from '../../../../../libs/user-service';

export default class MapInfoWindow extends Component {
    constructor(name, root) {
        super(name, root);

        // constants
        this.register = getRegister();
        this.gMapsHelper = getGMapsHelper();
        this.storeManager = getStoreManager();
        this.userService = getUserService();
        this.trackingManager = getTrackingManager();
        this.apiProvider = getApiProvider();

        this.MAP_PIN_SELECTOR = '.rt016-map-pin';

        this.id = this.root.id;
        this.pinId = this.root.dataset.pinId;
        this.storeId = this.root.dataset.storeId;
        this.poiLink = this._dEl('poiLink');
        this.flyerLink = this._dEl('flyerLink');

        this._addEventListeners();
    }

    _addEventListeners() {
        if (this.poiLink)
            this._addListener(
                'click',
                () => {
                    /* Tracking */
                    this.trackingManager.track(this.root, {
                        event: 'VaiAllaSchedaNegozio',
                        CustomLink: 'Click Vai alla Scheda Negozio',
                        negozioInfo: {
                            indirizzoRicerca: 'vars.currentSearchAddress',
                            idNegozio: 'data.storeId',
                            CooperativaNegozio: 'data.coopId',
                            tipologiaRisultati: 'data.resultType',
                        },
                    });
                },
                this.poiLink
            );

        if (this.flyerLink)
            this._addListener(
                'click',
                (event) => {
                    event.preventDefault();
                    this._callStoreFlyers();
                },
                this.flyerLink
            );
    }

    async _callStoreFlyers() {
        try {

            //get flyers
            const flyers = await getApiProvider().getStoreFlyers(this.storeId);
            if (!flyers || flyers.length == 0) return;
            const flyer = flyers[0];

            /* Tracking */
            this.trackingManager.track(this.root, {
                event: 'VaiAlVolantino',
                CustomLink: 'Click Vai al volantino',
                negozioInfo: {
                    indirizzoRicerca: 'vars.currentSearchAddress',
                    idNegozio: 'data.storeId',
                    CooperativaNegozio: 'data.coopId',
                    tipologiaRisultati: 'data.resultType',
                },
                volantinoInfo: {
                    idVolantino: flyer.id,
                    CooperativaVolantino: flyer.cooperative,
                    nomeVolantino: flyer.name,
                    TipologiaVolantino: flyer.hasDisaggregated ? 'Disaggregato' : 'Sfogliatore',
                },
            });

            window.open(flyer.link.href, '_blank'); // open flyer
        } catch (error) {
            console.warn('Could not get store flyers');
        }
    }
}



/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let uniqueid = args[1]['uniqueId'] || '';
    let message = args[1]['message'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n    ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "rt063-contact-form__statusMsg", 'attribute');
    $.dom.attr($t, 'role', "alert", 'attribute');
    const var_attrValue0 = (uniqueid) + ("--error-title");
    if (!$.col.empty(var_attrValue0)) {
      $.dom.attr($t, 'aria-labelledby', var_attrValue0, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("h3",false,false);
    const var_attrValue1 = (uniqueid) + ("--error-title");
    if (!$.col.empty(var_attrValue1)) {
      $.dom.attr($t, 'id', var_attrValue1, 'attribute');
    }
    $n = $.dom.push($n,$t);
    const var_2 = yield $.xss(message, "html");
    $.dom.append($n, var_2);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    $.dom.text($n,"\n");
    return $.dom.end();

  });
};

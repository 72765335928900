import './style.scss';
import Component from '../../../../../libs/components/component';

export default class CardServicesTwoColumnsComponent extends Component {
    constructor(name, root) {
        super(name, root);
    }

}



import './style.scss';
import Component from '../../../../../libs/components/component';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { storeData } from '../../../../../libs/pdv-utils';
import { getDictionary } from '../../../../../libs/dictionary-provider';
import { getFlowManager } from '../../../../../libs/flow-manager';
import { getApiProvider } from '../../../../../libs/api-provider';
import { getTrackingManager } from '../../../../../libs/tracking-manager';

export default class CarrotBannerFmf extends Component {
    constructor(name, root) {
        super(name, root);

        this.dictionary = getDictionary();
        this.flowManager = getFlowManager();
        this.apiProvider = getApiProvider();
        this.trackingManager = getTrackingManager();

        this.flyerVariant = this.root.dataset.flyerVariant;
        if (!this.flyerVariant) {
            console.error('Missing flyerVariant');
            return;
        }

        if (this.flyerVariant == '1/3') {
            this.flyerVariant = this._getAnacanIdParam() ? '1' : '3';
            this.root.setAttribute('data-flyer-variant', this.flyerVariant); // set on html
        }

        this.flyer = this.root.hasAttribute('data-flyer') ? JSON.parse(this.root.dataset.flyer) : null;
        this.fmfTitleV1 = this.root.dataset.fmfTitleV1 || '';
        this.fmfTitleV2 = this.root.dataset.fmfTitleV2 || '';
        this.fmfTextV2 = this.root.dataset.fmfTextV2 || '';
        this.fmfTitleV3 = this.root.dataset.fmfTitleV3 || '';
        this.viewAllLabel = this.root.dataset.viewAllLabel || 'View all';

        this._doLogic();
    }

    async _doLogic() {
        if (this._getAnacanIdParam()) {
            try {
                this.store = await getApiProvider().getPointOfServiceByAnacanId({ anacanId: this._getAnacanIdParam() });
            } catch (e) {
                console.warn('Cannot get store ' + this._getAnacanIdParam());
            }
        }

        await this._renderContent();
        this._getElements();
        this._addEventListeners();
    }

    async _renderContent() {
        if (!this.flyerVariant) return;

        const flyerVariant1Call = (await import('./partials/flyer-variant-1.html')).default;
        const flyerVariant2Call = (await import('./partials/flyer-variant-2.html')).default;
        const flyerVariant3Call = (await import('./partials/flyer-variant-3.html')).default;

        switch (this.flyerVariant) {
            case '1': {
                if (!this.store || !this.store.anacanId) break;
                const data = {
                    fmfTitle: this.fmfTitleV1,
                    ...(await storeData(this.store)),
                };
                const contentEl = runTemplate(flyerVariant1Call, data);
                Array.from(contentEl.children).forEach((child) => this.root.append(child));
                break;
            }
            case '2': {
                const data = {
                    fmfTitle: this.fmfTitleV2,
                    fmfText: this.fmfTextV2,
                };
                const contentEl = runTemplate(flyerVariant2Call, data);
                Array.from(contentEl.children).forEach((child) => this.root.append(child));
                break;
            }
            case '3': {
                const data = {
                    fmfTitle: this.fmfTitleV3,
                    viewAllLabel: this.dictionary.get(this.viewAllLabel),
                };
                const contentEl = runTemplate(flyerVariant3Call, data);
                Array.from(contentEl.children).forEach((child) => this.root.append(child));
                break;
            }
            default:
                break;
        }
    }

    _getElements() {
        if (!this.flyerVariant) return;
        switch (this.flyerVariant) {
            case '1': {
                this.storeLink = this._dEl('link');
                this.storeLinkMob = this._dEl('mobLink');
                break;
            }
            case '2': {
                break;
            }
            case '3': {
                this.viewAll = this._dEl('viewAll');
                break;
            }
            default:
                break;
        }
    }

    _addEventListeners() {
        if (!this.flyerVariant) return;
        switch (this.flyerVariant) {
            case '1': {
                this._addListener(
                    'click',
                    () => {
                        this._trackGoToStore();
                    },
                    this.storeLink
                );
                this._addListener(
                    'click',
                    () => {
                        this._trackGoToStore();
                    },
                    this.storeLinkMob
                );
                break;
            }
            case '2': {
                break;
            }
            case '3': {
                this._addListener(
                    'click',
                    () => {
                        this._emit('posList', { stores: this.flyer.stores });
                        this.flowManager.startFlow({
                            flowName: 'pos-list',
                            flowSteps: [{ name: 'pos-list' }],
                        });
                    },
                    this.viewAll
                );
                break;
            }
            default:
                break;
        }
    }

    _trackGoToStore() {
        this.trackingManager.track(this.root, {
            funnel: {
                stepFunnel: 'Interazioni con pagina volantino',
            },
            event: 'VaiAllaSchedaNegozio',
            CustomLink: 'Click Vai alla Scheda Negozio',
        });
    }

    _getAnacanIdParam() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('anacanId');
    }
}



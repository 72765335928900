import './style.scss';
import Component from '../../../../../libs/components/component';
import { calcLeft, calcTop } from '../../../../../libs/utils';


export default class Tooltip extends Component {
    constructor(name, root) {
        super(name, root);
        this._addEventListeners();
    }

    _addEventListeners() {

        this._addListener(
            'showTooltip',
            (event) => {
                if (!event.data.tooltipIcon) return;
                this.tooltipIcon = event.data.tooltipIcon;
                if (!event.data.show) {
                    this.root.classList.remove(this._mod('show'));
                    return;
                }
                this._setTooltipPosition();
                this.root.classList.add(this._mod('show'));
            },
        );

        this._addListener(
            'resize',
            () => {
                if (!this.isOpen()) return;
                this._setTooltipPosition();
            },
            window
        );

        /* click outside the popup */
        this._addListener(
            'click',
            (event) => {
                if (!this.isOpen()) return;
                const target = event.target;
                if (target.closest(this.getSelector()) || target.closest('.rt008-text-field__info')) return;
                this.root.classList.remove(this._mod('show'));
            },
            document
        );
    }

    isOpen() {
        return this.root.classList.contains(this._mod('show'));
    }

    _setTooltipPosition() {
        if (!this.tooltipIcon) return;
        this.root.style.top = calcTop(this.root, this.tooltipIcon);
        this.root.style.left = calcLeft(this.root, this.tooltipIcon);
    }
}



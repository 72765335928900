import './style.scss';
import Component from '../../../../../libs/components/component';

export default class CarrotBannerHsd extends Component {
    constructor(name, root) {
        super(name, root);

        this.storeLocatorLink = this.root.hasAttribute('data-store-locator-link')
            ? JSON.parse(this.root.dataset.storeLocatorLink)
            : null;
        this.address = this._dEl('address');

        this._addEventListeners();
    }

    _addEventListeners() {
        if (this.address)
            this._addListener(
                'rcInputChanged',
                (event) => {
                    if (!event.data.value || !event.data.value.geometry || !event.data.value.types) {
                        this.selectedAddress = null;
                        return;
                    }
                    this.selectedAddress = event.data.value;
                    if (!this.storeLocatorLink || !this.storeLocatorLink.href) return;
                    /* redirect to store locator */
                    const stUrl = this.storeLocatorLink.href.match(/^https?:.*/)
                        ? this.storeLocatorLink.href
                        : window.location.origin + this.storeLocatorLink.href;
                    try {
                        const url = new URL(stUrl);
                        url.searchParams.set('s', this.selectedAddress.formatted_address);
                        window.location = url.href;
                    } catch (error) {
                        console.warn(error);
                    }
                },
                this.address
            );
    }
}



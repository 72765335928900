
import './style.scss';
import Component from '../../../../../libs/components/component';
import { getDictionary } from '../../../../../libs/dictionary-provider';

export default class CarrotBannerHmd extends Component {
    constructor(name, root) {
        super(name, root);
        this.dictionary = getDictionary();
        this.findStoreText = this._dEl('findStoreText');
        this._initFindStoreText();
    }

    _initFindStoreText() {
        if (!this.findStoreText) return;
        const text = this.findStoreText.dataset.text;
        if (!text) return;
        const loginLink = this.findStoreText.dataset.loginLink ? JSON.parse(this.findStoreText.dataset.loginLink) : null;
        const registerLink = this.findStoreText.dataset.registerLink ? JSON.parse(this.findStoreText.dataset.registerLink) : null;
        let renderedText = text;
        if (text.includes('{{login}}') && loginLink && loginLink.href) {
            const url = new URL(loginLink.href);
            url.searchParams.set('cb', window.location.href);
            renderedText = renderedText.replace(
                '{{login}}',
                `<a href="${url.href}">${loginLink.label || (this.dictionary.get('Login'))}</a>`
            );
        }
        if (text.includes('{{register}}') && registerLink && registerLink.href) {
            const url = new URL(registerLink.href);
            url.searchParams.set('cb', window.location.href);
            renderedText = renderedText.replace(
                '{{register}}',
                `<a href="${url.href}">${registerLink.label || (this.dictionary.get('register'))}</a>`
            );
        }
        this.findStoreText.innerHTML = renderedText;
    }
}



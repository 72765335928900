import './style.scss';
import Component from '../../../../../libs/components/component';
import { getDictionary } from '../../../../../libs/dictionary-provider';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { generateShareLinks } from '../../../../../libs/utils';

export default class FlyerViewer extends Component {
    constructor(name, root) {
        super(name, root);
        this.dictionary = getDictionary();
        this.trackingManager = getTrackingManager();
        this.switch = document.querySelector('.rt152-switch-component');

        this.FULLSCREEN = false;
        this.DONE = false;
        this.PAGEVIEW = {
            event: 'Page',
            funnel: {
                nomeFunnel: 'Volantino',
                stepFunnel: 'Sfoglia Volantino',
            },
            volantinoInfo: {
                azione: 'pageview',
                NumeroPagina: this.currentPage,
                TotalePagine: this.totalPages,
                TipologiaVolantino: 'Sfogliatore',
            },
        };
        this.TRACKING_CONTEXT = {
            funnel: {
                nomeFunnel: 'Volantino',
                stepFunnel: 'Sfoglia Volantino',
            },
            volantinoInfo: {
                NumeroPagina: this.currentPage,
                TotalePagine: this.totalPages,
                TipologiaVolantino: 'Sfogliatore',
            },
        };
        this.fullDateFormatter = new Intl.DateTimeFormat('it-IT', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
        this.shortDateFormatter = new Intl.DateTimeFormat('it-IT', { day: 'numeric', month: 'long' });
        this.dayFormatter = new Intl.DateTimeFormat('it-IT', { day: 'numeric' });

        this.flyerFrom = this.root.dataset.flyerFrom;
        this.flyerTo = this.root.dataset.flyerTo;
        this.flyerBgColor = this.root.dataset.flyerBgColor;

        this.validity = this._dEl('validity');
        if (this.validity) this._setFlyerValidity();

        if (this._dEl('share')) this.share = this._dEl('share').querySelector(this._el('link', true));

        this.embedContainerId = this._dEl('embedContainer').id;

        this._createPlayer();
        this._getShareId();
        this._addEventListeners();
    }

    async _createPlayer() {
        if (this.initialized) return;
        if (this._dEl('embedContainer').offsetParent == null) {
            return;
        }
        this.initialized = true;

        let yumpuDomain = 'https://volantini.conad.it/';
        const regexDomain = /^.*\.([^.]+\.it)$/;
        const match = window.location.hostname.match(regexDomain);
        if (match) {
            yumpuDomain = `https://volantini.${match[1]}/`;
        }

        this.yumpu = await getExtApiHelper().getYumpuApi();
        this.yumpu.create_player('#' + this.embedContainerId, this.root.dataset.flyerId, {
            fallback_order: 'hornet,html5,js',
            lang: document.documentElement.lang,
            canvasBGColor: this.flyerBgColor,
            canvasFSBGColor: this.flyerBgColor,
            playerElementSearchEmbed: '0',
            playerElementFSShareLinks: '0',
            disableMobileFullscreen: false,
            ydomain: yumpuDomain,
        });

        setTimeout(() => {
            this._calcPages();
        }, 3000);

        setTimeout(() => {
            this._observe();
        }, 3000);

        setTimeout(() => {
            this.PAGEVIEW.volantinoInfo.NumeroPagina = this.currentPage;
            this.PAGEVIEW.volantinoInfo.TotalePagine = this.totalPages;
            this.trackingManager.track(this.root, this.PAGEVIEW);
        }, 3000);
    }

    _addEventListeners() {
        this._addListener(
            'rcInitFlyerViewer',
            (event) => {
                this._createPlayer();
                event.stopPropagation();
            },
            this.switch
        );

        this._addListener('pointerdown', (event) => {
            this._elementPointer = event.target;
        });

        //add tracking on links on page
        this._addListener(
            'pointerup',
            () => {
                const button = this._elementPointer.closest('button');
                const slider = this._elementPointer.closest('div');

                if (slider.classList.contains('rt133-flyer-viewer__embedContainer')) {
                    /* sfoglia */
                    setTimeout(() => {
                        if (this.prevPage != this.currentPage) {
                            if (this.prevPage > this.currentPage) {
                                /* sfoglia sinistra */
                                const left = this.TRACKING_CONTEXT;
                                left.event = 'SfogliaSinistra';
                                left.CustomLink = 'Sfoglia Sinistra';
                                left.volantinoInfo.azione = 'Left';
                                left.volantinoInfo.Trigger = 'Sfoglia';
                                left.volantinoInfo.NumeroPagina = this.currentPage;
                                left.volantinoInfo.TotalePagine = this.totalPages;
                                this.trackingManager.track(this.root, left);
                                this.PAGEVIEW.volantinoInfo.NumeroPagina = this.currentPage;
                                this.PAGEVIEW.volantinoInfo.TotalePagine = this.totalPages;
                                this.trackingManager.track(this.root, this.PAGEVIEW);
                            } else {
                                const right = this.TRACKING_CONTEXT;
                                right.event = 'SfogliaDestra';
                                right.CustomLink = 'Sfoglia Destra';
                                right.volantinoInfo.azione = 'Right';
                                right.volantinoInfo.Trigger = 'Sfoglia';
                                right.volantinoInfo.NumeroPagina = this.currentPage;
                                right.volantinoInfo.TotalePagine = this.totalPages;
                                this.trackingManager.track(this.root, right);
                                this.PAGEVIEW.volantinoInfo.NumeroPagina = this.currentPage;
                                this.PAGEVIEW.volantinoInfo.TotalePagine = this.totalPages;
                                this.trackingManager.track(this.root, this.PAGEVIEW);
                            }
                        }
                    }, 700);
                }

                if (
                    slider.classList.contains('yp-page-controls-pageslider') ||
                    slider.classList.contains('page-slider-line') ||
                    slider.classList.contains('yp-slider-line-before')
                ) {
                    /* slider */
                    setTimeout(() => {
                        const slider = this.TRACKING_CONTEXT;
                        slider.event = 'SfogliaSlider';
                        slider.CustomLink = 'Sfoglia Slider';
                        slider.volantinoInfo.azione = 'Slider';
                        slider.volantinoInfo.Trigger = '__delete__';
                        slider.volantinoInfo.NumeroPagina = this.currentPage;
                        slider.volantinoInfo.TotalePagine = this.totalPages;
                        this.trackingManager.track(this.root, slider);
                        this.PAGEVIEW.volantinoInfo.NumeroPagina = this.currentPage;
                        this.PAGEVIEW.volantinoInfo.TotalePagine = this.totalPages;
                        this.trackingManager.track(this.root, this.PAGEVIEW);
                    }, 700);
                }

                if (button) {
                    const buttonId = button.id.replace(/-\d+/, '');
                    switch (buttonId) {
                        case 'yp-zpb': {
                            /* ingrandisci zoom */
                            const zoomP = this.TRACKING_CONTEXT;
                            zoomP.event = 'IngrandisciZoom';
                            zoomP.CustomLink = 'Ingrandisci Zoom';
                            zoomP.volantinoInfo.azione = 'Zoom+';
                            zoomP.volantinoInfo.Trigger = '__delete__';
                            zoomP.volantinoInfo.NumeroPagina = this.currentPage;
                            zoomP.volantinoInfo.TotalePagine = this.totalPages;
                            this.trackingManager.track(this.root, zoomP);
                            break;
                        }
                        case 'yp-zmb': {
                            /* restringi zoom */
                            const zoomM = this.TRACKING_CONTEXT;
                            zoomM.event = 'RestringiZoom';
                            zoomM.CustomLink = 'Restringi Zoom';
                            zoomM.volantinoInfo.azione = 'Zoom-';
                            zoomM.volantinoInfo.Trigger = '__delete__';
                            zoomM.volantinoInfo.NumeroPagina = this.currentPage;
                            zoomM.volantinoInfo.TotalePagine = this.totalPages;
                            this.trackingManager.track(this.root, zoomM);
                            break;
                        }
                        case 'yp-fsb': {
                            this.FULLSCREEN = true;
                            break;
                        }
                        case 'yp-nar': {
                            /* right button */
                            setTimeout(() => {
                                const rightB = this.TRACKING_CONTEXT;
                                rightB.event = 'SfogliaDestra';
                                rightB.CustomLink = 'Sfoglia Destra';
                                rightB.volantinoInfo.azione = 'Right';
                                rightB.volantinoInfo.Trigger = 'Click';
                                rightB.volantinoInfo.NumeroPagina = this.currentPage;
                                rightB.volantinoInfo.TotalePagine = this.totalPages;
                                this.trackingManager.track(this.root, rightB);
                                this.PAGEVIEW.volantinoInfo.NumeroPagina = this.currentPage;
                                this.PAGEVIEW.volantinoInfo.TotalePagine = this.totalPages;
                                this.trackingManager.track(this.root, this.PAGEVIEW);
                            }, 600);
                            break;
                        }
                        case 'yp-nal': {
                            /* left button */
                            setTimeout(() => {
                                const leftB = this.TRACKING_CONTEXT;
                                leftB.event = 'SfogliaSinistra';
                                leftB.CustomLink = 'Sfoglia Sinistra';
                                leftB.volantinoInfo.azione = 'Left';
                                leftB.volantinoInfo.Trigger = 'Click';
                                leftB.volantinoInfo.NumeroPagina = this.currentPage;
                                leftB.volantinoInfo.TotalePagine = this.totalPages;
                                this.trackingManager.track(this.root, leftB);
                                this.PAGEVIEW.volantinoInfo.NumeroPagina = this.currentPage;
                                this.PAGEVIEW.volantinoInfo.TotalePagine = this.totalPages;
                                this.trackingManager.track(this.root, this.PAGEVIEW);
                            }, 600);
                            break;
                        }

                        default:
                            break;
                    }
                }
            },
            this.root
        );

        this._addListener(
            'resize',
            () => {
                if (window.innerHeight == screen.height && this.FULLSCREEN && !this.DONE) {
                    /* fullscreen open*/
                    const fullO = this.TRACKING_CONTEXT;
                    fullO.event = 'Fullscreen';
                    fullO.CustomLink = 'Fullscreen';
                    fullO.volantinoInfo.azione = 'FS Open';
                    fullO.volantinoInfo.Trigger = '__delete__';
                    fullO.volantinoInfo.NumeroPagina = this.currentPage;
                    fullO.volantinoInfo.TotalePagine = this.totalPages;
                    this.trackingManager.track(this.root, fullO);
                    this.DONE = true;
                } else if (this.FULLSCREEN) {
                    if (this.DONE) {
                        this.DONE = false;
                        return;
                    }
                    /* fullscreen exit*/
                    const fullE = this.TRACKING_CONTEXT;
                    fullE.event = 'Fullscreen';
                    fullE.CustomLink = 'Fullscreen';
                    fullE.volantinoInfo.azione = 'FS exit';
                    fullE.volantinoInfo.Trigger = '__delete__';
                    fullE.volantinoInfo.NumeroPagina = this.currentPage;
                    fullE.volantinoInfo.TotalePagine = this.totalPages;
                    this.trackingManager.track(this.root, fullE);
                    this.FULLSCREEN = false;
                }
            },
            window
        );

        if (this.share) {
            this._addListener(
                'click',
                (e) => {
                    e.preventDefault();
                    if (!this.shareId) return;
                    const event = new CustomEvent('showShareLinks', { bubbles: true });
                    event.data = {
                        root: this._dEl('share').querySelector('span:first-child'),
                    };
                    document.getElementById(this.shareId).dispatchEvent(event);
                    e.stopPropagation();
                },
                this.share
            );
        }
    }

    async _getShareId() {
        this.shareLink = this.root.hasAttribute('data-share') ? JSON.parse(this.root.dataset.share) : null;
        if (this.shareLink) this.shareId = await generateShareLinks(this.shareLink.href, this.shareLink.title);
    }

    _setFlyerValidity() {
        if (!this.validity) return;
        this.validity.innerText = this._getValidity(this.flyerFrom, this.flyerTo);
    }

    _getValidity(validFrom, validTo) {
        const from = this.dictionary.get('From the');
        const to = this.dictionary.get('to the');
        const until = this.dictionary.get('Until');

        if (!validFrom && !validTo) {
            return this.dictionary.get('Always valid');
        }
        if (!validFrom && validTo) {
            let eDate = new Date(parseInt(validTo));
            return `${until} ${this.fullDateFormatter.format(eDate)}`;
        }
        if (!validTo && validFrom) {
            let sDate = new Date(parseInt(validFrom));
            return `${from} ${this.fullDateFormatter.format(sDate)}`;
        }
        let sDate = new Date(parseInt(validFrom));
        let eDate = new Date(parseInt(validTo));
        if (sDate.getFullYear() !== eDate.getFullYear()) {
            return `${from} ${this.fullDateFormatter.format(sDate)} ${to} ${this.fullDateFormatter.format(eDate)}`;
        }
        if (sDate.getMonth() !== eDate.getMonth()) {
            return `${from} ${this.shortDateFormatter.format(sDate)} ${to} ${this.fullDateFormatter.format(eDate)}`;
        }

        return `${from} ${this.dayFormatter.format(sDate)} ${to} ${this.fullDateFormatter.format(eDate)}`;
    }

    _calcPages() {
        let el = document.getElementById('yp-pcp-0');
        if (el) this._getPages(el.innerText);
    }

    _observe() {
        const observer = new MutationObserver((mutations) => {
            for (let i = 0; i < mutations.length; ++i) {
                this._getPages(mutations[i].target.innerText);
            }
        });
        let el = document.getElementById('yp-pcp-0');
        if (el) observer.observe(el, { childList: true });
    }

    _getPages(page) {
        this.pages = page.split('/');
        this.prevPage = this.currentPage;
        this.currentPage = this.pages[0].replace('-', ',')?.trim();
        this.totalPages = this.pages[1]?.trim();
    }
}



const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

export const cDialogOn = (el, { value, expression }, { evaluateLater, cleanup }) => {
    const evExpression = evaluateLater(expression);

    const pm = el.closest('[aria-modal=true]');
    if (!pm) return;

    let prefix = 'ecModal';
    if (pm.matches('.mt4-popup')) prefix = 'ecPopup';

    const event = `${prefix}${capitalize(value)}`;
    const evl = pm.addEventListener(event, () => {
        evExpression();
    });

    cleanup(() => {
        pm.removeEventListener(event, evl);
    });
};

import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getGMapsHelper } from '../../../../../libs/gmaps-helper';
import { delay } from '../../../../../libs/utils';
import { styledMapFeatures } from './scripts/styled-map';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { ClusterPinRenderer } from '../rt016-map-pin/scripts/cluster-pin-renderer';
import { htmlMapPin } from '../rt016-map-pin/scripts/html-map-pin';
import { getFlowManager } from '../../../../../libs/flow-manager';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getPoiType } from '../../../../../libs/pdv-utils';

export default class SelectPosStep2Map extends Component {
    constructor(name, root) {
        super(name, root);
        this.gMapsHelper = getGMapsHelper();
        this.register = getRegister();
        this.flowManager = getFlowManager();

        this.BREAKPOINT_L = BREAKPOINTS.l;
        this.PRE_SHOW = this._mod('preShow');
        this.SHOW = this._mod('show');
        this.mapEl = this._dEl('map');
        this.map = null;
        this.markerClusterer = null;
        this.markers = [];
        this.markersMap = {};
        this.selectedMarker = null;
        this.closer = this._dEl('closer');

        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            () => {
                this.flowManager.backOrComplete();
            },
            this.closer
        );
        this._addListener(
            'clickedStore',
            (event) => {
                const data = event.data;
                if (!data.pinId) return;
                this.selectPoi(data.pinId, false);
                this.listObj.setSelectedStoreId(data.pinId);
                if (window.innerWidth >= this.BREAKPOINT_L) {
                    /* desktop: list is visible, select store directly */
                    this.listObj.selectPos(data.pinId);
                }
            },
            this.mapEl
        );
        this._addListener(
            'clickedAggregator',
            (event) => {
                const data = event.data;
                if (!data.position) return;
                this._resetPin();
                this.map.panTo(data.position);
                this.map.setZoom(this.map.getZoom() < 15 ? this.map.getZoom() + 2 : this.map.getZoom() + 1);
            },
            this.mapEl
        );
    }

    async init(listObj) {
        this.listObj = listObj;
        this.root.classList.add(this.PRE_SHOW);
        if (!this.map) {
            const maps = await this.gMapsHelper.getGMaps();
            this.map = new maps.Map(this.mapEl, {
                center: { lat: 43, lng: 12 },
                zoom: 10,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
            });

            const styledMapType = new maps.StyledMapType(styledMapFeatures);
            this.map.mapTypes.set('styled_map', styledMapType);
            this.map.setMapTypeId('styled_map');
        }

        await delay(500);
        this.root.classList.add(this.SHOW);
    }

    async addResults(stores) {
        if (!stores || stores.length <= 0) return;
        const maps = await this.gMapsHelper.getGMaps();
        const mapPinCall = (await import('../rt016-map-pin/rt016-map-pin.html')).default;

        if (this.markers.length > 0) {
            this._emptyMarkers();
        }

        this.currentBounds = new maps.LatLngBounds();
        for (const poi of stores) {
            /* get latlng */
            const position = new maps.LatLng(poi.latitudine, poi.longitudine);
            /* get marker element dynamically */
            const pinData = {
                uniqueId: `pin-${poi.anacanId}`,
                pinId: poi.anacanId,
                type: getPoiType(poi),
            };
            const mapPin = runTemplate(mapPinCall, pinData);
            /* create map marker */
            const marker = htmlMapPin({
                position: position,
                elem: mapPin,
                map: this.map,
            });
            /* set map params on pin object */
            const markerObj = this.register.getClass(mapPin);
            markerObj.setMapParams(this.map, position, marker, poi, true);
            /* add marker to the map */
            this.markersMap[poi.anacanId] = markerObj;
            this.markers.push(marker);
            this.currentBounds.extend(position);
        }

        this.map.fitBounds(this.currentBounds);
        this.markerClusterer?.reset();
        const MarkerClusterer = await getGMapsHelper().getMarkerClusterer();
        this.markerClusterer = new MarkerClusterer({
            map: this.map,
            markers: this.markers,
            renderer: new ClusterPinRenderer(this.map, this.register, mapPinCall),
        });
    }

    selectPoi(anacanId, panTo = true) {
        const pinObj = this.markersMap[anacanId];
        if (!pinObj || !pinObj.position || !this.map) {
            console.warn(`Cannot find marker for store ${anacanId}`);
            return;
        }

        if (pinObj.isSelected()) {
            this._resetPin();
            return;
        }

        this._resetPin(pinObj);
        if (panTo) {
            this.map.panTo(pinObj.getPosition());
            this.map.setZoom(17);
        }
        this.selectedMarker = pinObj;
    }

    _resetPin(selectedPinObj = null) {
        for (const prop in this.markersMap) {
            if (Object.hasOwn(this.markersMap, prop)) {
                if (selectedPinObj == this.markersMap[prop]) this.markersMap[prop].setSelected(true);
                else this.markersMap[prop].setSelected(false);
            }
        }
    }

    /* called by rt251-select-pos-step2 */
    panSelected() {
        if (this.selectedMarker) {
            const pos = this.selectedMarker.getPosition();
            this.map.panTo(pos);
            this.map.setZoom(17);
        } else if (this.currentBounds) {
            this.map.fitBounds(this.currentBounds);
        } else {
            this.map.panTo({ lat: 43, lng: 12 });
            this.map.setZoom(6);
        }
    }

    _emptyMarkers() {
        this._resetPin();
        for (const marker of this.markers) {
            marker.setMap(null);
        }
        this.markers = [];
        this.markersMap = {};
        this.selectedMarker = null;
        this.markerClusterer?.clearMarkers();
    }

    destroy() {
        this.root.classList.remove(this.PRE_SHOW);
        this.root.classList.remove(this.SHOW);
    }
}



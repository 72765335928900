import './style.scss';
import ModalComponent from '../../../../../libs/components/modal-component';
import { getFlowManager } from '../../../../../libs/flow-manager';

export default class SelectPosStep1 extends ModalComponent {
    constructor(name, root) {
        super(name, root);
        this.flowManager = getFlowManager();

        this.selectStore = this._dEl('selectStore');
        this.links = this._dEl('link', true);

        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            () => {
                this.flowManager.next('select-pos-step-2');
            },
            this.selectStore
        );
        this.links.forEach((link) => {
            this._addListener(
                'click',
                (event) => {
                    event.preventDefault();
                    if (!link.href) return;
                    const redirectUrl = new URL(link.href);
                    redirectUrl.searchParams.set('cb', window.location);
                    window.location = redirectUrl.href;
                },
                link
            );
        });
    }

    _onCancel() {
        this.flowManager.complete();
    }
}



import './style.scss';
import Component from '../../../../../libs/components/component';

export default class HeroCarta extends Component {
    constructor(name, root) {
        super(name, root);
        this.arrow = this._dEl('icon');

        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener('click', () => {
            const offset = window.innerWidth < this.BREAKPOINT_L ? 300 : 400;
            window.scrollBy(0, offset);
        },
        this.icon
        );
    }
}



import './style.scss';
import Component from '../../../../../libs/components/component';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { calcLeft, calcTop, emptyElement } from '../../../../../libs/utils';

export default class ShareLinks extends Component {
    constructor(name, root) {
        super(name, root);

        this.sharers = {
            facebook: 'Facebook',
            twitter: 'Twitter',
            linkedin: 'LinkedIn',
            whatsapp: 'Whatsapp',
            pinterest: 'Pinterest',
            email: 'Email',
        };
        this.shareUrl = this.root.dataset.shareUrl;
        if (!this.shareUrl) return;
        this.shareTitle = this.root.hasAttribute('data-share-title') ? this.root.dataset.shareTitle : null;
        this.ignoreSharers = this.root.hasAttribute('data-ignore-sharers')
            ? this.root.dataset.ignoreSharers.split(',')
            : null;
        this.shareOptions = this._dEl('shareOptions');

        this._doLogic();
    }

    async _doLogic() {
        await this._renderShareLinks();
        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'showShareLinks',
            (event) => {
                if (!event.data.root) {
                    console.warn('Missing root for the popup');
                    return;
                }
                this.hookedRoot = event.data.root;
                if (!this.isOpen()) {
                    this._setTooltipPosition();
                }
                this.root.classList.toggle(this._mod('show'));
                if (this.isOpen()) this.root.focus();
            },
            this.root
        );
        this._addListener(
            'resize',
            () => {
                if (!this.isOpen()) return;
                this._setTooltipPosition();
            },
            window
        );
        /* click outside the popup */
        this._addListener(
            'click',
            (event) => {
                if (!this.isOpen()) return;
                const target = event.target;
                if (target.closest(this.getSelector())) return;
                this.root.classList.remove(this._mod('show'));
            },
            document
        );
    }

    isOpen() {
        return this.root.classList.contains(this._mod('show'));
    }

    _setTooltipPosition() {
        if (!this.hookedRoot) return;
        this.root.style.top = calcTop(this.root, this.hookedRoot, 4);
        this.root.style.left = calcLeft(this.root, this.hookedRoot);
    }

    async _renderShareLinks() {
        const shareOptionHtml = (await import('./partials/share-option.html')).default;
        emptyElement(this.shareOptions);
        this.shareLinksMap = this._computeShareLinks(this.shareUrl, this.shareTitle, this.ignoreSharers);
        for (const key of Object.keys(this.shareLinksMap)) {
            const shareOptionData = {
                sharer: key,
                sharerLabel: this.sharers[key],
                shareUrl: this.shareLinksMap[key],
                target: '_blank',
            };
            const shareOption = runTemplate(shareOptionHtml, shareOptionData);
            this.shareOptions.appendChild(shareOption);
        }
    }

    _computeShareLinks(href, title, ignoreSharers = []) {
        const ignore = ignoreSharers || [];
        const shareLinksMap = {};

        // facebook (https://www.facebook.com/sharer.php?u=https%3A%2F%2Fwww.yumpu.com%2Fit%2Fdocument%2Fread%2F66312484%2Fplaceholder-pdf)
        if (!ignore.includes('facebook')) {
            try {
                const url = new URL('https://www.facebook.com/sharer.php');
                if (href) url.searchParams.set('u', href);
                if (title) url.searchParams.set('title', title);
                shareLinksMap.facebook = url.href;
            } catch (error) {
                console.error('Could not get facebook share link');
            }
        }

        // twitter (https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.yumpu.com%2Fit%2Fdocument%2Fread%2F66312484%2Fplaceholder-pdf&text=PLACEHOLDER%20PDF&via=yumpu_com)
        if (!ignore.includes('twitter')) {
            try {
                const url = new URL('https://twitter.com/intent/tweet');
                if (href) url.searchParams.set('url', href);
                if (title) url.searchParams.set('text', title);
                url.searchParams.set('via', 'conad_it');
                shareLinksMap.twitter = url.href;
            } catch (error) {
                console.error('Could not get twitter share link');
            }
        }

        // linkedin (https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.yumpu.com%2Fit%2Fdocument%2Fread%2F66312484%2Fplaceholder-pdf&title=PLACEHOLDER%20PDF)
        if (!ignore.includes('linkedin')) {
            try {
                const url = new URL('https://www.linkedin.com/shareArticle');
                url.searchParams.set('mini', 'true');
                if (href) url.searchParams.set('url', href);
                if (title) url.searchParams.set('title', title);
                shareLinksMap.linkedin = url.href;
            } catch (error) {
                console.error('Could not get linkedin share link');
            }
        }

        // whatsapp (https://api.whatsapp.com/send?text=https%3A%2F%2Fwww.yumpu.com%2Fit%2Fdocument%2Fread%2F66312484%2Fplaceholder-pdf)
        if (!ignore.includes('whatsapp')) {
            try {
                const url = new URL('https://api.whatsapp.com/send');
                if (href) url.searchParams.set('text', href);
                shareLinksMap.whatsapp = url.href;
            } catch (error) {
                console.error('Could not get whatsapp share link');
            }
        }

        // pinterest (https://www.pinterest.com/pin/create/button/?url=https%3A%2F%2Fwww.yumpu.com%2Fit%2Fdocument%2Fread%2F66312484%2Fplaceholder-pdf&media=https%3A%2F%2Fimg.yumpu.com%2F66312484%2F1%2F491x634%2Fplaceholder-pdf.jpg%3Fquality%3D80&description=PLACEHOLDER%20PDF)
        if (!ignore.includes('pinterest')) {
            try {
                const url = new URL('https://www.pinterest.com/pin/create/button/');
                if (href) url.searchParams.set('url', href);
                if (title) url.searchParams.set('description', title);
                shareLinksMap.pinterest = url.href;
            } catch (error) {
                console.error('Could not get pinterest share link');
            }
        }

        // email (mailto:?subject=Magazine recommendation&body=https://www.yumpu.com/it/document/read/66312484/placeholder-pdf)
        if (!ignore.includes('email')) {
            try {
                const url = new URL('mailto:');
                if (title) url.searchParams.set('subject', title);
                if (href) url.searchParams.set('body', href);
                shareLinksMap.email = url.href;
            } catch (error) {
                console.error('Could not get email share link');
            }
        }

        return shareLinksMap;
    }
}



import './style.scss';
import ModalComponent from '../../../../../libs/components/modal-component';
import { emptyElement } from '../../../../../libs/utils';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getDictionary } from '../../../../../libs/dictionary-provider';

export default class PosList extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.storeDetailPrefix = this.root.dataset.storeDetailPrefix;
        if (!this.storeDetailPrefix) {
            console.warn('Missing store detail prefix.');
            return;
        }
        this.list = this._dEl('list');

        this._addStoreListeners();
    }

    _addStoreListeners() {
        this._addStoreListener('posList', async (path, { stores }) => {
            if (!stores || stores.length <= 0) {
                console.warn('Missing stores.');
                return;
            }
            const posItemCall = (await import('../rt252-pos-list/partials/pos-item.html')).default;
            this._loadStores(stores, posItemCall);
        });
    }

    _loadStores(stores, posItemCall) {
        emptyElement(this.list);
        for (const store of stores) {
            const posItemData = {
                name: store.insignia,
                address: store.description,
                link: {
                    href: `${this.storeDetailPrefix}/slug--${store.anacanId}`,
                },
                linkLabel: getDictionary().get('Go to store card'),
            };
            const posItemEl = runTemplate(posItemCall, posItemData);
            this.list.append(posItemEl);
        }
    }

    _onCancel() {
        this.flowManager.complete();
    }
}



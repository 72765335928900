
import './style.scss';
import Component from '../../../../../libs/components/component';

export default class InsiemeCardsBannerCta extends Component {
    constructor(name, root) {
        super(name, root);
    }
}



import './style.scss';
import Component from '../../../../../libs/components/component';

export default class MenuDesktop extends Component {
    constructor(name, root) {
      super(name, root);
      this._addStoreListeners();
    }

    _addStoreListeners(){
        this._addStoreListener('currentPage', (path, data) => {
            const items = this._dEl('menuItem', true);
            for (const item of items) {
                if (item.matches(`[data-item-path='${data.path}']`)) item.classList.add('selected');
            }
        });
    }
}




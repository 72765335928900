import './style.scss';
import Component from '../../../../../libs/components/component';
import { delay } from '../../../../../libs/utils';

export default class TextIconContainer extends Component {
    constructor(name, root) {
        super(name, root);

        this.grid = this._dEl('track');
        this.items = this._dEl('slide', true);
        this._initMuuri();

        this._addEventListeners();
    }

    async _initMuuri() {
        const Muuri = (await import('muuri')).default;
        if (this.muuri) this.muuri.destroy(true);
        this.muuri = new Muuri(this.grid, {
            items: this.items,
            layoutDuration: 0,
            layout: {
                fillGaps: true,
            },
        });
        this.muuri.refreshItems();
        this.muuri.layout(true);
        await delay(600);
        this.muuri.getElement().classList.remove(this._elMod('track', 'preShow'));
    }

    _addEventListeners() {}
}



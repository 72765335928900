/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let uniqueid = args[1]['uniqueId'] || '';
    let extraclasses = args[1]['extraClasses'] || '';
    let text = args[1]['text'] || '';
    let textisrich = args[1]['textIsRich'] || '';
    let nostyling = args[1]['noStyling'] || '';
    let addcontainer = args[1]['addContainer'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n    ");
    $t = $.dom.create("div",false,false);
    const var_attrValue1 = ("rt001-richtext ") + (extraclasses) + (" ") + (nostyling ? "" : "rt001-richtext--styling") + (" ") + (addcontainer ? "rt001-richtext--container" : "");
    if (!$.col.empty(var_attrValue1)) {
      $.dom.attr($t, 'class', var_attrValue1, 'attribute');
    }
    $.dom.attr($t, 'data-component', null, 'attribute');
    const var_attrValue_id0 = uniqueid;
    if (!$.col.empty(var_attrValue_id0)) {
      $.dom.attr($t, 'id', var_attrValue_id0, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n        ");
    const var_unwrapcondition2 = textisrich;
    if (!var_unwrapcondition2) {
      $t = $.dom.create("p",false,false);
      $n = $.dom.push($n,$t);
    }
    const var_3 = yield $.xss(text, "html");
    $.dom.append($n, var_3);
    if (!var_unwrapcondition2) {
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    $.dom.text($n,"\n");
    return $.dom.end();

  });
};

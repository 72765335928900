/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let title = args[1]['title'] || '';
    let subtitle = args[1]['subtitle'] || '';
    let description = args[1]['description'] || '';
    let icon = args[1]['icon'] || '';
    let linklabel = args[1]['linkLabel'] || '';
    let jsonmodal = args[1]['jsonModal'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n\n    ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "rt156-excel-to-map-search-stores-form__store", 'attribute');
    const var_attrValue0 = jsonmodal;
    if (!$.col.empty(var_attrValue0)) {
      $.dom.attr($t, 'data-modal-json', var_attrValue0, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("span",false,false);
    $.dom.attr($t, 'class', "rt156-excel-to-map-search-stores-form__icon", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    const imgflw = $.exec("format", "{0}-icon", {"format": [title], });
    if (imgflw) {
    }
    $.dom.text($n,"\n            ");
    $t = $.dom.create("img",false,true);
    const var_attrValue1 = icon;
    if (!$.col.empty(var_attrValue1)) {
      $.dom.attr($t, 'src', var_attrValue1, 'attribute');
    }
    const var_attrValue2 = imgflw;
    if (!$.col.empty(var_attrValue2)) {
      $.dom.attr($t, 'alt', var_attrValue2, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "rt156-excel-to-map-search-stores-form__text", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "rt156-excel-to-map-search-stores-form__textContainer", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    $t = $.dom.create("h2",false,false);
    $.dom.attr($t, 'class', "rt156-excel-to-map-search-stores-form__name", 'attribute');
    $n = $.dom.push($n,$t);
    const var_3 = yield $.xss(title, "html");
    $.dom.append($n, var_3);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    const typelabel = subtitle;
    if (typelabel) {
      $t = $.dom.create("p",false,false);
      $.dom.attr($t, 'class', "rt156-excel-to-map-search-stores-form__subtitle", 'attribute');
      $n = $.dom.push($n,$t);
      const var_4 = yield $.xss(typelabel, "html");
      $.dom.append($n, var_4);
      $n = $.dom.pop($n);
    }
    $.dom.text($n,"\n                ");
    $t = $.dom.create("p",false,false);
    $.dom.attr($t, 'class', "rt156-excel-to-map-search-stores-form__address", 'attribute');
    $n = $.dom.push($n,$t);
    const var_5 = yield $.xss(description, "html");
    $.dom.append($n, var_5);
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("button",false,false);
    $.dom.attr($t, 'class', "rt156-excel-to-map-search-stores-form__link", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    const var_6 = yield $.xss(linklabel, "html");
    $.dom.append($n, var_6);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n\n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    return $.dom.end();

  });
};

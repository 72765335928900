import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getStoreManager } from '../../../../../libs/store-manager';
import { getApiProvider } from '../../../../../libs/api-provider';
import { getGMapsHelper } from '../../../../../libs/gmaps-helper';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { htmlMapPin } from '../rt016-map-pin/scripts/html-map-pin';
import { ClusterPinRenderer } from '../rt016-map-pin/scripts/cluster-pin-renderer';
import { styledMapFeatures } from './scripts/styled-map';
import { getPoiType, storeData } from '../../../../../libs/pdv-utils';
import { closeLoader, delay, emptyElement, loadStep, openLoader } from '../../../../../libs/utils';
import { formToJSON, jsonToForm } from '../../../../../libs/form-to-json';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { getDictionary } from '../../../../../libs/dictionary-provider';
import { getUserService } from '../../../../../libs/user-service';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class StoreLocator extends Component {
    constructor(name, root) {
        super(name, root);

        // services
        this.register = getRegister();
        this.storeManager = getStoreManager();
        this.api = getApiProvider();
        this.gMapsHelper = getGMapsHelper();
        this.trackingManager = getTrackingManager();
        this.dictionary = getDictionary();
        this.userService = getUserService();

        // constants
        this.RES_ITEM = this._el('resItem');
        this.PAD_BOX_SHADOW = 7;
        this.FILTERS_PANE_OPEN = this._mod('filtersOpen');
        this.BG_FILTERS = this._elMod('bg', 'filters');
        this.BREAKPOINT_L = BREAKPOINTS.l;
        this.MAP_SELECTOR = this._el('map', true);
        this.SHOW_MAP_MOB = this._mod('showMapMob');
        this.FILTER_OPENING_SELECTOR = this._el('filterOpening', true);
        this.NO_RESULTS = this._mod('noRes');
        this.SEARCH_EMPTY = this._mod('searchEmpty');
        this.PRE_FILTERED = this._mod('preFiltered');
        this.HIGH_BG = this._elMod('bg', 'high');

        this.bg = this._dEl('bg');
        this.searchAlert = this._dEl('searchAlert');
        this.address = this._dEl('address');
        this.filtersContainer = this._dEl('filters');
        this.numRes = this._dEl('resHeadingFound')?.querySelector('span:first-child');
        this.results = this._dEl('results');
        this.resList = this._dEl('resList');
        this.openFiltersPane = this._dEl('openFiltersPane');
        this.closeFiltersPane = this._dEl('closeFiltersPane');
        this.filtersCount = this._dEl('filtersCount');
        this.filtersOpening = this._dEl('filtersOpening');
        this.filtersPane = this._dEl('filtersPane');
        this.fAccInsignia = this._dEl('filtersAccordionInsignia');
        this.fAccDepartment = this._dEl('filtersAccordionDepartment');
        this.fAccService = this._dEl('filtersAccordionService');
        this.fInsSpecTitle = this._dEl('insigniaSpecializedTitle');
        this.fade = this._dEl('paneFade', true);
        this.mapEl = this._dEl('map');
        this.mapMob = this._dEl('mapMob');
        this.mapDsk = this._dEl('mapDsk');
        this.showMap = this._dEl('showMap');
        this.showList = this._dEl('showList');
        this.form = this._dEl('form');
        this.reset = this._dEl('reset');
        this.apply = this._dEl('apply');
        this.stores = null;
        this.map = null;
        this.markers = [];
        this.mapPins = {};
        this.markerClusterer = null;
        this.selectedAddress = null;
        this.yourStoreLink = this.root.dataset.yourStoreLink;
        if (this.root.hasAttribute('data-specialized-store-filters'))
            this.specializedStoreFilters = JSON.parse(this.root.dataset.specializedStoreFilters);
        if (this.root.hasAttribute('data-services-url-map')) this.servicesUrlMap = this.root.dataset.servicesUrlMap;
        this._doLogic();
    }

    async _doLogic() {
        /* init */
        this._showSearchEmpty();
        this._disableFilters();
        this._disableReset();
        this._disableApply();

        this.ff = this._getFixedFilter();
        if (this.ff) {
            const ff = this.specializedStoreFilters.filter((sf) => sf.theme == this.ff)[0];
            if (ff && ff.value) {
                const obj = this.register.getClass(this.searchAlert);
                obj.setTrailingText(ff.label);
                obj.setTheme(ff.theme);

                if (!this.root.classList.contains(this.PRE_FILTERED)) this.root.classList.add(this.PRE_FILTERED);

                // force insigna checked
                const realValue = ff.value
                    .map((item) => item.value)
                    .reduce((acc, value) => {
                        return acc + value;
                    }, '');
                const ffInput = this.root.querySelector(`.rt023-insignia-card__input[value="${realValue}"]`);
                if (ffInput) ffInput.checked = true;
            }
            this._emit('prefiltersChanged', false);
        }

        await this._updateFilters();

        this.psFilters?.destroy();
        this.psFilters = null;
        this.psResults?.destroy();
        this.psResults = null;
        this.psFiltersOpening?.destroy();
        this.psFiltersOpening = null;

        this.initMap();
        await this._initScrollbarFilters();
        await this._initScrollbarFiltersOpening();

        this._addEventListeners();
        this._addStoreListeners();

        this.searchString = this._getSearch();
        if (this.searchString) {
            const s = decodeURIComponent(this.searchString);
            const obj = this.register.getClass(this.address);
            obj.setInputValue(s);
            obj._manualSearch();
        }
    }

    _addEventListeners() {
        this._addListener(
            'rcInputChanged',
            async (event) => {
                if (!event.data.value || !event.data.value.geometry || !event.data.value.types) {
                    this.selectedAddress = null;
                    this._closeFiltersPane();
                    this._disableFilters();
                    return;
                }
                this.selectedAddress = event.data.value;
                if (this.selectedAddress != null) {
                    this.filtersContainer.classList.remove(this._elMod('filters', 'hidden'));
                    this.showMap.classList.remove(this._elMod('showMap', 'hidden'));
                    this.bg.classList.remove(this.HIGH_BG);
                    this.input = this._dEl('address')?.querySelector('input');

                    if (this.input.value.length > 23) {
                        let shortAddress = this.input.value.substring(0, 24) + '...';
                        this.input.value = shortAddress;
                    }
                }

                if (this.storeManager.get('prefiltersChanged')) {
                    await this._resetFilters();
                    this._initFilters();
                }

                await this._loadPoi(this.selectedAddress);
                this._enableFilters();
            },
            this.address
        );

        this._addListener(
            'rcInputChanged',
            async () => {
                if (!this.storeManager.get('prefiltersChanged')) {
                    this._emit('prefiltersChanged', true);
                    this.searchAlert.dispatchEvent(new CustomEvent('closeSearchAlert', { bubbles: false }));
                }
                // refilter at every input change and mask filters accordingly to the results
                await this._refreshSearchWithFilters();

                // update ctas
                const numChecked = Array.from(this.form.querySelectorAll('input')).filter((i) => i.checked).length;
                this.register.getClass(this.reset).setStatus(numChecked <= 0 ? 'disabled' : 'enabled');
                this.register.getClass(this.apply).setStatus('enabled');
                await this._refreshModals();
                this.register.getClass(this.filtersModal.reset).setStatus(numChecked <= 0 ? 'disabled' : 'enabled');
                this.register.getClass(this.filtersModal.apply).setStatus('enabled');
            },
            this.form
        );

        this._addListener(
            'click',
            () => {
                if (window.innerWidth < this.BREAKPOINT_L) {
                    /* mobile */
                    this._openFiltersModal();
                } else {
                    /* desktop */
                    this._openFiltersPane();
                }
                this.trackingManager.track(this.root, {
                    event: 'ApriFiltro',
                    CustomLink: 'Apri Filtro',
                    funnel: {
                        stepFunnel: 'Filtro',
                    },
                    negozioInfo: {
                        indirizzoRicerca: 'vars.currentSearchAddress',
                    },
                });
            },
            this.openFiltersPane
        );

        this._addListener(
            'click',
            () => {
                this._closeFiltersPane();
            },
            this.closeFiltersPane
        );

        this._addListener(
            'rt17HeightChanged',
            async (event) => {
                this.trackingManager.track(this.root, {
                    event: 'EsplodiFiltro',
                    CustomLink: 'Esplodi Filtro',
                    funnel: {
                        stepFunnel: 'Filtro',
                    },
                    filtro: {
                        nomeFiltro: 'Insegne',
                    },
                    negozioInfo: {
                        indirizzoRicerca: 'vars.currentSearchAddress',
                    },
                });
                await delay(500);
                await this._initScrollbarFilters();
                await this._refreshModals();
                this.filtersModal.manageAccordion('filtersAccordionInsignia', event.data.isOpen);
            },
            this.fAccInsignia
        );
        this._addListener(
            'rt17HeightChanged',
            async (event) => {
                this.trackingManager.track(this.root, {
                    event: 'EsplodiFiltro',
                    CustomLink: 'Esplodi Filtro',
                    funnel: {
                        stepFunnel: 'Filtro',
                    },
                    filtro: {
                        nomeFiltro: 'Reparti',
                    },
                    negozioInfo: {
                        indirizzoRicerca: 'vars.currentSearchAddress',
                    },
                });
                await delay(500);
                await this._initScrollbarFilters();
                await this._refreshModals();
                this.filtersModal.manageAccordion('filtersAccordionDepartment', event.data.isOpen);
            },
            this.fAccDepartment
        );
        this._addListener(
            'rt17HeightChanged',
            async (event) => {
                this.trackingManager.track(this.root, {
                    event: 'EsplodiFiltro',
                    CustomLink: 'Esplodi Filtro',
                    funnel: {
                        stepFunnel: 'Filtro',
                    },
                    filtro: {
                        nomeFiltro: 'Altri Servizi',
                    },
                    negozioInfo: {
                        indirizzoRicerca: 'vars.currentSearchAddress',
                    },
                });
                await delay(500);
                await this._initScrollbarFilters();
                await this._refreshModals();
                this.filtersModal.manageAccordion('filtersAccordionService', event.data.isOpen);
            },
            this.fAccService
        );

        this._addListener(
            'click',
            () => {
                this._showMapMob();
            },
            this.showMap
        );

        this._addListener(
            'click',
            () => {
                this._showMapMob(false);
                this._updateSelectedRes();
            },
            this.showList
        );

        this._addListener(
            'click',
            async () => {
                await this._resetFilters();
                this._closeFiltersPane();
                this._refreshSearchWithFilters();
            },
            this.reset
        );

        this._addListener(
            'click',
            () => {
                this._closeFiltersPane();
                this._trackApplyFilter();
            },
            this.apply
        );

        this._addListener(
            'rcInputChanged',
            async () => {
                await this._refreshSearchWithFilters();
                //this._trackApplyFilter();
            },
            this.filtersOpening
        );

        this._addListener(
            'rt10CanceledField',
            () => {
                this._emptyMarkers();
                this._emptyResults();
                this._showSearchEmpty();
            },
            this.address
        );

        this._addListener(
            'resize',
            () => {
                this._refreshMapPosition();
                if (this.currentBounds) this.map.fitBounds(this.currentBounds);
            },
            window
        );

        this._addListener('clickedStore', (event) => {
            const data = event.data;
            if (!data.pinId) return;

            const pinObj = this.mapPins[data.pinId];
            if (!pinObj || !pinObj.getPosition() || !this.map) return;

            if (pinObj.isSelected()) {
                this._resetPin();
                return;
            }

            this._resetPin(pinObj);
            this.selectedResItem = this.resList.querySelector(`.${this.RES_ITEM}[data-store-id='${data.pinId}']`);
            if (!this.selectedResItem) return;
            this._updateSelectedRes();
        });

        this._addListener(
            'clickedAggregator',
            (event) => {
                const data = event.data;
                if (!data.position) return;

                this._resetPin();

                this.map.panTo(data.position);
                this.map.setZoom(this.map.getZoom() < 15 ? this.map.getZoom() + 2 : this.map.getZoom() + 1);
            },
            this.mapEl
        );

        this._addListener('clickedStoreCard', (event) => {
            const data = event.data;
            if (!data.storeId) return;

            /* update selected marker */
            const pinObj = this.mapPins[data.storeId];
            if (!pinObj || !pinObj.getPosition() || !this.map) return;

            this._resetPin(pinObj);

            this.map.panTo(pinObj.getPosition());
            this.map.setZoom(17);
        });

        this._addListener(
            'closedSearchAlert',
            (e) => {
                this._doClosedSearchAlertLogic();
                e.stopPropagation();
            },
            this.searchAlert
        );
    }

    _addStoreListeners() {
        this._addStoreListener('maskStoreLocatorFilters', (path, data) => {
            this._maskFilters(data.stores);
        });
    }

    async initMap() {
        if (!this.map) {
            const maps = await this.gMapsHelper.getGMaps();
            this.map = new maps.Map(this.mapEl, {
                center: { lat: 43, lng: 12 },
                zoom: 10,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
            });

            const styledMapType = new maps.StyledMapType(styledMapFeatures);
            this.map.mapTypes.set('styled_map', styledMapType);
            this.map.setMapTypeId('styled_map');
        }
        this._refreshMapPosition();
    }

    _refreshMapPosition() {
        if (!this.mapEl) return;
        if (window.innerWidth >= this.BREAKPOINT_L) {
            this.mapDsk?.append(this.mapEl);
            this.mapEl = this.mapDsk?.querySelector(this.MAP_SELECTOR);
        } else {
            this.mapMob?.append(this.mapEl);
            this.mapEl = this.mapMob?.querySelector(this.MAP_SELECTOR);
        }
    }

    _updateSelectedRes() {
        if (!this.selectedResItem || !this.selectedResItem.offsetTop) return;
        this.resList.scrollTop = this.selectedResItem.offsetTop - this.PAD_BOX_SHADOW;
        const resObj = this.register.getClass(this.selectedResItem);
        resObj.unselectCards();
        resObj.setSelected();
    }

    _showMapMob(show = true) {
        if (show) {
            this.root.classList.add(this.SHOW_MAP_MOB);
            const pinSelected = Object.values(this.mapPins).filter((pin) => pin.isSelected()).length > 0;
            if (this.currentBounds && !pinSelected) this.map.fitBounds(this.currentBounds);
        } else {
            this.root.classList.remove(this.SHOW_MAP_MOB);
        }
    }

    async _initScrollbarResults() {
        this.psResults?.destroy();
        this.psResults = null;
        const PerfectScrollbar = await getExtApiHelper().getPerfectScrollbar();
        this.psResults = new PerfectScrollbar(this.resList, {
            swipeEasing: true,
            suppressScrollX: true,
            wheelPropagation: false,
            minScrollbarLength: 32,
        });
    }

    async _initScrollbarFilters() {
        this.psFilters?.destroy();
        this.psFilters = null;
        const PerfectScrollbar = await getExtApiHelper().getPerfectScrollbar();
        this.psFilters = new PerfectScrollbar(this.form, {
            swipeEasing: true,
            suppressScrollX: true,
            wheelPropagation: false,
            minScrollbarLength: 32,
        });
    }

    async _initScrollbarFiltersOpening() {
        this.psFiltersOpening?.destroy();
        this.psFiltersOpening = null;
        const PerfectScrollbar = await getExtApiHelper().getPerfectScrollbar();
        this.psFiltersOpening = new PerfectScrollbar(this.filtersOpening, {
            swipeEasing: true,
            suppressScrollY: true,
            wheelPropagation: false,
            useBothWheelAxes: true,
            minScrollbarLength: 32,
        });
    }

    _openFiltersPane() {
        this.bg.classList.add(this.BG_FILTERS);
        this.root.classList.add(this.FILTERS_PANE_OPEN);
        setTimeout(() => {
            this.openFiltersPane.blur();
            this.closeFiltersPane.focus();
        }, 300);
    }

    hasFiltersOpen() {
        return this.root.classList.contains(this.FILTERS_PANE_OPEN);
    }

    manageAccordion(name, isOpen) {
        const el = this._dEl(name);
        if (!el) return;
        const elObj = this.register.getClass(el);
        isOpen ? elObj.openContent() : elObj.closeContent();
    }

    async _refreshModals() {
        if (!this.filtersModal) {
            let modalEl = document.querySelector('[data-name=store-locator-filters]');
            if (!modalEl) modalEl = await loadStep('store-locator-filters');
            this.modalObj = this.register.getClass(modalEl);
            this.filtersModal = this.register.getClass(modalEl.querySelector(".rt253-store-locator-filters"));
        }
    }

    async _openFiltersModal() {
        await this._refreshModals();
        this.modalObj.open();
    }

    async _closeFiltersModal() {
        await this._refreshModals();
        this.modalObj.close();
    }

    async _closeFiltersPane() {
        this.root.classList.remove(this.FILTERS_PANE_OPEN);
        this.openFiltersPane.focus();
        await delay(175);
        this.bg.classList.remove(this.BG_FILTERS);
    }

    _enableReset() {
        this.register.getClass(this.reset).setStatus('enabled');
    }

    _disableReset() {
        this.register.getClass(this.reset).setStatus('disabled');
        this.reset.blur();
    }

    _enableApply() {
        this.register.getClass(this.reset).setStatus('enabled');
    }

    _disableApply() {
        this.register.getClass(this.apply).setStatus('disabled');
        this.apply.blur();
    }

    _enableOpeningFilters() {
        if (!this.filtersOpening) return;
        [...this.filtersOpening.querySelectorAll(this.FILTER_OPENING_SELECTOR)]?.forEach((el) => {
            this.register.getClass(el).setDisabled(false);
        });
    }

    _disableOpeningFilters() {
        if (!this.filtersOpening) return;
        [...this.filtersOpening.querySelectorAll(this.FILTER_OPENING_SELECTOR)]?.forEach((el) => {
            this.register.getClass(el).setDisabled();
        });
    }

    _enableFilters() {
        this._enableApply();
        this._enableOpeningFilters();
    }

    _disableFilters() {
        this._disableReset();
        this._disableApply();
        this._disableOpeningFilters();
    }

    _updateFiltersCount(filters) {
        /* update single filters count on accordions */
        this.register
            .getClass(this.fAccInsignia)
            .setBadgeText(`${filters.insignia.count + filters.insigniaSpecialized.count || ''}`);
        this.register.getClass(this.fAccDepartment).setBadgeText(`${filters.department.count || ''}`);
        this.register.getClass(this.fAccService).setBadgeText(`${filters.service.count || ''}`);
        const sum =
            filters.insignia.count +
            filters.insigniaSpecialized.count +
            filters.department.count +
            filters.service.count +
            filters.opening.count;
        /* update global filters count */
        this.filtersCount.innerText = sum > 0 ? ` (${sum})` : '';
    }

    async _resetFilters() {
        /* reset filters */
        jsonToForm(this.form, {});
        jsonToForm(this.filtersOpening, {});
        this._resetFiltersCount();
        await this._refreshModals();
        this.filtersModal.setForm({});
        this.filtersModal._resetFiltersCount();

        /* reset applied filters */
        this._emit('storeLocatorFilters', {});
        this._disableReset();
        this._disableApply();
        this.filtersModal._disableReset();
        this.filtersModal._disableApply();
    }

    _resetFiltersCount() {
        /* reset single filters count on accordions */
        this.register.getClass(this.fAccInsignia).setBadgeText('');
        this.register.getClass(this.fAccDepartment).setBadgeText('');
        this.register.getClass(this.fAccService).setBadgeText('');
        this.filtersCount.innerText = '';
    }

    _resetPin(selectedPinObj = null) {
        for (const prop in this.mapPins) {
            if (Object.hasOwn(this.mapPins, prop)) {
                if (selectedPinObj == this.mapPins[prop]) this.mapPins[prop].setSelected(true);
                else this.mapPins[prop].setSelected(false);
            }
        }
    }

    _getEmptyFiltersCopy() {
        return {
            insignia: {
                values: [],
                count: 0,
            },
            insigniaSpecialized: {
                values: [],
                count: 0,
            },
            department: {
                values: [],
                count: 0,
            },
            service: {
                values: [],
                count: 0,
            },
            opening: {
                values: [],
                count: 0,
            },
        };
    }

    _initFilters() {
        this._emit('storeLocatorFilters', this._getEmptyFiltersCopy());
    }

    async _updateFilters() {
        /* get raw filters data and group it */
        let filters = this._getEmptyFiltersCopy();

        const filtersData = formToJSON(this.form);
        const openingData = formToJSON(this.filtersOpening);
        [filtersData, openingData].forEach((data) => {
            Object.keys(data).forEach((key) => {
                const prefix = key.split('-')[0];
                if (!filters[prefix]) return;
                filters[prefix].values.push(...data[key].split(','));
                filters[prefix].count++;
            });
        });

        this._updateFiltersCount(filters);
        // update modal filters
        await this._refreshModals();
        this.filtersModal.setForm(formToJSON(this.form));
        this.filtersModal._updateFiltersCount(filters);

        this._emit('storeLocatorFilters', filters);
    }

    _appliedFilters() {
        let appliedFilters = {};
        let filters = this.storeManager.get('storeLocatorFilters');
        for (const prop in filters) {
            if (!filters[prop]) continue;
            appliedFilters[prop] = filters[prop]?.values;
        }
        return appliedFilters;
    }

    async _doClosedSearchAlertLogic() {
        this.filtersContainer.classList.remove(this._elMod('filters', 'hidden'));
        this.showMap.classList.remove(this._elMod('showMap', 'hidden'));
        this.bg.classList.remove(this.HIGH_BG);
        this._enableFilters();
        this._enableReset();
        await this._refreshModals();
        this.filtersModal._enableReset();
    }

    async _refreshSearchWithFilters() {
        await this._updateFilters();

        // if a selected address is missing, manually search with what's in the input
        if (!this.selectedAddress) {
            this.register.getClass(this.address)._manualSearch();
            return;
        }
        this._loadPoi(this.selectedAddress);
    }

    async _loadPoi(address) {
        if (!address || !address.geometry || !address.types) return;

        this.trackingManager.appendVariable({
            currentSearchAddress: address.formatted_address,
        });

        openLoader('main');

        const data = {
            latitudine: `${address.geometry.location.lat()}`,
            longitudine: `${address.geometry.location.lng()}`,
            raggioRicerca: this._getSearchRadius(address),
        };

        /* merge applied filters */
        const appliedFilters = this._appliedFilters();
        if (appliedFilters.insignia) data.insegneId = appliedFilters.insignia;
        if (appliedFilters.department) data.repartiId = appliedFilters.department;
        if (appliedFilters.insigniaSpecialized || appliedFilters.service)
            data.serviziId = [...appliedFilters.insigniaSpecialized, ...appliedFilters.service];
        if (appliedFilters.opening) data.apertura = appliedFilters.opening;

        try {
            this.stores = await this.api.retrievePointOfService(data);
            await this._addResults(this.stores);
            this._emit('maskStoreLocatorFilters', { stores: this.stores });
        } catch (error) {
            console.error(error);
            this._showNoResults();
        }

        closeLoader('main');
    }

    _getSearchRadius(_address) {
        // for (const type of address.types) {
        //     if (type == 'postal_code' || type == 'street_address' || type == 'route') return '5';
        // }
        return '15';
    }

    _showNoResults() {
        this.root.classList.remove(this.SEARCH_EMPTY);
        this.root.classList.add(this.NO_RESULTS);
        this._removeFade();
    }

    _showSearchEmpty() {
        this.root.classList.remove(this.NO_RESULTS);
        this.root.classList.add(this.SEARCH_EMPTY);
        this._removeFade();
    }

    _showResults() {
        this.root.classList.remove(this.SEARCH_EMPTY);
        this.root.classList.remove(this.NO_RESULTS);
    }

    async _addResults(results) {
        if (!results || results.length <= 0) {
            this._emptyMarkers();
            this._emptyResults();
            this._closeFiltersPane();
            this._showNoResults();
            return;
        }
        const maps = await this.gMapsHelper.getGMaps();
        const mapPinCall = (await import('../rt016-map-pin/rt016-map-pin.html')).default;
        const storeCardCall = (await import('../rt021-store-card/rt021-store-card.html')).default;

        if (this.markers.length > 0) {
            this._emptyMarkers();
            this._emptyResults();
        }

        this._showResults();
        if (results.length >= 2) {
            this._addFade();
        } else {
            this._removeFade();
        }
        this._updateNumRes(results.length);

        this.trackingManager.appendVariable({
            currentNumRes: results.length,
        });

        //track search number of results
        this.trackingManager.track(this.root, {
            event: 'pageView',
            funnel: {
                stepFunnel: 'Risultati ricerca negozi',
            },
            negozioInfo: {
                numeroRisultati: 'vars.currentNumRes',
                indirizzoRicerca: 'vars.currentSearchAddress',
            },
        });

        this.currentBounds = new maps.LatLngBounds();
        const posMarkersTmp = {};
        let index = 0;
        for (const poi of results) {
            this._addResultToMap(maps, poi, posMarkersTmp, mapPinCall);
            this._addResultToList(poi, index, storeCardCall);
            index++;
        }

        this.map.fitBounds(this.currentBounds);

        this.markerClusterer?.reset();
        const MarkerClusterer = await getGMapsHelper().getMarkerClusterer();
        this.markerClusterer = new MarkerClusterer({
            map: this.map,
            markers: this.markers,
            renderer: new ClusterPinRenderer(this.map, this.register, mapPinCall),
        });
        await this._initScrollbarResults();
    }

    getStoreLocation(store) {
        return `${store.latitudine}-${store.longitudine}`;
    }

    _addResultToMap(maps, poi, posMarkersTmp, mapPinCall) {
        const latLngKey = `${poi.latitudine}-${poi.longitudine}`;
        let tmpMarker = posMarkersTmp[latLngKey];

        let pinObj = null;
        let marker = null;
        if (!tmpMarker) {
            //marker not already present in map
            /* get latlng */
            const position = new maps.LatLng(poi.latitudine, poi.longitudine);
            const pinData = {
                uniqueId: `pin-${poi.anacanId}`,
                pinId: poi.anacanId,
                type: getPoiType(poi),
                specialized: poi.specialized ? true : false,
            };
            const mapPin = runTemplate(mapPinCall, pinData);
            /* create map marker */
            marker = htmlMapPin({
                position: position,
                elem: mapPin,
                map: this.map,
            });
            /* set map params on pin object */
            pinObj = this.register.getClass(mapPin);
            pinObj.setMapParams(this.map, position, marker, poi);

            // add to tmp markers
            posMarkersTmp[latLngKey] = {
                marker,
                pinObj,
            };

            //add marker to map
            this.markers.push(marker);
            this.currentBounds.extend(position);
        } else {
            //reuse marker, and set it as mixed
            marker = tmpMarker.marker;
            pinObj = tmpMarker.pinObj;

            //set pin as mixed
            pinObj.addMixed(poi);
        }

        /* add marker to the internal pdv map */
        this.mapPins[poi.anacanId] = pinObj;
    }

    _addResultToList(poi, index, storeCardCall) {
        const storeCardData = storeData(poi, `${poi.anacanId}-store-card`);
        storeCardData.extraClasses = this.RES_ITEM;
        storeCardData.extraClasses += index == 0 ? ' rt021-store-card--firstOne' : '';
        storeCardData.storeType = getPoiType(poi);
        storeCardData.numFlyers = poi.volantiniCount;
        storeCardData.servicesUrlMap = this.servicesUrlMap;

        // your store link
        storeCardData.yourStoreLink = JSON.parse(this.yourStoreLink) || {};
        if (
            !!storeCardData.yourStoreLink ||
            storeCardData.yourStoreLink == null ||
            !!storeCardData.yourStoreLink.label ||
            storeCardData.yourStoreLink.label == null ||
            storeCardData.yourStoreLink.label == ''
        ) {
            storeCardData.yourStoreLink.label = this.dictionary.get('Your shops');
        }

        // specialized
        if (poi.specializedServices && poi.specializedServices.length > 0) {
            storeCardData.serviceCodes = poi.specializedServices;
        }
        storeCardData.specialized = poi.specialized || false;

        const storeCard = runTemplate(storeCardCall, storeCardData);
        this.resList.appendChild(storeCard);
    }

    async _maskFilters(stores) {
        const filters = this.storeManager.get('storeLocatorFilters');

        // get insignia specialized ids
        const insigniaSpecializedServiceIds = new Set(
            this._dEl(`f-insigniaSpecialized`, true)
                .map((item) => item.querySelector('input').value.split(','))
                .reduce((_, i) => [..._, ...i])
                .map((s) => parseInt(s))
        );

        // construct available filters from stores
        const availableFilters = {
            insignia: new Set(),
            insigniaSpecialized: new Set(),
            department: new Set(),
            service: new Set(),
            opening: new Set(),
        };
        stores.forEach((store) => {
            if (window.insigniaToId[store.descrizioneInsegna])
                availableFilters.insignia.add(window.insigniaToId[store.descrizioneInsegna]);
            for (const dId of store.repartiId) {
                availableFilters.department.add(dId);
            }
            for (const sId of store.serviziId) {
                if (insigniaSpecializedServiceIds.has(sId)) {
                    availableFilters.insigniaSpecialized.add(sId);
                    continue;
                }
                availableFilters.service.add(sId);
            }
        });

        // hide filters if not available
        const hideIfNotAvailable = (item, availableSet) => {
            const values = item.querySelector('input').value.split(',');
            let hide = true;
            for (const v of values) {
                if (availableSet.has(parseInt(v))) {
                    hide = false;
                    break;
                }
            }
            hide ? item.classList.add('f-hidden') : item.classList.remove('f-hidden');
        };
        Object.keys(filters)
            .filter((key) => key !== 'opening')
            .forEach((type) => {
                this._dEl(`f-${type}`, true).forEach((item) => {
                    hideIfNotAvailable(item, availableFilters[type]);
                });
                const allHiddenOfType = this._dEl(`f-${type}`, true)
                    .map((item) => item.classList.contains('f-hidden'))
                    .reduce((_, hidden) => _ && hidden);
                // check if all insignia filters have been hidden
                if (type === 'insignia') {
                    allHiddenOfType
                        ? this.fInsSpecTitle.classList.add(this._elMod('insigniaSpecializedTitle', 'noMarginTop'))
                        : this.fInsSpecTitle.classList.remove(this._elMod('insigniaSpecializedTitle', 'noMarginTop'));
                }
                // check if all insigniaSpecialized filters have been hidden
                if (type === 'insigniaSpecialized') {
                    this.fInsSpecTitle.style.display = allHiddenOfType ? 'none' : 'block';
                }
                // hide accordion if all inner filters have been hidden
                switch (type) {
                    case 'insignia': {
                        const allHiddenOfInsignia =
                            this._dEl(`f-insignia`, true)
                                .map((item) => item.classList.contains('f-hidden'))
                                .reduce((_, hidden) => _ && hidden) &&
                            this._dEl(`f-insigniaSpecialized`, true)
                                .map((item) => item.classList.contains('f-hidden'))
                                .reduce((_, hidden) => _ && hidden);
                        allHiddenOfInsignia
                            ? this.fAccInsignia.classList.add('acc-hidden')
                            : this.fAccInsignia.classList.remove('acc-hidden');
                        break;
                    }
                    case 'department': {
                        allHiddenOfType
                            ? this.fAccDepartment.classList.add('acc-hidden')
                            : this.fAccDepartment.classList.remove('acc-hidden');
                        break;
                    }
                    case 'service': {
                        allHiddenOfType
                            ? this.fAccService.classList.add('acc-hidden')
                            : this.fAccService.classList.remove('acc-hidden');
                        break;
                    }
                    case 'insigniaSpecialized':
                    default:
                        break;
                }
            });

        // update accordions heights
        (
            await Promise.allSettled(
                [this.fAccInsignia, this.fAccDepartment, this.fAccService].map((el) => this.register.getClass(el))
            )
        )
            .map((promise) => promise.value)
            .forEach((accObj) => accObj.updateContentHeight());
    }

    _trackApplyFilter() {
        let filters = this.storeManager.get('storeLocatorFilters');
        let nomeFiltro = new Array();
        if (filters.insignia.count + filters.insigniaSpecialized.count > 0) nomeFiltro.push('Insegne');
        if (filters.department.count > 0) nomeFiltro.push('Reparti');
        if (filters.service.count > 0) nomeFiltro.push('Altri Servizi');

        // skip tracking of applied filters if no filters applied
        if (nomeFiltro.length <= 0) return;

        this.trackingManager.track(this.root, {
            event: 'ApplicaFiltro',
            CustomLink: 'Applica Filtro',
            funnel: {
                stepFunnel: 'Filtro',
            },
            negozioInfo: {
                indirizzoRicerca: 'vars.currentSearchAddress',
            },
            filtro: {
                NomeFiltro: nomeFiltro.join('|'),
                Insegne:
                    filters.insignia.count + filters.insigniaSpecialized.count > 0
                        ? [
                              ...new Set(
                                  this.storeManager
                                      .get('storeLocatorFilters')
                                      .insignia.values.map(
                                          (value) =>
                                              this.root.querySelector(`.rt023-insignia-card__input[value="${value}"]`)
                                                  ?.parentElement.dataset.label
                                      )
                                      .concat(
                                          this.storeManager
                                              .get('storeLocatorFilters')
                                              .insigniaSpecialized.values.map(
                                                  (value) =>
                                                      this.root.querySelector(
                                                          `.rt023-insignia-card__input[value*="${value}"][name^="insigniaSpecialized"]`
                                                      )?.parentElement.dataset.label
                                              )
                                      )
                              ),
                          ].join('|')
                        : '',
                Reparti:
                    filters.department.count > 0
                        ? [
                              ...new Set(
                                  filters.department.values.map(
                                      (value) =>
                                          this.root.querySelector(`.rt003-checkbox__input[value*="${value}"]`)
                                              ?.parentElement.dataset.label
                                  )
                              ),
                          ].join('|')
                        : '',
                AltriServizi:
                    filters.service.count > 0
                        ? [
                              ...new Set(
                                  filters.service.values.map(
                                      (value) =>
                                          this.root.querySelector(
                                              `.rt003-checkbox__input[value*="${value}"][name^="service"]`
                                          )?.parentElement.dataset.label
                                  )
                              ),
                          ].join('|')
                        : '',
            },
        });
    }

    setForm(json) {
        // translate prop names from _mob to _dsk suffix
        for (const prop in json) {
            if (!prop.endsWith('_mob')) continue;
            json[`${prop.substring(0, prop.indexOf('_mob'))}_dsk`] = json[prop];
        }
        jsonToForm(this.form, json);
    }

    _updateNumRes(numRes) {
        this.numRes.innerText = numRes;
    }

    _emptyMarkers() {
        //deselect all pins
        this._resetPin();
        //remove markers
        for (const marker of this.markers) {
            marker.setMap(null);
        }
        this.markers = [];
        this.mapPins = {};
        this.selectedMarker = null;
        this.markerClusterer?.clearMarkers();
    }

    _emptyResults() {
        emptyElement(this.resList);
    }

    _getFixedFilter() {
        const urlParams = new URLSearchParams(window.location.search);
        const ff = urlParams.get('ff');
        if (ff) return ff;
        return null;
    }

    _hideFilters() {
        if (!this.root.classList.contains(this.PRE_FILTERED)) this.root.classList.add(this.PRE_FILTERED);
        this.bg.classList.add(this.HIGH_BG);
    }

    _getSearch() {
        const urlParams = new URLSearchParams(window.location.search);
        const s = urlParams.get('s');
        if (s) return s;
        return null;
    }

    _addFade() {
        this.fade[0].classList.remove(this._elMod('paneFade', 'hidden'));
        this.fade[1].classList.remove(this._elMod('paneFade', 'hidden'));
    }

    _removeFade() {
        this.fade[0].classList.add(this._elMod('paneFade', 'hidden'));
        this.fade[1].classList.add(this._elMod('paneFade', 'hidden'));
    }
}



import ModalComponent from '../../../../../libs/components/modal-component';
import PerfectScrollbar from 'perfect-scrollbar';
import { getFlowManager } from '../../../../../libs/flow-manager';
import './style.scss';

export default class ErrorModal extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.scrollbar;
        this.flowManager = getFlowManager();

        this.wrapper = this._dEl('wrapper');

        this.genericErrorTitle = this._dEl('genericErrorTitle');
        this.genericErrorText = this._dEl('genericErrorText');
        this.genericErrorCta = this._dEl('genericErrorCta');
        this.registryErrorTitle = this._dEl('registryErrorTitle');
        this.registryErrorText = this._dEl('registryErrorText');
        this.registryErrorCta = this._dEl('registryErrorCta');
        this.noCardErrorTitle = this._dEl('noCardErrorTitle');
        this.noCardErrorText = this._dEl('noCardErrorText');
        this.noCardErrorCta = this._dEl('noCardErrorCta');
        this.insurancePolicyAlreadyActiveErrorTitle = this._dEl('insurancePolicyAlreadyActiveErrorTitle');
        this.insurancePolicyAlreadyActiveErrorText = this._dEl('insurancePolicyAlreadyActiveErrorText');
        this.insurancePolicyAlreadyActiveErrorCta = this._dEl('insurancePolicyAlreadyActiveErrorCta');
        this.noValidReceiptErrorTitle = this._dEl('noValidReceiptErrorTitle');
        this.noValidReceiptErrorText = this._dEl('noValidReceiptErrorText');
        this.noValidReceiptErrorCta = this._dEl('noValidReceiptErrorCta');

        this.termsAndConditions = this._dEl('termsAndConditions');

    }

    _onOpen() {
        this._initScrollbar();
        let errorType = 'genericError';
        try {
            const data = this.flowManager.getDataFromFlow();
            errorType = data?.errorType || 'genericError';
        } catch(e) {
            console.warn(e);
        }

        switch (errorType) {
            case 'registryError': {
                this.registryErrorTitle?.classList.remove('hidden');
                this.registryErrorText?.classList.remove('hidden');
                this.registryErrorCta?.classList.remove('!hidden');
                this.termsAndConditions?.classList.remove('hidden');
                break;
            }

            case 'noCardError': {
                this.noCardErrorTitle?.classList.remove('hidden');
                this.noCardErrorText?.classList.remove('hidden');
                this.noCardErrorCta?.classList.remove('!hidden');
                this.termsAndConditions?.classList.remove('hidden');
                break;
            }

            case 'insurancePolicyAlreadyActiveError': {
                this.insurancePolicyAlreadyActiveErrorTitle?.classList.remove('hidden');
                this.insurancePolicyAlreadyActiveErrorText?.classList.remove('hidden');
                this.insurancePolicyAlreadyActiveErrorCta?.classList.remove('!hidden');
                this.termsAndConditions?.classList.remove('hidden');
                break;
            }

            case 'noValidReceiptsError': {
                this.noValidReceiptErrorTitle?.classList.remove('hidden');
                this.noValidReceiptErrorText?.classList.remove('hidden');
                this.noValidReceiptErrorCta?.classList.remove('!hidden');
                this.termsAndConditions?.classList.remove('hidden');
                break;
            }

            case 'genericError':
            default: {
                this.genericErrorTitle?.classList.remove('hidden');
                this.genericErrorText?.classList.remove('hidden');
                this.genericErrorCta?.classList.remove('!hidden');
                if (errorType != 'genericError') {
                    console.log('Unknown error');
                }
            }
        }
    }

    _initScrollbar() {
        this.scrollbar = new PerfectScrollbar(this.wrapper, {
            swipeEasing: true,
            suppressScrollX: true,
        });
    }
}



import './style.scss';
import Component from '../../../../../libs/components/component';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getRegister } from '../../../../../libs/register';
import { getUserService } from '../../../../../libs/user-service';
import { delay } from '../../../../../libs/utils';
import { getTrackingManager } from '../../../../../libs/tracking-manager';

export default class Header extends Component {
    constructor(name, root) {
        super(name, root);

        this.trackingManager = getTrackingManager();

        this.lastScroll = window.scrollY || window.pageYOffset;
        this.anonymousLoginBtn = this._dEl('anonymousLoginBtn');
        this.anonymousRegisterBtn = this._dEl('anonymousRegisterBtn');
        if (this.anonymousLoginBtn) this._setCallbackCb(this.anonymousLoginBtn);
        if (this.anonymousRegisterBtn) this._setCallbackCb(this.anonymousRegisterBtn);
        this.menu = this._dEl('menu');
        this.submenu = this._dEl('submenu');
        this.menuItems = this._dEl('menuItem', true);
        this.submenuItems = this._dEl('submenuItem', true);
        this.currentSubmenuId = null;
        this.searchBtn = this._dEl('searchBtn');
        this.searchBar = this._dEl('searchBar');

        this.backdrop = this._dEl('backdrop');
        this.page = getRegister().getClass(document.querySelector('.rs1-page'));
        this._addEventListeners();
        this._addStoreListeners();
        setTimeout(() => {
            this._calcMenuPosition();
        }, 100);

        //check current user
        this._checkUser();

        // calculate the height of the mouseover submenu and hide them after calculating the max height
        this._doCalculationsOnMouseoverSubmenuAndHideThem();
    }

    _doCalculationsOnMouseoverSubmenuAndHideThem() {
        this.submenuItems?.forEach((submenuItem) => {
            let mouseoverSubmenuWrappers = submenuItem.querySelectorAll('.rt055-menu-dsk__mouseoverSubmenuWrapper');
            if (mouseoverSubmenuWrappers) {
                let maxHeight = this._calculateMaxHeight(mouseoverSubmenuWrappers);
                submenuItem.style.height = `${maxHeight + 40}px`;
            }
            this._hideSubmenusWrappers(mouseoverSubmenuWrappers);
        });
    }

    _hideSubmenusWrappers(mouseoverSubmenuWrappers) {
        mouseoverSubmenuWrappers.forEach((el) => {
            el.classList.add('hidden');
        });
    }

    _calculateMaxHeight(mouseoverSubmenuWrappers) {
        let heights = [];
        mouseoverSubmenuWrappers?.forEach((el) => {
            heights.push(el.offsetHeight);
        });
        return Math.max(...heights);
    }

    _addEventListeners() {
        this._addListener(
            'click',
            (e) => {
                const menuItem = e.target.closest(this._el('menuItem', true));
                if (!menuItem) return;
                if (!menuItem.classList.contains(this._elMod('menuItem', 'submenu'))) return;
                e.preventDefault();
                this._clickMenuItem(menuItem);
            },
            this.menu
        );

        this._addListener(
            'mouseover',
            (e) => {
                const menuItem = e.target.closest('.rt055-menu-dsk__menuItem');
                if (!menuItem) return;
                e.preventDefault();
                this._mouseoverMenuItem(menuItem);
            },
            this.root
        );

        this._addListener(
            'resize',
            () => {
                window.innerWidth < BREAKPOINTS.l && this.submenu.classList.contains('active')
                    ? this._closeMenu()
                    : this._calcMenuPosition();

                if (this._isSearchBarOpen()) {
                    window.innerWidth < BREAKPOINTS.l ? this.page.removeBlockScroll() : this.page.setBlockScroll();
                }
            },
            window
        );

        this._addListener(
            'click',
            (e) => {
                if (e.target.closest('.' + this.getName())) return;
                this._closeMenu();
            },
            document.body
        );

        this._addListener(
            'click',
            () => {
                this._emit('menuMobStatus', true);
            },
            'menuBtn'
        );

        this._addListener(
            'scroll',
            () => {
                this._setHeaderScroll();
            },
            document,
            { passive: true }
        );

        this._addListener(
            'click',
            () => {
                if (this._isSearchBarOpen()) {
                    this._closeSearchBar();
                    return;
                }
                this._closeMenu();
                this._openSearchBar();

                this.trackingManager.track(this.root, {
                    event: 'HeaderIntestazione',
                    funnel: {
                        stepFunnel: 'Intestazione',
                        elementoHeader: 'Cerca',
                    },
                    CustomLink: 'Click Header Intestazione',
                });
            },
            this.searchBtn
        );

        this._addListener(
            'click',
            () => {
                this._closeSearchBar();
            },
            this.backdrop
        );

        this._addListener(
            'rt69Close',
            () => {
                this._closeSearchBar();
            },
            this.searchBar
        );
    }

    _addStoreListeners() {
        this._addStoreListener('menuMobStatus', async (path, data) => {
            if (data) {
                this.root.classList.add(this._mod('menuMobOpen'));
                return;
            }
            await delay(400);
            this.root.classList.remove(this._mod('menuMobOpen'));
        });

        this._addStoreListener('currentPage', (path, data) => {
            this._checkPage(data.path);
        });
    }

    _checkPage(currentPath) {
        if (!currentPath) return;

        const items = this._dEl('menuItem', true);
        let matches = [];
        for (const item of items) {
            //remove selected if present
            if (item.classList.contains('selected')) item.classList.remove('selected');

            //check page path
            if (!currentPath?.includes(item.dataset.itemPath)) continue;
            matches.push({
                item: item,
                match: item.dataset.itemPath.length,
            });
        }

        //add selected to most lenght path
        if (matches && matches.length > 0) {
            const reduced = matches.reduce((x, y) => (x.match >= y.match ? x : y));
            reduced.item.classList.add('selected');
        }
    }

    _isSearchBarOpen() {
        return this.root.classList.contains(this._mod('searchOpen'));
    }

    _openSearchBar() {
        if (this._isSearchBarOpen()) return;
        this.root.classList.add(this._mod('searchOpen'));
        this.page.setBlockScroll();
        setTimeout(() => {
            this.searchBtn.blur();
            this.searchBar.querySelector('input').focus();
        }, 400);
    }

    _closeSearchBar() {
        if (!this._isSearchBarOpen()) return;
        this.root.classList.remove(this._mod('searchOpen'));
        this.page.removeBlockScroll();
        this.searchBtn.focus();
    }

    _setCallbackCb(linkA) {
        if (!linkA || !linkA.href) return;
        const link = new URL(linkA.href);
        link.searchParams.set('cb', window.location.href);
        linkA.href = link.href;
    }

    _clickMenuItem(menuItem) {
        const menuId = menuItem.dataset.menu;
        let currentSubmenu = null;

        this._dEl('menuItem', true).forEach((item) => {
            item.classList.remove('active');
        });

        for (const submenu of this.submenuItems) {
            if (submenu.id == menuId) {
                submenu.classList.add('active');
                currentSubmenu = submenu;
            } else {
                submenu.classList.remove('active');
            }
        }

        if (this.currentSubmenuId == menuId && this.submenu.classList.contains('active')) {
            this._closeMenu();
            return;
        }

        this._closeSearchBar();
        menuItem.classList.add('active');
        this.submenu.style.visibility = 'visible';
        this.submenuItems.forEach((item) => {
            item.setAttribute('tabindex', item.id === menuId ? '0' : '-1');
            item.style.visibility = item.id === menuId ? 'visible' : 'hidden';
        });
        this.submenu.classList.add('active');
        this.submenu.style.height = `${currentSubmenu.clientHeight}px`;
        currentSubmenu.focus();
        this.currentSubmenuId = menuId;
    }

    _mouseoverMenuItem(menuItem) {
        let reference = menuItem.dataset.itemRef;
        if (reference) {
            let submenuItems = this.root.querySelectorAll('.rt055-menu-dsk__menuItem');
            submenuItems.forEach((item) => {
                if (item.classList.contains('underline')) {
                    item.classList.remove('underline');
                }
            });
            menuItem.classList.add('underline');
            let elementToShow = document.getElementById(reference);
            elementToShow?.classList.remove('hidden');

            // hide elements
            let menuItems = this.root.querySelectorAll('.rt055-menu-dsk__mouseoverSubmenuWrapper');
            menuItems.forEach((item) => {
                if (item.id != elementToShow.id) {
                    item.classList.add('hidden');
                }
            });
        }
    }

    _closeMenu() {
        this._dEl('menuItem', true).forEach((item) => {
            item.classList.remove('active');
        });

        this.submenu.style.height = 0;
        this.submenu.classList.remove('active');
        this.submenu.style.visibility = 'hidden';

        this.submenuItems.forEach((item) => {
            item.setAttribute('tabindex', '-1');
            item.style.visibility = 'hidden';
        });
    }

    _calcMenuPosition() {
        for (const menu of this.menuItems) {
            const submenuItem = document.getElementById(menu.dataset.menu);
            if (!submenuItem) continue;

            const rect = menu.getBoundingClientRect();
            const popWidth = submenuItem.clientWidth;
            const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
            let left = rect.left + scrollLeft;
            if (left + popWidth > document.documentElement.scrollWidth) {
                const inRect = this.menu.getBoundingClientRect();
                left =
                    inRect.left +
                    scrollLeft +
                    parseInt(window.getComputedStyle(this.menu).getPropertyValue('padding-left'));
            }

            submenuItem.style.marginLeft = `${left}px`;
        }
    }

    _setHeaderScroll() {
        const printMedia = window.matchMedia('print');
        const print = printMedia && printMedia.matches;
        const limitScroll = this.root.offsetHeight;
        const scrollY = window.scrollY || window.pageYOffset;

        //change header position from standard to fixed during scroll
        if (!print && scrollY > 0 && !this.root.classList.contains(this._mod('scrolled'))) {
            this.root.classList.add(this._mod('scrolled'));
        } else if (scrollY <= 0) {
            this.root.classList.remove(this._mod('scrolled'));
        }

        //hide/show topbar during scroll up/down (mobile only)
        if (window.innerWidth < BREAKPOINTS.l && !print && scrollY > limitScroll) {
            const diff = scrollY - this.lastScroll;
            if (diff > 0) {
                this.root.classList.add(this._mod('topbarHidden'));
            } else if (diff < 0) {
                this.root.classList.remove(this._mod('topbarHidden'));
            }
        } else {
            this.root.classList.remove(this._mod('topbarHidden'));
        }

        this.lastScroll = scrollY;
    }

    async _checkUser() {
        let user = await getUserService().getUser();
        if (user) {
            this.root.classList.add(this._mod('user'));
        }

        this._dEl('actions').classList.add(this._elMod('actions', 'visible'));
    }
}



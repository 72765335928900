import './style.scss';
import ModalComponent from '../../../../../libs/components/modal-component';
import { getDictionary } from '../../../../../libs/dictionary-provider';
import { getRegister } from '../../../../../libs/register';
import { formToJSON } from '../../../../../libs/form-to-json';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { emptyElement, isObjectEmpty } from '../../../../../libs/utils';
import { initFliyerCategories } from '../rt071-flyer-filters/script';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class FlyerFiltersModal extends ModalComponent {
    constructor(name, root) {
        super(name, root);
        this.ACC_OPEN = this._mod('accOpen');
        this.ACCORDION_OPEN = this._elMod('accordion', 'open');
        this.ACCORDION_SHOW_COUNT = this._elMod('accordion', 'showCount');

        this.dictionary = getDictionary();
        this.register = getRegister();

        /* swiper */
        this.carousel = this._dEl('carousel');
        this.swiper = null;
        /* filters */
        this.realWrapper = this._dEl('wrapper');
        this.wrapper = this._dEl('wrapperFilters');
        this.accordions = this._dEl('accordion', true);

        this._getElements();
        this._addStoreListeners();
    }

    async _doLogic() {
        this._getElements();
        this._checkApplyResetCtas();
        await this._initScrollbars();
        this._removeAllListeners();
        this._addEventListeners();
    }

    _getElements() {
        this.accordions = this._dEl('accordion', true);
        this.headings = this._dEl('heading', true);
        this.counts = this._dEl('count', true);
        this.contents = this._dEl('content', true);
        this.forms = this._dEl('form', true);
        this.formCategories = this._dEl('formCategories');
        this.scrollbars = [];
        this.resets = this._dEl('reset', true);
        this.applies = this._dEl('apply', true);
        this.searchBars = this._dEl('search', true);
    }

    _checkApplyResetCtas() {
        for (let i = 0; i < this.accordions.length; i++) {
            //check and disable buttons if necessary
            const data = formToJSON(this.forms[i]);
            if (isObjectEmpty(data)) {
                this.register.getClass(this.applies[i]).setStatus('disabled');
                this.register.getClass(this.resets[i]).setStatus('disabled');
            }
        }
    }

    async _initSwiper() {
        const options = {
            wrapperClass: this._el('track'),
            slideClass: this._el('slideContainer'),
            slidesPerView: 'auto',
            spaceBetween: 14,
        };

        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.carousel, options);
    }

    async _initScrollbars() {
        for (let i = 0; i < this.accordions.length; i++) {
            const PerfectScrollbar = await getExtApiHelper().getPerfectScrollbar();
            const scrollbar = new PerfectScrollbar(this.forms[i], {
                swipeEasing: true,
                suppressScrollX: true,
            });
            this.scrollbars.push(scrollbar);
        }
    }

    _addEventListeners() {
        for (let i = 0; i < this.accordions.length; i++) {
            this._addListener(
                'click',
                () => {
                    /* open accordion, update content max-height */
                    this.accordions[i].classList.toggle(this.ACCORDION_OPEN);
                    this.contents[i].style.maxHeight = `${
                        !this._isAccordionOpen(this.accordions[i]) ? 0 : this.realWrapper.clientHeight - 56 - 30
                    }px`;

                    /* hide/show topBar */
                    this._isAccordionOpen(this.accordions[i])
                        ? this.root.classList.add(this.ACC_OPEN)
                        : this.root.classList.remove(this.ACC_OPEN);
                    if (this._isAccordionOpen(this.accordions[i]))
                        this._emit('rt150ExplodeFilters', { filterName: this.headings[i].firstChild.innerText });

                    /* Scroll to */
                    if (this._isAccordionOpen(this.accordions[i])) this.accordions[i].scrollIntoView();
                },
                this.headings[i]
            );
            this._addListener(
                'rcInputChanged',
                (e) => {
                    const currentString = e.data.value;
                    this._maskFilters(currentString, this.accordions[i]);
                },
                this.searchBars[i]
            );
            this._addListener(
                'click',
                () => {
                    /* close modal */
                    this.modal.close();
                    this.root.classList.remove(this.ACC_OPEN);
                    this._updateFilters([], i);
                },
                this.resets[i]
            );
            this._addListener(
                'click',
                () => {
                    /* close modal */
                    this.modal.close();
                    this.root.classList.remove(this.ACC_OPEN);
                    /* add filters and emit */
                    const filters = Object.keys({ ...formToJSON(this.forms[i]) });
                    this._updateFilters(filters, i);
                },
                this.applies[i]
            );

            this._addListener(
                'rcInputChanged',
                () => {
                    this.register.getClass(this.applies[i]).setStatus('enabled');
                    this.register.getClass(this.resets[i]).setStatus('enabled');
                },
                this.accordions[i]
            );

            //disable propagation form scroll
            this._addListener(
                'touchmove',
                (event) => {
                    event.stopPropagation();
                },
                this.forms[i]
            );
        }

        this._addListener(
            'rcInputChanged',
            (e) => {
                initFliyerCategories(e, this, this.formCategories, this.register);
            },
            this.formCategories
        );
    }

    _updateFilters(filters = [], i) {
        /* add filters and emit */
        const appliedFilters = {
            ...this.storeManager.get('appliedFilters'),
        };
        const category = this.accordions[i].dataset.filterCategory;
        if (appliedFilters[category]) delete appliedFilters[category];
        appliedFilters[category] = filters;
        this._emit('appliedFilters', appliedFilters);

        /* emit listing filters */
        const json = {};
        Object.keys(appliedFilters).forEach((k) => {
            const value = appliedFilters[k];
            if (value) json[k] = value;
        });
        this._emit('rt150Filters', { filters: json });
    }

    _addStoreListeners() {
        this._addStoreListener('reloadFlyerFilters', async (path, data) => {
            if (!Array.isArray(data)) {
                console.warn('Missing filters array to reload');
                return;
            }
            for (const acc of this.accordions) {
                acc.remove();
            }

            const filterAccordionHtml = (await import('./partials/filter-accordion.html')).default;
            /* reload filter accordions */
            for (const filter of data) {
                if (!filter.value || filter.value.length <= 0) continue;
                const accordionData = {
                    uniqueId: this.root.id,
                    filterId: `flyer-filter-${filter.category}`,
                    title: filter.title,
                    filterCategory: filter.category,
                    count: filter.count,
                    filters: filter.value,
                    labels: {
                        reset: this.dictionary.get('Reset'),
                        apply: this.dictionary.get('Apply'),
                    },
                    searchable: filter.searchable,
                    searchLabel: filter.searchLabel,
                };
                const accordionEl = runTemplate(filterAccordionHtml, accordionData);
                this.wrapper.appendChild(accordionEl);
            }
            /* restart logic */
            this._doLogic();
        });

        this._addStoreListener('loadCategoryFilters', async (path, data) => {
            if (!Array.isArray(data)) {
                console.warn('Missing filters array to reload');
                return;
            }
            emptyElement(this.carousel);
            const categoriesData = {
                uniqueId: this.root.id + '-cat',
                categoriesFilters: data,
            };
            const filterCategoryHtml = (await import('./partials/filter-categories.html')).default;
            const categElem = runTemplate(filterCategoryHtml, categoriesData);
            this.carousel.appendChild(categElem);

            await this._initSwiper();
            this._doLogic();
        });
    }

    _isAccordionOpen(accordion) {
        return accordion.classList.contains(this.ACCORDION_OPEN);
    }

    _maskFilters(currentString, container) {
        const options = container.querySelectorAll(this._el('checkbox', true));
        for (const option of options) {
            if ((option.innerText || option.textContent)?.toLowerCase().includes(currentString.toLowerCase())) {
                option.classList.remove(this._elMod('checkbox', 'hidden'));
                continue;
            }
            option.classList.add(this._elMod('checkbox', 'hidden'));
        }
    }

    _onCancel(){
        this.flowManager.complete();
    }
}



import './style.scss';
import Component from '../../../../../libs/components/component';
import { openLoader } from '../../../../../libs/utils';

export default class SearchBar extends Component {
    constructor(name, root) {
        super(name, root);
        this.search = this._dEl('search');
        this.searchResultsPath = this.root.dataset.searchResultsPath || console.warn('Missing search results path');
        this.close = this._dEl('close');
        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            () => {
                this.root.dispatchEvent(new CustomEvent('rt69Close', { bubbles: true }));
            },
            this.close
        );
        this._addListener(
            'rt10SearchQuery',
            (e) => {
                if (!e.data.value || !this.searchResultsPath) return;
                const url = new URL(this.searchResultsPath);
                url.searchParams.set('query', e.data.value);
                openLoader('main');
                window.location = url.href;
            },
            this.search
        );
    }
}




import './style.scss';
import Component from '../../../../../libs/components/component';
import { emptyElement } from '../../../../../libs/utils';


export default class Accordion extends Component {
    constructor(name, root) {
        super(name, root);

        // constants
        this.OPEN = this._mod('open');
        this.BADGE_SHOW = this._mod('badge');

        this.heading = this._dEl('heading');
        this.badge = this._dEl('badge');
        this.content = this._dEl('content');

        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            (event) => {
                event.preventDefault();
                if (!this.isOpen()) {
                    // about to be opened
                    this.content.style.visibility = 'visible';
                }
                this.root.classList.toggle(this.OPEN);
                this.updateContentHeight();
                this._updateAriaAttributes();
                if (!this.isOpen()) {
                    // just closed
                    this.content.style.visibility = 'hidden';
                }
                const customEvent = new CustomEvent('rt17HeightChanged', { bubbles: true });
                customEvent.data = { isOpen: this.isOpen() };
                this.root.dispatchEvent(customEvent);
            },
            this.heading
        );
        this._addListener(
            'resize',
            () => {
                this.updateContentHeight();
            },
            window
        );
    }

    updateContentHeight() {
        this.content.style.maxHeight = `${!this.isOpen() ? 1 : this.content.scrollHeight + 100}px`;
    }

    setBadgeText(badgeText) {
        this.badge.innerText = badgeText;
        badgeText === '' ? this.hideBadge() : this.root.classList.add(this.BADGE_SHOW);
    }
    getBadgeText() {
        return this.badge.innerText;
    }

    hideBadge() {
        this.root.classList.remove(this.BADGE_SHOW);
    }

    isOpen() {
        return this.root.classList.contains(this.OPEN);
    }

    setContent(contentEl) {
        emptyElement(this.content);
        this.content.append(contentEl);
    }

    openContent() {
        if (this.isOpen()) return;
        this.root.classList.add(this.OPEN);
        this.content.style.maxHeight = `${this.content.scrollHeight + 100}px`;
        this._updateAriaAttributes();
    }

    closeContent() {
        this.root.classList.remove(this.OPEN);
        this.content.style.maxHeight = '1px';
        this._updateAriaAttributes();
    }

    _updateAriaAttributes() {
        const isOpen = this.isOpen();
        this.heading.setAttribute('aria-expanded', `${isOpen ? 'true' : 'false'}`);
        this.content.setAttribute('tabindex', `${isOpen ? '0' : '-1'}`);
        this.content.setAttribute('aria-hidden', `${isOpen ? 'false' : 'true'}`);
    }
}



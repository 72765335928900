import './style.scss';
import PopupComponent from '../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../libs/flow-manager';

export default class BooksReservationPopup extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this._addStoreListeners();
    }

    _addStoreListeners() {
        this._addStoreListener('closeBooksPopup', () => {
            setTimeout(() => {
                flowManager.complete();
            }, 500);
        });
    }
}



import './style.scss';
import Component from '../../../../../libs/components/component';
import { getUserService } from '../../../../../libs/user-service';

export default class Footer extends Component {
    constructor(name, root) {
        super(name, root);

        this.links = this._dEl('links');
        this.privacyPolicyLink = document.getElementById('privacy-policy-link');
        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            (e) => {
                const container = e.target.closest(this._el('linkContainer', true));
                if (!container) return;

                if (container.classList.contains(this._elMod('linkContainer', 'open'))) {
                    container.classList.remove(this._elMod('linkContainer', 'open'));
                } else {
                    container.classList.add(this._elMod('linkContainer', 'open'));
                }
            },
            this.links
        );
        if (this.privacyPolicyLink) {
            this._addListener(
                'click',
                async (e) => {
                    e.preventDefault();
                    let coop = 'cnd';
                    const user = await getUserService().getUser();
                    if (user) coop = user?.newMappedCooperative ? user.newMappedCooperative : 'cnd';
                    const url = new URL(this.privacyPolicyLink.href, window.origin);
                    url.searchParams.set('coop', coop?.toLowerCase());
                    window.location = url.href;
                },
                this.privacyPolicyLink
            );
        }
    }
}



import { ApiProvider } from './api-provider';

class MyConadApiProvider extends ApiProvider {
    constructor() {
        super();
        const serviceLang = document.documentElement.dataset.serviceLang || 'it-it';
        this.baseUrl = `${window.myConadDomain}/api/myconad/${serviceLang}`;

        this.urlMap = {
            all: this.baseUrl + '.%%.json',
            regolamentolink: this.baseUrl + '.regolamentolink.pdf',
            privacyLink: this.baseUrl + '.privacylink.pdf',
        };
    }
}

/**
 *
 * @returns {ApiProvider} api provider
 */
export const getMyConadApiProvider = () => {
    if (!window.mcApiProvider) {
        window.mcApiProvider = new MyConadApiProvider();
    }
    return window.mcApiProvider;
};

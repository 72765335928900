import { getApiProvider } from '../../../../../libs/api-provider';
import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { getTrackingManager } from '../../../../../libs/tracking-manager';

export default class ListingComp extends Component {
    constructor(name, root) {
        super(name, root);
        this._init();
        this._addEventListeners();
    }

    async _init() {
        this.loaderEndpoint = this.root.dataset.loaderEndpoint;
        this.maxCards = this.root.dataset.maxCards;
        this.promoName = this.root.dataset.promoName;

        this.cardIndex = 1;

        this.loadMore = this._dEl('loadMore');
        this.wrapper = this._dEl('wrapper');
        this.dynReg = getRegister();
        this.apiProvider = getApiProvider();
        this.trackingManager = getTrackingManager();

        await this._hideLast();

        this.thereIsAPendingRequest = false;
        this._checkLoadMore();
    }

    _addEventListeners() {
        if (this.loadMore) {
            this._addListener(
                'click',
                () => {
                    this.trackingManager.track(this.root, {
                        funnel: {
                            stepFunnel: "Interazioni con prodotti disaggregato"
                        },
                        event: 'CaricaAltriProdotti',
                        CustomLink: 'Click Carica Altri prodotti',
                        prodottoInfo: {
                            meccanicaPromozionale: this.promoName
                        }
                    });
                    this._loadMore();
                },
                this.loadMore
            );
        }
    }

    _hideLoadMore() {
        if (this.loadMore) {
            this.loadMore.classList.add(this._elMod('loadMore', 'hidden'));
        }
    }

    _showLoadMore() {
        if (this.loadMore) {
            this.loadMore.classList.remove(this._elMod('loadMore', 'hidden'));
        }
    }

    _checkLoadMore() {
        if (this.cardIndex >= this.maxCards) this._hideLoadMore();
        else this._showLoadMore();
    }

    async _loadMore() {

        const url = this.loaderEndpoint;
        const data = {
            disflyer: true,
            cardIndex: this.cardIndex,
            promoName: this.promoName,
        };
        try {
            if (!this.thereIsAPendingRequest) {
                this.thereIsAPendingRequest = true;
                this._openLoader();
                this._hideLoadMore();

                const response = await this.apiProvider.loaderGet(url, data, { unwrap: false });

                this.cardIndex++;
                await this._appendCards(response);
                this._checkLoadMore();
                this._release();
            }
        } catch (error) {
            console.error(error);
            this._release();
        }
    }

    async _appendCards(html) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const newResults = doc.querySelectorAll(this._el('card', true));
        this.wrapper.append(...newResults);
        await this._hideLast();
    }

    _openLoader() {
        const loader = this._dElMod('loader', 'hidden');
        loader.classList.remove(this._elMod('loader', 'hidden'));
        loader.classList.add(this._el('loader'));
    }

    _release() {
        const loader = this._dEl('loader');
        loader.classList.remove(this._el('loader'));
        loader.classList.add(this._elMod('loader', 'hidden'));
        this.thereIsAPendingRequest = false;
    }

    async _hideLast() {
        const cards = this.wrapper.children;

        for (const element of cards) await this._showCard(element);
        let { gridRowCount, gridColumnCount, numberOfchilds } = this._getGridData();
        // number of cards in the last row
        const excess = gridColumnCount - ((gridColumnCount * gridRowCount) % numberOfchilds);
        // place to store the computed number of card until the second last row
        let cardsUntileSecondLast = numberOfchilds;

        if (excess != gridColumnCount && !(this.cardIndex >= this.maxCards)) {
            for (let i = 0; i < excess; ) {
                let card = cards[cards.length - 1 - i];

                cardsUntileSecondLast -= this._typeOfCard(card);

                if (this._typeOfCard(card) == 2) {
                    const cardBefore = cards[cards.length - 1 - i - 1];

                    if (i == excess - 1) {
                        // First card of the last row
                        gridRowCount--;
                        const excessSecondLast = (gridColumnCount * gridRowCount) % cardsUntileSecondLast;
                        // Check if there's a hole of exactly one space in the second last row
                        if (excessSecondLast == 1) {
                            // if the card before the hole is of one space I can swap the current card (of 2 spaces) with that one
                            if (this._typeOfCard(cardBefore) == 1) {
                                card.parentNode.insertBefore(card, cardBefore);
                                card = cardBefore;
                            } else if (this._typeOfCard(cardBefore) == 2) {
                                // else I can search a card of 1 space between the hidden ones
                                for (let j = cards.length - 1 - i; j < cards.length; j++)
                                    if (this._typeOfCard(cards[j]) == 1) {
                                        const singleCard = cards[j];
                                        singleCard.parentNode.insertBefore(singleCard, card);
                                        this._showCard(singleCard);
                                    }
                            }
                        }
                    }
                }
                this._hideCard(card);
                i += this._typeOfCard(card);
            }
        }
    }

    _hideCard(element) {
        const card =  this.dynReg.getClass(element);
        element.classList.add(card._mod('hidden'));
    }

    _showCard(element) {
        const card =  this.dynReg.getClass(element);
        element.classList.remove(card._mod('hidden'));
    }

    _typeOfCard(element) {
        if (element.classList.contains('rt213-card-product-flyer')) return 1;
        if (element.classList.contains('rt222-card-promotional')) return 2;
        return 0;
    }

    _getGridData() {
        const gridComputedStyle = window.getComputedStyle(this.wrapper);

        let absoluteChildCount = 0;
        for (const child of this.wrapper.children) absoluteChildCount += this._typeOfCard(child);

        return {
            gridRowCount: gridComputedStyle.getPropertyValue('grid-template-rows').split(' ').length,
            gridColumnCount: gridComputedStyle.getPropertyValue('grid-template-columns').split(' ').length,
            numberOfchilds: absoluteChildCount,
        };
    }
}



import './style.scss';
import { getApiProvider } from '../../../../../libs/api-provider';
import Component from '../../../../../libs/components/component';
import { getDictionary } from '../../../../../libs/dictionary-provider';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { getUserService } from '../../../../../libs/user-service';
import { emptyElement } from '../../../../../libs/utils';

export default class CarrotBanner extends Component {
    constructor(name, root) {
        super(name, root);

        this.dictionary = getDictionary();
        this.userService = getUserService();
        this.apiProvider = getApiProvider();
        this.trackingManager = getTrackingManager();

        this.variant = this.root.dataset.variant;
        this.SIDE_HIDDEN_MOB = this._elMod('side', 'hiddenMob');

        this.storeLocatorLink = this.root.hasAttribute('data-store-locator-link')
            ? JSON.parse(this.root.dataset.storeLocatorLink)
            : null;
        this.changeFavoriteStoreLink = this.root.hasAttribute('data-change-favorite-store-link')
            ? JSON.parse(this.root.dataset.changeFavoriteStoreLink)
            : null;
        this.hsfTitle = this.root.dataset.hsfTitle || '';
        this.fsfFlyersHeading = this.root.dataset.fsfFlyersHeading || '';

        this.container = this._dEl('container');
        this.main = this._dEl('main');
        this.side = this._dEl('side');
        this.mainDiv = this.main.querySelector('div:first-child');
        this.sideDiv = this.side.querySelector('div:first-child');

        this._init();
    }

    async _init() {
        await this._checkUser();
        await this._checkLastVisitedStore();
        this.container.classList.add(this._elMod('container', 'initialized'));
    }

    async _checkUser() {
        if (this.variant != 'home') return;
        const user = await this.userService.getUser();
        if (!user) return;

        const store = await this.userService.getStore();
        if (!store || !store.anacanId) return;

        await this._fillHomeMain(store);
        await this._fillHomeSide(store);
    }

    async _checkLastVisitedStore() {
        if (this.variant != 'home') return;
        const user = await this.userService.getUser();
        if (user) return;
        const store = await this.userService.getStore();
        if (!store || !store.anacanId) return;
        await this._fillHomeMain(store);
        await this._fillHomeSide(store);
    }

    async _fillHomeMain(data) {
        const hmfCall = (await import('../rt102-carrot-banner-hmf/rt102-carrot-banner-hmf.html')).default;
        const hmfData = {};
        emptyElement(this.mainDiv);

        const tmpData = data;
        hmfData.storeLocatorLinkJson = this.storeLocatorLink ? JSON.stringify(this.storeLocatorLink) : null;
        tmpData.pdvLink = {
            href: data.pdvLink.href,
        };
        hmfData.storeJson = JSON.stringify(tmpData);
        hmfData.anacanId = data.anacanId;
        hmfData.insignia = data.descrizioneInsegna;
        hmfData.coopId = data.codiceCooperativa;
        hmfData.changeFavoriteStoreLinkJson = this.changeFavoriteStoreLink
            ? JSON.stringify(this.changeFavoriteStoreLink)
            : null;

        const hmfElem = runTemplate(hmfCall, hmfData);
        this.main.append(hmfElem);
    }

    async _fillHomeSide(data) {
        const hsfCall = (await import('../rt102-carrot-banner-hsf/rt102-carrot-banner-hsf.html')).default;

        const hsfData = {
            title: this.hsfTitle,
            storeJson: JSON.stringify(data),
        };

        hsfData.anacanId = data.anacanId;
        hsfData.insignia = data.descrizioneInsegna;
        hsfData.coopId = data.codiceCooperativa;

        emptyElement(this.sideDiv);
        if (!data.volantini || data.volantini.length <= 0) {
            this._hideEmptySideMob();
            return;
        }
        const hsfElem = runTemplate(hsfCall, hsfData);
        this.side.append(hsfElem);
    }

    // TODO: check on HMF from HTL
    _hideEmptySideMob() {
        if (!this.side.classList.contains(this.SIDE_HIDDEN_MOB)) this.side.classList.add(this.SIDE_HIDDEN_MOB);
    }
    _resetSideMob() {
        this.side.classList.remove(this.SIDE_HIDDEN_MOB);
    }
}



import './style.scss';
import Component from '../../../../../libs/components/component';


export default class StatusDot extends Component {
    constructor(name, root) {
        super(name, root);

        // constants
        this.STATUS = ['success', 'error', 'warning', 'info'];
    }

    setStatus(status) {
        if (!this.STATUS.includes(status)) return;
        this.root.dataset.status = status;
    }
}




import './style.scss';
import Component from '../../../../../libs/components/component';
import { getUserService } from '../../../../../libs/user-service';

export default class AssociateCardBanner extends Component {
    constructor(name, root) {
        super(name, root);
        this._checkUserConadCard();
    }

    async _checkUserConadCard() {
        const user = await getUserService().getUser();
        if (!user || user.cartaFedelta || user.conadCard) return;
        /* hide self */
        const event = new CustomEvent('hideLayoutItem', { bubbles: true });
        event.data = {
            hide: true,
        };
        this.root.dispatchEvent(event);
    }
}



import './style.scss';
import Component from '../../../../../libs/components/component';
import { getGMapsHelper } from '../../../../../libs/gmaps-helper';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getPdvDistance, getPoiType } from '../../../../../libs/pdv-utils';

export default class CardNearbyContainer extends Component {
    constructor(name, root) {
        super(name, root);
        this.resList = this._dEl('track');
        this.SLIDE = this._el('slide');

        this.lon = this.root.dataset.storeLon;
        this.lat = this.root.dataset.storeLat;
        this.stores = JSON.parse(this.root.dataset.storeNeighbors);
        this._addResults();
    }

    async _addResults() {
        const results = this.stores;
        if (!results || results.length <= 0) {
            this._removeMainComponent();
            return;
        }

        const nearbyCardCall = (await import('../rt220-card-nearby/rt220-card-nearby.html')).default;

        /* the first one is always itself */
        const destinations = [];
        let count = 0;
        for (let store of results) {
            if (store.anacanId == this.anacanId) continue;
            if (count >= 3) break;
            destinations.push(`${store.latitudine},${store.longitudine}`);
            count++;
        }

        const times = await this.calcDistance(this.lat, this.lon, destinations);

        count = 0;
        for (let store of results) {
            if (store.anacanId == this.anacanId) continue;
            if (count >= 3) break;

            const nearbyCardData = {
                extraClasses: this.SLIDE,
                title: store.pdvTitle,
                text: store.pdvAddress,
                km: getPdvDistance(store),
                minutes: times[count]?.duration?.text || '',
                link: store.pdvLink,
                icon: getPoiType(store),
            };
            const nearbyCard = runTemplate(nearbyCardCall, nearbyCardData);

            this.resList.appendChild(nearbyCard);
            count++;
        }
    }

    async calcDistance(latitudine, longitudine, destinations) {
        const maps = await getGMapsHelper().getGMaps();
        const service = new maps.DistanceMatrixService(); // instantiate Distance Matrix service
        const matrixOptions = {
            origins: [`${latitudine},${longitudine}`], // technician locations
            destinations, // customer address
            travelMode: 'DRIVING',
            unitSystem: maps.UnitSystem.METRIC,
        };

        return new Promise((resolve) => {
            service.getDistanceMatrix(matrixOptions, function (response) {
                if (response?.rows?.length > 0 && response?.rows[0].elements) resolve(response?.rows[0].elements);
                resolve([]);
            });
        });
    }
}



import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { calcLeft, calcTop, isObjectEmpty } from '../../../../../libs/utils';
import { formToJSON } from '../../../../../libs/form-to-json';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class FilterPopup extends Component {
    constructor(name, root) {
        super(name, root);
        this.register = getRegister();

        this.filterId = this.root.dataset.filterId;
        this.category = this.root.dataset.filterCategory;
        if (!this.filterId || !this.category) {
            console.error(`Missing filter id and/or category for filter popup of id "${this.root.id}"`);
            return;
        }
        this.form = this._dEl('form');
        this.reset = this._dEl('reset');
        this.apply = this._dEl('apply');
        this.search = this._dEl('search');
        this.register = getRegister();

        this._init();
    }

    async _init() {
        await this._addPerfectScrollBar();
        this._addEventListeners();
    }

    async _addPerfectScrollBar() {
        const PerfectScrollbar = await getExtApiHelper().getPerfectScrollbar();
        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true,
        };
        this.ps = new PerfectScrollbar(this.form, scrollbarOptions);
    }

    _addEventListeners() {
        this._addListener('showFilterPopup', (event) => {
            if (event.data.forceClose) {
                this.root.classList.remove('open');
                return;
            }
            if (!event.data.filter) return;
            this.filter = event.data.filter;
            this.title = event.data.title;
            if (!this.isOpen()) this._setTooltipPosition();
            this.root.classList.toggle('open');
            if (this.isOpen()) this.root.focus();

            //check and disable buttons if necessary
            const data = formToJSON(this.form);
            if (isObjectEmpty(data)) {
                this.register.getClass(this.apply).setStatus('disabled');
                this.register.getClass(this.reset).setStatus('disabled');
            }
        });

        this._addListener(
            'resize',
            () => {
                if (window.innerWidth < BREAKPOINTS.l) {
                    /* mobile */
                    this._close();
                }
                if (!this.isOpen()) return;
                this._setTooltipPosition();
            },
            window
        );

        /* click outside the popup */
        this._addListener(
            'click',
            (event) => {
                if (!this.isOpen()) return;
                const target = event.target;
                if (target.closest(this.getSelector())) return;
                this.root.classList.remove('open');
            },
            document
        );

        this._addListener(
            'click',
            () => {
                this._close();
                this._updateFilters([]);
            },
            this.reset
        );

        this._addListener(
            'click',
            () => {
                this._close();
                const filters = Object.keys({ ...formToJSON(this.form) });
                this._updateFilters(filters);
            },
            this.apply
        );

        if (this.search) {
            this._addListener(
                'rcInputChanged',
                (e) => {
                    const currentString = e.data.value;
                    this._maskFilters(currentString);
                },
                this.search
            );
        }

        this._addListener(
            'rcInputChanged',
            () => {
                this.register.getClass(this.apply).setStatus('enabled');
                this.register.getClass(this.reset).setStatus('enabled');
            },
            this.form
        );
    }

    _updateFilters(filters = []) {
        /* add filters and emit */
        const appliedFilters = {
            ...this.storeManager.get('appliedFilters'),
        };
        if (appliedFilters[this.category]) delete appliedFilters[this.category];
        appliedFilters[this.category] = filters;
        this._emit('appliedFilters', appliedFilters);

        /* emit listing filters */
        const json = {};
        Object.keys(appliedFilters).forEach((k) => {
            const value = appliedFilters[k];
            if (value) json[k] = value;
        });
        this._emit('rt111Filters', { filters: json });
        this._emit('rt150Filters', { filters: json, currentFilterName: this.title });
    }

    _maskFilters(currentString) {
        const options = this._dEl('option', true);
        for (const option of options) {
            if ((option.innerText || option.textContent)?.toLowerCase().includes(currentString.toLowerCase())) {
                option.classList.remove(this._elMod('option', 'hidden'));
                continue;
            }
            option.classList.add(this._elMod('option', 'hidden'));
        }
    }

    isOpen() {
        return this.root.classList.contains('open');
    }

    _close() {
        this.root.classList.remove('open');
    }
    _open() {
        this.root.classList.add('open');
    }

    _setTooltipPosition() {
        if (!this.filter) return;
        this.root.style.top = calcTop(this.root, this.filter);
        this.root.style.left = calcLeft(this.root, this.filter);
    }
}



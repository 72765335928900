import './style.scss';
import Component from '../../../../../libs/components/component';

export default class SearchAlert extends Component {
    constructor(name, root) {
        super(name, root);

        this.trailingText = this._dEl('text').querySelector('span:last-child');
        this.close = this._dEl('close');

        this._addEventListeners();
    }

    _addEventListeners() {
        this._addListener(
            'click',
            () => {
                this.closeSearchAlert();
            },
            this.close
        );
        this._addListener(
            'closeSearchAlert',
            (e) => {
                this.closeSearchAlert();
                e.stopPropagation();
            },
            this.root
        );
    }

    setTrailingText(text) {
        this.trailingText.innerText = `${text}`;
    }

    setTheme(theme) {
        this.root.setAttribute('data-theme', theme);
    }

    closeSearchAlert() {
        this.root.dispatchEvent(new CustomEvent('closedSearchAlert', { bubbles: true }));
        this.root.remove();
    }
}


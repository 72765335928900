import './style.scss';
import ValidableComponent from '../../../../../libs/components/validable-component';

export default class Textarea extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        this.input = this._dEl('input');
        this.optional = this._dEl('label').querySelector('span');
        this.error = this._dEl('error');

        this.showOptional(!this.input.required);
        if (this.root.hasAttribute('data-new-line-disabled')) {
            this._disableNewLine();
        }
        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    _addEventListeners() {
        this._addListener(
            ['keyup', 'change', 'input'],
            () => {
                this._checkState();
                this._changedInput();
            },
            this.input
        );

        this._addListener(
            'focusout',
            () => {
                if (this.input.required && this.input.value == '') {
                    this.setState('error');
                    this._requireField();
                }
            },
            this.input
        );

        /* hide default browser error popup on enter pressed */
        this._addListener(
            'invalid',
            () => {
                this._checkState();
                if (this.input.required && this.input.value == '') {
                    this.setState('error');
                    this._requireField();
                }
            },
            this.root,
            true
        );
    }

    _requireField() {
        this.error.innerText = 'Campo obbligatorio';
    }

    _disableNewLine() {
        this._addListener(
            'keydown',
            (event) => {
                if (event.keyCode == 13) event.preventDefault();
            },
            this.input
        );
    }

    showOptional(show = true) {
        this.optional.style.display = show ? 'inline' : 'none';
    }

    getLabel() {
        return this.input.placeholder;
    }

    getName() {
        return this.input.name;
    }

    getValue() {
        return this.input.value;
    }

    isValid() {
        return !this.input.required || (this.input.required && this.input.value);
    }

    reset() {
        this.input.value = '';
        this.setState('');
    }
}



import './style.scss';
import ValidableComponent from '../../../../../libs/components/validable-component';
import { getRegister } from '../../../../../libs/register';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { debounce, emptyElement, transitionEnd, isInViewport } from '../../../../../libs/utils';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class Select extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        // constants
        this.register = getRegister();
        this.SELECTED = 'selected';
        this.OPEN = this._mod('open');
        this.DISABLED = this._mod('disabled');
        this.LABEL_HIDDEN = this._elMod('label', 'hidden');

        this.page = document.querySelector('.rs1-page');
        this.id = this.root.id;
        this.button = this._dEl('button');
        this.label = this._dEl('label');
        this.selectLabel = this._dEl('selectLabel');
        this.input = this._dEl('input');
        this.inputLabel = this._dEl('inputLabel');
        this.content = this._dEl('content');
        this.error = this._dEl('error');
        this.items = this._dEl('item', true);
        this.selectedItem = this.items.filter((item) => item.classList.contains(this.SELECTED))[0];
        this.selValue = this.input.value;
        this.keyDownText = '';

        this._init();
    }

    async _init() {
        const PerfectScrollbar = await getExtApiHelper().getPerfectScrollbar();
        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true,
            wheelPropagation: false,
        };
        this.ps = new PerfectScrollbar(this.content, scrollbarOptions);
        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    /* override */
    _checkState() {
        if (!this.isValid()) {
            this.setState('error');
        } else if (this.isValid() && this.getValue()) {
            this.setState('valid');
        } else {
            this.setState('');
        }
    }

    _addEventListeners() {
        /* open dropdown */
        this._addListener(
            'click',
            (event) => {
                event.preventDefault();
                /* close other select dropdowns on the page */
                this.page.querySelectorAll(this.getSelector()).forEach((sel) => {
                    if (sel.id === this.id) return;
                    this.register.getClass(sel).closeDropdown();
                });
                if (this.isDropdownOpen()) {
                    this.closeDropdown();
                    //check required
                    if (this.input.required && this.input.value == '') {
                        this.setState('error');
                        this._requireField();
                    }
                } else {
                    this.openDropdown();
                }
            },
            this.button
        );

        /* select option */
        this._addListener(
            ['click', 'keydown'],
            (event) => {
                if (event.type === 'keydown' && event.key !== 'Enter') return;
                const item = event.target.closest(this._el('item', true));
                if (!item) return;
                event.preventDefault();
                this.setSelected(item.dataset.value);
                this.setState('valid');
                this.closeDropdown();
                this.button.focus();
            },
            this.content
        );

        /* invalid field, set message */
        this._addListener(
            'invalid',
            () => {
                this._checkState();
                if (this.input.required && this.input.value == '') {
                    this.setState('error');
                    this._requireField();
                }
            },
            this.input
        );

        this._addListener(
            'change',
            () => {
                this.input.setCustomValidity('');
            },
            this.input
        );

        this._addListener(
            ['keyup', 'change', 'input', 'focusout'],
            (e) => {
                if (e.type === 'focusout') this.keyDownText = '';
                this._checkState();
            },
            this.input
        );

        /* debounced scrolling to best match option on keydown */
        const scrollDebounced = debounce((text) => {
            const scored = [...this.items]
                .map((item) => {
                    let label = item.querySelector(this._el('option', true))?.innerText?.toLowerCase();
                    return {
                        item: item,
                        label: label,
                        score: label.indexOf(text),
                    };
                })
                .filter((x) => x.score >= 0)
                .sort((x, y) => x.score - y.score);
            if (scored.length <= 0) return;
            const bestOption = scored[0];
            this.content.scrollTop = bestOption.item.offsetTop;
            bestOption.item?.focus();
            this.keyDownText = '';
        }, 800);
        this._addListener(
            'keydown',
            (event) => {
                if (['Enter', 'Tab', 'Shift'].includes(event.key)) return;
                event.preventDefault();
                if (!event.key.match(/^[a-zA-Z]$/)) {
                    event.stopPropagation();
                    return;
                }
                this.keyDownText += event.key;
                scrollDebounced(this.keyDownText);
                event.stopPropagation();
            },
            this.root
        );

        /* fix parent scrolling on mobile */
        this._addListener(
            'touchmove',
            (event) => {
                event.stopPropagation();
            },
            this.content
        );

        this._addListener(
            'click',
            (event) => {
                const target = event.target;
                if (!target.closest(`.${this.name}`)) {
                    this.closeDropdown();
                }
            },
            document.body
        );
    }

    isDropdownOpen() {
        return this.root.classList.contains(this.OPEN);
    }

    async _scrollIntoView() {
        if (!this.isDropdownOpen()) return;
        if (!this.content) return;
        await transitionEnd(this.content);
        if (isInViewport(this.content)) return;
        this.content.scrollIntoView();
    }

    openDropdown() {
        if (this.isDropdownOpen()) return;
        this.root.classList.add(this.OPEN);
        this._scrollIntoView();
        setTimeout(() => {
            this.ps.update();
        }, 700);
    }

    closeDropdown() {
        if (!this.isDropdownOpen()) return;
        this.root.classList.remove(this.OPEN);

        setTimeout(() => {
            this.button?.focus();
        }, 200);
    }

    setSelected(value) {
        /* get item */
        if (value.indexOf("'") >= 0) value = value.replace("'", "\\'");
        const item = this.content.querySelector(`${this._el('item', true)}[data-value='${value}']`);
        if (!item) return;

        this._resetItems();
        item.classList.add(this.SELECTED);
        this.selectedItem = item;
        this.label.textContent = item.textContent;
        this._showLabel();
        this.selValue = value;
        this.input.value = item.dataset.value;
        this.inputLabel.value = item.textContent.trim();
        this._changedInput();
    }

    async setItems(items) {
        /* empty dropdown */
        emptyElement(this.content);

        /* load html */
        const selectItemCall = (await import('./partials/select-item.html')).default;

        /* populate dropdown */
        const documentFragment = document.createDocumentFragment();
        for (const item of items) {
            const el = await runTemplate(selectItemCall, item);
            documentFragment.appendChild(el);
        }
        this.content.appendChild(documentFragment);
        /* set items and get selected items */
        this.items = Array.from(this._dEl('item', true));
        this.selectedItem = this.items.filter((item) => item.classList.contains(this.SELECTED))[0];
        /* apply pre-selected value */
        if (this.selectedItem) {
            this.setSelected(this.selectedItem.dataset.value);
        }
    }

    _showLabel() {
        this.label.classList.remove(this.LABEL_HIDDEN);
    }
    _hideLabel() {
        if (this.label.classList.contains(this.LABEL_HIDDEN)) return;
        this.label.classList.add(this.LABEL_HIDDEN);
    }

    getSelected() {
        return this.selectedItem;
    }

    getLabel() {
        return this.getSelected() ? this.getSelected().querySelector(this._el('option', true)).textContent : '';
    }

    getName() {
        return this.root.dataset.name;
    }

    getValue() {
        return this.getSelected() ? this.getSelected().dataset.value : '';
    }

    isValid() {
        if (!this._getInput().required) return true;
        return this.items.map((item) => item.dataset.value).filter((val) => val === this.input.value).length == 1;
    }

    reset() {
        this._resetItems();
        this.label.textContent = '';
        this._hideLabel();
        this.selValue = '';
        this.input.value = '';
        this.inputLabel.value = '';
        this.closeDropdown();
        this.setState('');
    }

    _resetItems() {
        this.items.forEach((item) => item.classList.remove(this.SELECTED));
    }

    enable() {
        if (!this.root.classList.contains(this.DISABLED)) return;
        this.root.classList.remove(this.DISABLED);
    }

    disable() {
        if (this.root.classList.contains(this.DISABLED)) return;
        this.root.classList.add(this.DISABLED);
    }

    _requireField() {
        this.error.innerText = 'Campo obbligatorio';
    }
}



import Component from './component';

export default class ValidableComponent extends Component {
    constructor(name, root) {
        super(name, root);

        // constants
        this.VALID = this._mod('validState');
        this.ERROR = this._mod('errorState');
        this.WARNING = this._mod('warningState');

        if (!this.root.hasAttribute('data-is-validable')) {
            throw 'A validable component need to hava a data-is-validable on root element';
        }
    }

    _getInput() {
        throw 'Method need to be implemented by subclasses';
    }

    getInput(){
        return this._getInput();
    }

    getLabel() {
        return this._getInput().placeholder;
    }

    getName() {
        return this._getInput().name;
    }

    getValue() {
        return this._getInput().value;
    }

    setValue(value){
        this._getInput().value = value;
        this._changedInput();
    }

    isValid() {
        return this._getInput().validity.valid || (!this._getInput().required && !this._getInput().value);
    }

    checkValidity() {
        const valid = this.isValid();
        if (valid) {
            this.setState('valid');
        } else {
            this.setState('error');
        }
        return valid;
    }

    setState(code) {
        const input = this._getInput();

        switch (code) {
            case 'error':
                this.root.classList.add(this.ERROR);
                this.root.classList.remove(this.VALID);
                this.root.classList.remove(this.WARNING);
                if (input) input.setCustomValidity(' ');
                break;
            case 'warning':
                this.root.classList.add(this.WARNING);
                this.root.classList.remove(this.VALID);
                this.root.classList.remove(this.ERROR);
                if (input) input.setCustomValidity('');
                break;
            case 'valid':
                this.root.classList.add(this.VALID);
                this.root.classList.remove(this.ERROR);
                this.root.classList.remove(this.WARNING);
                if (input) input.setCustomValidity('');
                break;
            case 'undefined':
            default:
                this.root.classList.remove(this.VALID);
                this.root.classList.remove(this.ERROR);
                this.root.classList.remove(this.WARNING);
                if (input) input.setCustomValidity('');
                break;
        }
    }

    _checkState() {
        const input = this._getInput();
        if (input) input.setCustomValidity('');

        if (!this.isValid() && this.getValue()) {
            this.setState('error');
        } else if (this.isValid() && this.getValue()) {
            this.setState('valid');
        } else {
            this.setState('');
        }
    }

    _changedInput() {
        const data = {
            valid: this.isValid(),
            value: this.getValue(),
            label: this.getLabel(),
        };

        const event = new CustomEvent('rcInputChanged', { bubbles: true });
        event.data = data;
        this.root.dispatchEvent(event);

        //console.debug(`Changed data for ${this.root.getAttribute('id')}`, data);
    }

    reset() {
        this._getInput().value = '';
        this.setState('');
    }

    trackFormError(errorText = null) {
        const form = {
            errorField: this.getName(),
        };
        if (errorText != null) form.errorText = errorText;
        const event = new CustomEvent('rcFormError', { bubbles: true });
        event.data = form;
        this.root.dispatchEvent(event);
    }
}

import './style.scss';
import ValidableComponent from '../../../../../libs/components/validable-component';
import { getGMapsHelper } from '../../../../../libs/gmaps-helper';
import { getStoreManager } from '../../../../../libs/store-manager';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { delay, getZIndex } from '../../../../../libs/utils';

export default class SearchField extends ValidableComponent {
    constructor(name, root) {
        super(name, root);
        this.gMapsHelper = getGMapsHelper();
        this.storeManager = getStoreManager();
        this.trackingManager = getTrackingManager();

        this._getElements();
        this._doLogic();
    }

    async _doLogic() {
        if (!this.searchQuery) await this._initGoogleAutocomplete();
        this._addEventListeners();

        //if geolocation is active & autogeocode active, default start search with user location
        if (this.root.dataset.autogeocode == 'true' && window.navigator.geolocation) {
            /*setTimeout(() => {
                this._detectLocation();
            }, 500);*/
            //wait 500ms to be sure that page is loaded
        }
    }

    _getElements() {
        // constants
        this.CANCEL_HIDDEN = this._elMod('cancel', 'hidden');
        this.DETECT_LOCATION_ACTIVE = this._elMod('detectLocationContainer', 'active');

        this.input = this._dEl('input');
        this.searchBtn = this._dEl('searchBtn');
        this.cancel = this._dEl('cancel');
        this.error = this._dEl('error');
        this.detectLocationContainer = this._dEl('detectLocationContainer');
        this.detectLocationItem = this._dEl('detectLocationItem');

        this.country = this.root.dataset.country;
        if (this.country.indexOf(',') >= 0) {
            this.country = this.country.split(',');
        }
        this.checkStreet = this.root.hasAttribute('data-check-street');
        this.value = null;
        this.searchType = null;
        this.searchQuery = this.root.hasAttribute('data-search-query');
    }

    _getInput() {
        return this.input;
    }

    getValue() {
        return this.value;
    }

    isValid() {
        return !this._getInput().required || this.value;
    }

    _addEventListeners() {
        this._addListener(
            ['input', 'change'],
            () => {
                if (this.input.value === '' && !this.cancel.classList.contains(this.CANCEL_HIDDEN))
                    this.cancel.classList.add(this.CANCEL_HIDDEN);
                if (this.input.value != '') {
                    this.error.innerText = '';
                    this._showCancelField();
                }
            },
            this.input
        );

        if (!this.searchQuery) {
            this._addListener(
                ['input', 'change', 'focus'],
                () => {
                    this._showDetectLocation(this.input.value === '');
                },
                this.input
            );
            this._addListener(
                'click',
                () => {
                    this._detectLocation();
                },
                this.detectLocationItem
            );
        }

        this._addListener(
            'keypress',
            async (event) => {
                if (!(event.key == 'Enter')) return;
                if (!this.searchQuery) {
                    await this._manualSearch();
                    return;
                }
                this._searchQuery();
            },
            this.input
        );

        this._addListener(
            'click',
            async () => {
                if (!this.searchQuery) {
                    await this._manualSearch();
                    return;
                }
                this._searchQuery();
            },
            this.searchBtn
        );

        this._addListener(
            'click',
            (event) => {
                event.preventDefault();
                this.reset();
                this._changedInput();
                this.root.dispatchEvent(new CustomEvent('rt10CanceledField', { bubbles: true }));
            },
            this.cancel
        );

        this._addListener(
            'focusout',
            async () => {
                if (this.searchQuery) return;
                /* add little delay because focusout preceeds click on detectLocationItem */
                await delay(100);
                this._showDetectLocation(false);
                if (this.input.required && this.input.value == '') {
                    this.setState('error');
                    this._requireField();
                }
                if (this.input.value !== '' && this.value == null) {
                    this.setState('error');
                    this._invalidField();
                }
            },
            this.input
        );

        this._addListener(
            'focusin',
            (event) => {
                // set z-index = input
                const zindex = getZIndex(this.input);
                Array.from(document.querySelectorAll('.pac-container')).forEach((pc) => {
                    pc.style.zIndex = `${zindex}`;
                });

                this.trackingManager.track(event.target, {
                    event: 'BarraRicerca',
                    CustomLink: 'Click barra ricerca',
                    funnel: {
                        stepFunnel: 'Ricerca Store Locator',
                    },
                });
            },
            this.input
        );
    }

    async _initGoogleAutocomplete() {
        const gmaps = await this.gMapsHelper.getGMaps();
        this.gmaps = gmaps;
        const options = {
            componentRestrictions: {
                country: this.country,
            },
        };
        if (this.checkStreet) options.types = ['address'];
        this.autocomplete = new gmaps.places.Autocomplete(this.input, options);
        this.autocompleteListener = gmaps.event.addListener(this.autocomplete, 'place_changed', () => {
            const currentPlace = this.autocomplete.getPlace();
            if (!this._manageState(currentPlace)) return;
            this.value = currentPlace;
            this._changedInput();
            this._toggleCancelField();

            this.trackingManager.track(this.root, {
                event: 'ItemRicerca',
                CustomLink: 'Selezione item ricerca',
                negozioInfo: {
                    indirizzoRicerca: currentPlace.formatted_address,
                },
                funnel: {
                    stepFunnel: 'Ricerca Store Locator',
                },
            });
        });
        /* init gmaps services */
        this.autocompleteService = new gmaps.places.AutocompleteService();
        this.geocoder = new gmaps.Geocoder();
    }

    _showDetectLocation(show = true) {
        if (!this.detectLocationContainer) return;
        if (show) {
            if (!this.detectLocationContainer.classList.contains(this.DETECT_LOCATION_ACTIVE))
                this.detectLocationContainer.classList.add(this.DETECT_LOCATION_ACTIVE);
        } else {
            this.detectLocationContainer.classList.remove(this.DETECT_LOCATION_ACTIVE);
        }
    }

    _detectLocation() {
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                (pos) => {
                    this._locationDetectionSuccess(pos);
                },
                (error) => {
                    this._locationDetectionFailed(error);
                },
                {
                    maximumAge: 3600000 /* 1 hour */,
                    timeout: 3000 /* 3 seconds */,
                    enableHighAccuracy: true,
                }
            );
        } else {
            console.warn('geolocation not found in navigator object');
        }
    }

    _locationDetectionSuccess(pos) {
        const coords = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
        };
        this.geocoder.geocode({ location: coords }, (results, status) => {
            if (status === 'OK') {
                const place = results[0];
                this.input.value = place.formatted_address;
                if (!this._manageState(place)) {
                    return;
                }
                this.value = place;
                this._changedInput();
                this.searchType = 'Geolocalizzazione';
                this._emit('rt10AddressSearchType', { searchType: this.searchType });
                this._toggleCancelField();
            } else {
                console.warn(`Geocoder failed with code ${status}`);
            }
        });
    }
    _locationDetectionFailed(error) {
        switch (error.code) {
            case window.GeolocationPositionError.PERMISSION_DENIED:
                console.warn(`Geolocation permission denied. ${error.message}`);
                break;
            case window.GeolocationPositionError.POSITION_UNAVAILABLE:
                console.warn(`Geolocation position not available. ${error.message}`);
                break;
            case window.GeolocationPositionError.TIMEOUT:
                console.warn(`Geolocation request went in timeout. ${error.message}`);
                break;
            default:
                console.warn(`Unknown error ${error.code}: ${error.message}`);
                break;
        }
    }

    _requireField() {
        this.error.innerText = 'Campo obbligatorio';
    }

    _invalidField() {
        this.error.innerText = 'Campo non valido';
    }

    _toggleCancelField() {
        this.input.value !== '' ? this._showCancelField() : this._hideCancelField();
    }
    _hideCancelField() {
        if (this.cancel.classList.contains(this.CANCEL_HIDDEN)) return;
        this.cancel.classList.add(this.CANCEL_HIDDEN);
    }
    _showCancelField() {
        this.cancel.classList.remove(this.CANCEL_HIDDEN);
    }

    async _manualSearch() {
        const gmaps = await this.gMapsHelper.getGMaps();
        this.token = new gmaps.places.AutocompleteSessionToken();
        /* retrieve predictions */
        const placePredictionsRequest = {
            input: this.input.value,
            sessionToken: this.token,
        };
        this.autocompleteService.getPlacePredictions(placePredictionsRequest, (predictions, status) => {
            if (status != window.google.maps.places.PlacesServiceStatus.OK || !predictions || predictions.length <= 0) {
                console.warn(`No predictions found for value "${placePredictionsRequest.input}"`);
                return;
            }
            /* retrieve first prediction details by place id via geocoder */
            const firstPrediction = predictions[0];
            const geocodeRequest = { placeId: firstPrediction.place_id };
            this.geocoder.geocode(geocodeRequest, (results, status) => {
                if (status != window.google.maps.GeocoderStatus.OK || !results || results.length <= 0) {
                    console.warn('No place found with geocoder for the given place id');
                    return;
                }
                const place = results[0];
                this.input.value = place.formatted_address;
                if (!this._manageState(place)) {
                    return;
                }
                this.value = place;
                this._changedInput();
                this.searchType = 'Manuale';
                this._emit('rt10AddressSearchType', { searchType: this.searchType });
                this._toggleCancelField();
            });
        });
    }

    _manageState(currentPlace) {
        if (!currentPlace || !currentPlace.types || !currentPlace.address_components) {
            /* null address */
            this._invalidField();
            return false;
        }
        if (this.checkStreet && currentPlace.types.indexOf('street_address') == -1) {
            /* address without street or street number */
            this.setState('error');
            if (currentPlace.types.indexOf('route') == -1) {
                /* address without street (default invalid error text) */
                this._invalidField();
                return false;
            }
            /* address with street but without street number (custom error text) */
            this._invalidField('Inserisci numero civico');
        } else {
            this.setState('valid');
        }
        return true;
    }

    _getAddressComp(comps, type, field) {
        const comp = comps.filter((cmp) => cmp.types.includes(type))[0];
        return comp ? comp[field] : '';
    }

    _searchQuery() {
        const value = this._getInput().value;
        if (!value || value == '') return;
        const event = new CustomEvent('rt10SearchQuery', { bubbles: true });
        event.data = {
            value: this._getInput().value,
        };
        this.root.dispatchEvent(event);
    }

    setInputValue(value) {
        this._getInput().value = value;
        this._showCancelField();
        this.value = null;
        this.setState('');
    }

    reset() {
        this._getInput().value = '';
        this._hideCancelField();
        this.value = null;
        this.setState('');
    }

    dispose() {
        super.dispose();
        this.gmaps?.event.clearListeners(this.autocomplete, 'place_changed');
        this.gmaps?.event.clearInstanceListeners(this.autocomplete);
        this.autocomplete.unbindAll();
        this.input.classList.remove('pac-target-input');
    }
}



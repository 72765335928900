import './style.scss';
import PopupComponent from '../../../../../libs/components/popup-component';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { getFlowManager } from '../../../../../libs/flow-manager';

export default class RefreshConfirmPopup extends PopupComponent {
    constructor(name, root) {
        super(name, root);
        this.flowManager = getFlowManager();
        this.trackingManager = getTrackingManager();
        this.proceedCta = this._dEl('proceed');
        this.cancelCta = this._dEl('cancel');
        this.closeCta = this._dEl('close');
        this._addListeners();
    }

    _onOpen() {
        super._onOpen();
        //track pageView thank you age
        this.trackingManager.track(this.root, {
            event: 'pageview',
            funnel: {
                nomeFunnel: 'Conad Refresh',
                stepFunnel: 'Pop up uscita aggiornamento dati',
            },
        });
    }

    _addListeners() {
        this.proceedCta.addEventListener('click', () => {
            this.trackingManager.track(this.root, {
                event: 'uscitaAggiornamentoDatiRefresh',
                funnel: {
                    nomeFunnel: 'Conad Refresh',
                    stepFunnel: 'Pop up uscita aggiornamento dati - Esci',
                },
            });
            const data = this.flowManager.getDataFromFlow();
            if (data.pageToGo) {
                let redirectPage = data.pageToGo;
                window.location.href = redirectPage;
            }
        });

        this.cancelCta.addEventListener('click', () => {
            this.flowManager.complete();
        });

        this.closeCta.addEventListener('click', () => {
            this.flowManager.complete();
        });
    }

    _onCancel() {
        this.flowManager.complete();
    }
}



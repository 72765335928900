class Dictionary {
    constructor() {
        this.dictionary = window.dictionary;
    }

    /**
     * Get dictionary byg key
     * @param {*} key
     * @returns The label
     */
    get(key) {
        return this._get(key);
    }

    _get(key) {
        const res = this.dictionary ? this.dictionary[key] : null;
        if (res) return res;
        return key;
    }
}

/**
 *
 * @returns {Dictionary} dictionary
 */
export const getDictionary = () => {
    if (!window.rcDictionary) {
        window.rcDictionary = new Dictionary();
    }
    return window.rcDictionary;
};

import './style.scss';
import Component from '../../../../../libs/components/component';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';

export default class CardTextComponent extends Component {
    constructor(name, root) {
        super(name, root);

        this.carousel = this._dEl('carousel');
        this.pagination = this._dEl('pagination');
        this.swiper = null;
        this.slide = this._dEl('slideContainer');
        this.read = this._dEl('read');
        if (this.read) {
            this.rules = document.getElementById(this.read.dataset.rulesId);
        }

        this._init();
    }

    async _init() {
        await this._initSwiper();
        this._addEventListeners();
    }

    async _initSwiper() {
        const slideCounts = this._dEl('slideContainer', true).length;
        if (this.pagination && slideCounts <= 3) {
            this.pagination.classList.add(this._elMod('pagination', 'hidden'));
        }

        const options = {
            wrapperClass: this._el('track'),
            slideClass: this._el('slideContainer'),
            slidesPerView: 'auto',
            spaceBetween: 16,
            watchOverflow: true,
            observer: true,
            observeParents: true,
        };

        if (this.pagination) {
            options['pagination'] = {
                el: this._dEl('pagination', true),
                bulletClass: this._el('bullet'),
                bulletActiveClass: this._elMod('bullet', 'active'),
                clickable: true,
            };
            options['breakpoints'] = {
                1024: {
                    slidesPerView: 3,
                },
            };
        }

        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.root, options);
    }

    _scroll() {
        const offset = window.innerWidth < this.BREAKPOINT_L ? 100 : 200;
        window.scrollBy(0, this.rules.getBoundingClientRect().top - offset);
    }

    _addEventListeners() {
        this._addListener(
            'click',
            () => {
                if (this.rules) {
                    this._scroll();
                }
            },
            this.read
        );
    }

    //delete all
    dispose() {
        super.dispose();
        this.swiper?.destroy(true, true);
    }
}

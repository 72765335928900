import './style.scss';
import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { animationEnd } from '../../../../../libs/utils';

export default class Popup extends Component {
    constructor(name, root) {
        super(name, root);

        this.register = getRegister();
        this.page = this.register.getClass(document.querySelector('.rs1-page'));
        this.popupName = this.root.dataset.name;
        this.backdrop = this._dEl('backdrop');
        this.content = this._dEl('content');

        this.updateHeight();
        this._addEventListeners();
    }

    _addEventListeners() {
        document.addEventListener('click', (event) => {
            if (!event.target.matches(`[data-open-popup='${this.popupName}']`)) return;
            event.preventDefault();
            this.open();
        });
        this.backdrop.addEventListener('click', (event) => {
            event.preventDefault();
            this.cancel();
        });
        this.root.addEventListener('click', (event) => {
            if (!event.target.matches('[data-close-popup]')) return;
            event.preventDefault();
            this.cancel();
        });
        window.addEventListener('resize', () => {
            this.updateHeight();
        });
        document.addEventListener('scroll', () => {
            this.updateHeight();
        });
    }

    updateHeight() {
        this.root.style.height = `${window.innerHeight}px`;
    }

    async open() {
        this._sendBeforeOpenEvent();
        this.root.classList.add(this._mod('open'));
        this.page.setBlockScroll();
        this.content.classList.add(this._elMod('content', 'animate'));
        await animationEnd(this.content);
        this.content.classList.remove(this._elMod('content', 'animate'));
        this._sendOpenEvent();
    }

    isOpen() {
        return this.root.classList.contains(this._mod('open'));
    }

    close() {
        this.root.classList.remove(this._mod('open'));
        this.page.removeBlockScroll();
        this._sendCloseEvent();
    }

    cancel() {
        this._sendCancelEvent();
        this.close();
    }

    toggle() {
        if (this.root.classList.contains(this._mod('open'))) {
            this.close();
        } else {
            this.open();
        }
    }

    getPopupName() {
        return this.popupName;
    }

    _sendBeforeOpenEvent() {
        const event = new CustomEvent('ecPopupBeforeOpen', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    _sendOpenEvent() {
        const event = new CustomEvent('ecPopupOpen', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    _sendCloseEvent() {
        const event = new CustomEvent('ecPopupClose', { bubbles: true });
        this.root.dispatchEvent(event);
    }

    _sendCancelEvent() {
        const event = new CustomEvent('ecPopupCancel', { bubbles: true });
        this.root.dispatchEvent(event);
    }
}



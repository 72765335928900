import { getApiProvider } from './api-provider';
import { getStoreManager } from './store-manager';
import { getUserService } from './user-service';
import { getClosestAll, mergeDeep, isObject, replaceAsync } from './utils';

class TrackingManager {
    constructor() {
        this.document = document;
        this.body = this.document.body;
        this.userService = getUserService();
        this.storeManager = getStoreManager();
    }

    appendVariable(vars) {
        this.storeManager.emit(
            'trackingVars',
            {
                ...vars,
            },
            true
        );
    }

    /**
     * Track page view event
     */
    async trackPageView() {
        //load owr info
        const owr = this.storeManager.get('trackingPageView');
        owr.event = 'pageView';

        const data = await this._loadTrackContext(this.body, owr);
        this._track(data);
    }

    /**
     * Standard track, event need to be put inside owr object
     * @param {*} el
     * @param {*} owr
     */
    async track(el, owr = {}) {
        const data = await this._loadTrackContext(el, owr);
        this._track(data);
    }

    /**
     * Track link
     * @param {*} el The link element
     * @param {*} options The options
     */
    async trackLink(el, options = {}) {
        //load fallback infos
        const fallback = {};
        fallback.event = 'Click su CTA';
        fallback.CustomLink = el.text?.trim() || el.href;

        //load context
        const data = await this._loadTrackContext(el, options, fallback);
        this._track(data);
    }

    async _loadBasicInfo() {
        return {
            userinfo: await this.userService.getUser(),
        };
    }

    async _loadTrackContext(el, owr, fallback = {}) {
        let jsonContext = mergeDeep(fallback, this.storeManager.get('trackingContext'));

        const els = getClosestAll(el, '[data-tracking-context]');
        for (const el of els) {
            jsonContext = mergeDeep(jsonContext, JSON.parse(el.dataset.trackingContext));
        }

        const tmpContext = {
            ...jsonContext,
            ...(await this._loadBasicInfo()),
        };

        const result = !owr ? tmpContext : mergeDeep(tmpContext, owr);
        return await this._resolveVars(el, result);
    }

    getVars(el) {
        let trackingVars = {};

        const els = getClosestAll(el, '[data-tracking-vars]');
        for (const el of els) {
            trackingVars = mergeDeep(trackingVars, JSON.parse(el.dataset.trackingVars));
        }

        const storeTrackingVars = this.storeManager.get('trackingVars');
        if (storeTrackingVars) {
            trackingVars = mergeDeep(trackingVars, storeTrackingVars);
        }

        return trackingVars;
    }

    _track(data) {
        console.log(`>>>> TRACKING DEBUG: ${data.event}`, data);
        /* push to adobeDataLayer */
        if (!window.adobeDataLayer) {
            console.warn('adobeDataLayer not found, cannot push analytics tracking data');
            return;
        }
        window.adobeDataLayer.push(data);
    }

    async _resolveVars(el, data) {
        let trackingVars = this.getVars(el);

        let json = JSON.stringify(data);
        json = await replaceAsync(
            json,
            /"(vars|fn|data|text|cmp)\.([A-Za-z-_]+)(?:\|\|([A-Za-z-_ ]+))?"/g,
            async (found, p1, p2, p3) => {
                let data = '';
                switch (p1) {
                    case 'data':
                        data = this._findClosestData(el, p2) || p3 || '';
                        break;
                    case 'fn':
                        data = (await this._runFunction(el, p2)) || p3 || '';
                        break;
                    case 'text':
                        data = this._findClosestText(el, p2) || p3 || '';
                        break;
                    case 'cmp':
                        data = this._findComponentText(el, p2) || p3 || '';
                        break;
                    case 'vars':
                        data = trackingVars[p2] || p3 || '';
                        break;
                }

                if (data instanceof Object) {
                    return JSON.stringify(data);
                } else if (Array.isArray(data)) {
                    return JSON.stringify(data);
                }
                return `"${data}"`;
            }
        );
        const result = JSON.parse(json);
        return this._cleanTrackingObject(result);
    }

    _findClosestData(el, name) {
        const dataToFind = 'data-' + name.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());
        const closest = el.closest(`[${dataToFind}]`);
        if (!closest) return null;

        const value = closest.dataset[name];
        return value;
    }

    _findClosestText(el, name) {
        const dataToFind = 'data-' + name.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());
        const closest = el.closest(`[${dataToFind}]`);
        if (!closest) return null;

        return closest.textContent?.trim();
    }

    _findComponentText(el, name) {
        const validCmp = (el) => {
            const component = el.closest(`[data-component]`);
            if (!component) return null;

            const firstClass = component.classList[0];
            if (firstClass.match(/^rt0[01234][0-9]-.*$/))
                return validCmp(component.parentElement || component.parentNode);
            return component;
        };

        const component = validCmp(el);
        if (!component) return null;

        const firstClass = component.classList[0];
        const selector = '.' + firstClass + '__' + name;

        const items = Array.from(component.querySelectorAll(selector));
        if (!items || items.length == 0) return null;
        return items[0].textContent?.trim();
    }

    async _runFunction(el, name) {
        switch (name) {
            case 'currentLabel': {
                return el.textContent?.trim();
            }
            case 'anacanParams': {
                return await this._getStoreFromParams();
            }
            case 'delete': {
                return '__delete__';
            }
            case 'customLink': {
                let title = this._findComponentText(el, 'title');
                let label = el.textContent?.trim();
                if (el.matches('a')) return label + ' ' + title;
                else return '__delete__';
            }
            case 'eventLink': {
                return this._eventLink(el);
            }
            default:
                return null;
        }
    }

    async _getStoreFromParams() {
        const urlParams = new URLSearchParams(window.location.search);
        const anacanId = urlParams.get('anacanId');
        if (!anacanId) return '__delete__';

        try {
            const pdv = await getApiProvider().getPointOfServiceByAnacanId({ anacanId });
            return {
                CooperativaNegozio: pdv.codiceCooperativa,
                Insegna: pdv.descrizioneInsegna,
                idNegozio: pdv.anacanId,
            };
        } catch (e) {
            console.warn('Cannot get pdv {}', anacanId);
            return '__delete__';
        }
    }

    _eventLink(el) {
        let href = el.href;
        if (!href) return '__delete__';

        switch (true) {
            case /https?:\/\/(?:ecommerce\.nsc.*|spesaonline\.conad\.it)/.test(href):
                return 'AcquistaSuEcommerce';
            case /https?:\/\/mipremio\.(?:nsc.*|conad)\.it/.test(href):
                return 'VaiAlCatalogoMiPremio';
            case /https?:\/\/www\.saporie(tst)?\.com/.test(href):
                return 'ScopriSuSaporie';
            case /https?:\/\/www\.conadserviziassicurativi\.it/.test(href):
                return 'ServiziAssicurativi';
            default:
                return 'Click su CTA';
        }
    }

    _cleanTrackingObject(source) {
        let obj = source;
        let result = {};

        if (isObject(obj)) {
            for (const key in obj) {
                if (obj[key] != '__delete__') {
                    if (isObject(obj[key])) {
                        result[key] = this._cleanTrackingObject(obj[key]);
                    } else {
                        result[key] = obj[key];
                    }
                }
            }
        }

        return result;
    }
}

window.rcTrackingManager = new TrackingManager();

/**
 *
 * @returns {TrackingManager} the tracking manager
 */
export const getTrackingManager = () => {
    return window.rcTrackingManager;
};

export const trackingManager = window.rcTrackingManager;

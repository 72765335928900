import Component from '../../../../../libs/components/component';
import './style.scss';

export default class RefreshPrizeCoupon extends Component {
    constructor(name, root) {
        super(name, root);
        this.root = root;

        this._addEventListeners();
    }

    _addEventListeners() {}
}



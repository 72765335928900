import './style.scss';
import Component from '../../../../../libs/components/component';

export default class Step extends Component {
    constructor(name, root) {
        super(name, root);

        // constants
        this.SELECTED = this._mod('selected');
        this.FILLED = this._mod('filled');
    }

    isSelected() {
        return this.root.classList.contains(this.SELECTED);
    }
    select() {
        if (this.isSelected()) return;
        this.root.classList.add(this.SELECTED);
    }
    unselect() {
        this.root.classList.remove(this.SELECTED);
    }
    
    isFilled() {
        return this.root.classList.contains(this.FILLED);
    }
    fill() {
        if (this.isFilled()) return;
        this.root.classList.add(this.FILLED);
    }
    unfill() {
        this.root.classList.remove(this.FILLED);
    }
}


import './style.scss';
import Component from '../../../../../libs/components/component';
import { getStoreManager } from '../../../../../libs/store-manager';
import { getRegister } from '../../../../../libs/register';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import { getUserService } from '../../../../../libs/user-service';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { getApiProvider } from '../../../../../libs/api-provider';
import { closeLoader, flyerData, openLoader } from '../../../../../libs/utils';

export default class StoreCard extends Component {
    constructor(name, root) {
        super(name, root);

        // constants
        this.storeManager = getStoreManager();
        this.register = getRegister();
        this.trackingManager = getTrackingManager();
        this.userService = getUserService();
        this.apiProvider = getApiProvider();

        this.SELECTED = this._mod('selected');
        this.SAVED = this._mod('saved');
        this.FAV_DISABLED = this._mod('savedDisabled');
        this.FAVORITE = this._mod('favorite');
        this.SHOW_TOOLTIP = this._mod('showTooltip');
        this.SHOW_TOOLTIP_MAX_STORES = this._mod('showTooltipMaxStores');
        this.SHOW_POPUP = this._mod('showPopup');
        this.FLYERS_OPEN = this._elMod('flyers', 'open');
        this.PAGE_SELECTOR = '.rs1-page';

        this.servicesUrlMap = [];
        if (this.root.hasAttribute('data-services-url-map')) {
            const list = JSON.parse(this.root.dataset.servicesUrlMap);
            if (Array.isArray(list)) for (let mapping of list) this.servicesUrlMap[mapping.serviceId] = mapping.url;
        }

        this.id = this.root.id;
        this.storeId = this.root.dataset.storeId;
        this.coopId = this.root.dataset.coopId;
        this.cplData = this.root.dataset.cplData;

        this.content = this._dEl('content');
        this.favorite = this._dEl('favorite');
        this.setFavoriteBtn = this._dEl('setFavorite');
        this.setSavedBtn = this._dEl('setSaved');
        this.flyers = this._dEl('flyers');
        this.flyersHeading = this._dEl('flyersHeading');
        this.nameEl = this._dEl('name');
        this.services = this._dEl('serviceImage', true);

        this._addEventListeners();
        this._addStoreListeners();
        this._updateStatus();
    }

    async _updateStatus() {
        // Reset
        this.root.classList.remove(this.FAVORITE);
        this.root.classList.remove(this.SAVED);
        this.root.classList.remove(this.FAV_DISABLED);
        this._hidePopups();

        // check user
        const user = await this.userService.getUser();
        if (user == null) return;

        // show favorite button
        this.favorite.classList.add(this._elMod('favorite', 'show'));

        const savedStores = await this.userService.getSavedStores();
        const favoriteStore = await this.userService.getStore();

        // check favorites
        if (this.storeId == favoriteStore.anacanId) {
            this.root.classList.add(this.FAVORITE);
            return;
        } else {
            for (let store of savedStores) {
                if (this.storeId == store.anacanId) {
                    this.root.classList.add(this.SAVED);
                    return;
                }
            }
        }

        //if max reached
        if (savedStores.length >= window.maxStoreOfInterest) {
            this.root.classList.add(this.FAV_DISABLED);
        }
    }

    _addStoreListeners() {
        this._addStoreListener('userData', () => {
            this._updateStatus();
        });
    }

    _addEventListeners() {
        this._addListener(
            'click',
            () => {
                /* case selected */
                if (this.isSelected()) return;
                /* case not selected */
                this.unselectCards();
                this.setSelected();
                /* emit res item clicked */
                const clickedStore = new CustomEvent('clickedStoreCard', { bubbles: true });
                clickedStore.data = { storeId: this.storeId };
                this.root.dispatchEvent(clickedStore);
            },
            this.content
        );

        this._addListener(
            'click',
            () => {
                /* Tracking */
                this.trackingManager.track(this.root, {
                    event: 'VaiAllaSchedaNegozio',
                    CustomLink: 'Click Vai alla Scheda Negozio',
                    negozioInfo: {
                        indirizzoRicerca: 'vars.currentSearchAddress',
                        idNegozio: 'data.storeId',
                        CooperativaNegozio: 'data.coopId',
                        tipologiaRisultati: 'data.resultType',
                    },
                });
            },
            this.nameEl
        );

        this._addListener(
            'click',
            (event) => {
                const flyerLink = event.target.closest(this._el('flyerLink', true));
                if (!flyerLink) return;

                /* Tracking */
                this.trackingManager.track(flyerLink, {
                    event: 'VaiAlVolantino',
                    CustomLink: 'Click Vai al volantino',
                    negozioInfo: {
                        indirizzoRicerca: 'vars.currentSearchAddress',
                        idNegozio: 'data.storeId',
                        CooperativaNegozio: 'data.coopId',
                        tipologiaRisultati: 'data.resultType',
                    },
                    volantinoInfo: {
                        idVolantino: 'data.flyerId',
                        CooperativaVolantino: 'data.flyerCoop',
                        nomeVolantino: 'data.flyerName',
                        TipologiaVolantino: 'data.flyerType',
                    },
                });
            },
            this.flyers
        );

        if (this.setFavoriteBtn && this.setSavedBtn) {
            this._addListener(
                'click',
                async () => {
                    openLoader('main');
                    const res1 = await this.userService.setPreferredStore(this.storeId);
                    const res2 = await this.userService.getSavedStores();
                    let isStoreSaved = false;
                    if (Array.isArray(res2) && res2.find((element) => element.anacanId == this.storeId)) {
                        isStoreSaved = true;
                    }
                    if (!isStoreSaved) {
                        this.userService.addSavedStore(this.storeId);
                    }
                    if (res1) {
                        this.root.classList.remove(this.SHOW_POPUP);
                    }

                    /* Tracking */
                    this.trackingManager.track(this.root, {
                        event: 'PdvPreferito',
                        CustomLink: 'Aggiunta Pdv preferito',
                        negozioInfo: {
                            indirizzoRicerca: 'vars.currentSearchAddress',
                            idNegozio: 'data.storeId',
                            CooperativaNegozio: 'data.coopId',
                            tipologiaRisultati: 'data.resultType',
                        },
                    });

                    closeLoader('main');
                },
                this.setFavoriteBtn
            );
            this._addListener(
                'click',
                async () => {
                    openLoader('main');
                    let res = await this.userService.addSavedStore(this.storeId);
                    if (res) {
                        this.root.classList.add(this.SHOW_TOOLTIP);
                        setTimeout(() => this.root.classList.remove(this.SHOW_TOOLTIP), 2000);
                        this.root.classList.remove(this.SHOW_POPUP);
                    }
                    closeLoader('main');
                },
                this.setSavedBtn
            );
        }

        //// POPUP FAVORITE
        if (this.favorite)
            this._addListener(
                'click',
                async (event) => {
                    event.preventDefault();

                    // hide current popup
                    if (this.root.classList.contains(this.SHOW_POPUP)) {
                        this._hidePopups();
                        return;
                    }

                    // open another popup
                    this._hidePopups();
                    const savedStores = await this.userService.getSavedStores();
                    if (this.root.classList.contains(this.SAVED)) {
                        openLoader('main');
                        await this.userService.removeSavedStore(this.storeId);
                        closeLoader('main');
                    } else if (savedStores.length >= window.maxStoreOfInterest) {
                        this.root.classList.add(this.SHOW_TOOLTIP_MAX_STORES);
                        setTimeout(() => this.root.classList.remove(this.SHOW_TOOLTIP_MAX_STORES), 5000);
                    } else {
                        this.root.classList.add(this.SHOW_POPUP);
                        this._dEl('popup')?.focus();
                    }
                },
                this.favorite
            );

        if (this.flyersHeading)
            this._addListener(
                'click',
                async (event) => {
                    event.preventDefault();

                    if (!this._dEl('flyersContent')) {
                        const flyers = await this._callStoreFlyers(this.storeId);
                        let flyersData = [];
                        for (let f of flyers) {
                            const fData = await flyerData(f);
                            fData.title = fData.feTitle;
                            flyersData.push(fData);
                        }

                        const accContentHtml = (await import('./partials/acc-content.html')).default;
                        const accContent = runTemplate(accContentHtml, { storeId: this.storeId, flyers: flyersData });
                        this.flyers.append(accContent);
                        // set aria-controls on button
                        this.flyersHeading.setAttribute('aria-controls', this._dEl('flyersContent').id);
                    }

                    if (!this.isAccOpen()) {
                        // about to be opened
                        this._dEl('flyersContent').style.visibility = 'visible';
                    }
                    this.flyers.classList.toggle(this.FLYERS_OPEN);
                    this._updateAriaAttributes();
                    if (!this.isAccOpen()) {
                        // just closed
                        this._dEl('flyersContent').style.visibility = 'hidden';
                    }

                    this.trackingManager.track(event.target, {
                        event: 'AccordionVolantini',
                        funnel: {
                            stepFunnel: 'Interazioni con Risultati Store Locator',
                        },
                        CustomLink: 'Esplodi Accordion Volantini',
                        negozioInfo: {
                            indirizzoRicerca: 'vars.currentSearchAddress',
                            idNegozio: this.storeId,
                            CooperativaNegozio: this.coopId,
                            tipologiaRisultati: 'Elenco',
                        },
                    });
                },
                this.flyersHeading
            );

        this.services?.forEach((service) => {
            this._addListener(
                'click',
                () => {
                    if (
                        service.hasAttribute('data-service') &&
                        this.servicesUrlMap[service.getAttribute('data-service')]
                    )
                        window.open(this.servicesUrlMap[service.getAttribute('data-service')].href, '_blank');
                },
                service
            );
        });
    }

    async _callStoreFlyers(storeId) {
        let flyers = [];
        try {
            flyers = await this.apiProvider.getStoreFlyers(storeId);
        } catch (error) {
            console.warn('Could not get store flyers');
        }
        return flyers;
    }

    isSelected() {
        return this.root.classList.contains(this.SELECTED);
    }
    setSelected(select = true) {
        if (select) {
            this.root.classList.add(this.SELECTED);
        } else {
            this.root.classList.remove(this.SELECTED);
        }
    }

    unselectCards() {
        const page = document.querySelector(this.PAGE_SELECTOR);
        const cards = Array.from(page.querySelectorAll(this.getSelector()));
        for (const card of cards) {
            const cardObj = this.register.getClass(card);
            if (cardObj.id !== this.id) cardObj.setSelected(false);
        }
    }

    _hidePopups() {
        const page = document.querySelector(this.PAGE_SELECTOR);
        const cards = Array.from(page.querySelectorAll(this.getSelector()));
        cards.forEach((card) => card.classList.remove(this.SHOW_POPUP));
    }

    isAccOpen() {
        if (!this._dEl('flyersContent')) return false;
        return this.flyers.classList.contains(this.FLYERS_OPEN);
    }

    _updateAriaAttributes() {
        const isOpen = this.isAccOpen();
        this.flyersHeading.setAttribute('aria-expanded', `${isOpen ? 'true' : 'false'}`);
        this._dEl('flyersContent').setAttribute('tabindex', `${isOpen ? '0' : '-1'}`);
        this._dEl('flyersContent').setAttribute('aria-hidden', `${isOpen ? 'false' : 'true'}`);
    }
}



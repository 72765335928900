import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { closeLoader, delay, emptyElement, openLoader } from '../../../../../libs/utils';
import { formToJSON } from '../../../../../libs/form-to-json';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import * as storeCardHtml from './partials/store-card.html';
import { getDictionary } from '../../../../../libs/dictionary-provider';
import { getFlowManager } from '../../../../../libs/flow-manager';
import './style.scss';

export default class ExcelToMapSearchStoresForm extends Component {
    constructor(name, root) {
        super(name, root);
        this.flowManager = getFlowManager();
        this._doLogic();
    }

    _doLogic() {
        this.register = getRegister();
        this.labelCtaCards = this.root.dataset.labelCtaCards;
        this.pagination = [];
        this.PAGE_SIZE = 8;
        this.LOAD_MORE_HIDDEN = this._elMod('loadMore', 'hidden');

        this._getElements();
        this._extractColumns();
        this._addEventListeners();
    }

    _getElements() {
        this.form = this._dEl('form');
        this.search = this._dEl('search');
        this.numResults = this._dEl('numResults');
        this.numResultsCount = this.numResults.querySelector('span');
        this.NUM_RESULTS_SHOW = this._elMod('numResults', 'show');
        this.results = this._dEl('results');
        this.loadMore = this._dEl('loadMore');
        this.filters = this.form.querySelectorAll(this._el('filter', true));
        this.configExcelMap = JSON.parse(this.root.dataset.config);
    }

    _extractColumns() {
        this.titleKey = '';
        this.typeLabelKey = '';
        this.descriptionKey = '';
        this.iconCardKey = '';
        this.filtersColumns = [];
        this.filterDependant = [];
        const tipologies = Object.entries(this.configExcelMap.tipologies);
        for (let [key, value] of tipologies) {
            if (value.type == 'title') {
                this.titleKey = key;
            }
            if (value.type == 'typeLabel') {
                this.typeLabelKey = key;
            }
            if (value.type == 'description') {
                this.descriptionKey = key;
            }
            if (value.type == 'iconCard') {
                this.iconCardKey = key;
            }
            if (value.type == 'filters') {
                this.filtersColumns.push(key);
                this.filterDependant.push(value.filterDependant);
            }
        }
    }

    _addEventListeners() {
        this.filters?.forEach((filter, index) => {
            filter?.addEventListener('rcInputChanged', (event) => {
                event.target.classList.add(this._elMod('filter', 'activated'));
                let newRows = this._filterRows();
                if (this.filterDependant[index + 1] == true) {
                    this._changeFilterAfter(newRows, event.target, index);
                }
            });
        });

        this._addListener(
            'submit',
            (e) => {
                e.preventDefault();
            },
            this.form
        );

        this._addListener(
            'click',
            () => {
                if (this.form.checkValidity()) {
                    this._doSearchLogic();
                }
            },
            this.search
        );

        this._addListener(
            'click',
            () => {
                this.currentPage++;
                this.renderResults(this.currentPage);
                this._checkLoadMore();
            },
            this.loadMore
        );

        // add listener to results due to dynamically created store card
        this._addListener(
            'click',
            (e) => {
                if (!e.target.matches(this._el('link', true))) return;

                const link = e.target;
                let jsonModal = JSON.parse(link.closest(this._el('store', true)).dataset.modalJson);
                this.flowManager.startFlow({
                    flowName: 'excel-to-map-card-details',
                    flowSteps: [{ name: 'excel-to-map-card-details' }],
                    initialData: {
                        currentStore: jsonModal ? jsonModal : {},
                        config: JSON.parse(this.root.dataset.config),
                    },
                });
            },
            this.results
        );
    }

    _filterRows() {
        let stores = this.root.dataset.rows ? JSON.parse(this.root.dataset.rows) : [];
        let formJson = Object.entries(formToJSON(this.form));
        for (let [key, value] of formJson) {
            let filteredStores = stores.filter(
                (store) => store[key] && store[key] != '' && store[key].toLowerCase() == value.toLowerCase()
            );

            if (filteredStores.length > 0) {
                stores = filteredStores;
            } else if (filteredStores.length == 0 && stores.length == 0) {
                stores = [];
            }
        }
        this.root.dataset.results = JSON.stringify(stores);
        return stores;
    }

    _doSearchLogic() {
        // search by filters
        this._loadStores();
    }

    _requireSelection(selectEl) {
        const obj = this.register.getClass(selectEl);
        obj?.setState('error');
        obj?._requireField();
    }

    _changeFilterAfter(rows, currentFilter, indexCurrentFilter) {
        if (indexCurrentFilter + 1 < this.filters.length) {
            let filterValues = Array.from(
                new Set(rows.map((item) => item[this.filters[indexCurrentFilter + 1].dataset.name]))
            );
            filterValues.sort(); // sort
            const element = this.register.getClass(this.filters[indexCurrentFilter + 1]);
            element.enable();
            element.reset();
            element.setItems(filterValues.map((filterSingle) => ({ label: filterSingle, value: filterSingle })));

            this.filters.forEach((filter, index) => {
                if (index > indexCurrentFilter + 1) {
                    const elementAfter = this.register.getClass(filter);
                    elementAfter.disable();
                    elementAfter.reset();
                }
            });
        }
    }

    async _loadStores() {
        openLoader('main');
        const filtered = this.root.dataset.results
            ? JSON.parse(this.root.dataset.results)
            : JSON.parse(this.root.dataset.rows);
        this.paginateStoreCards(filtered);
        this.setNumResults(filtered.length);
        emptyElement(this.results);
        this.renderResults();
        this._checkLoadMore();
        await delay(250);
        closeLoader('main');
    }

    _getStoreCard(store) {
        const storeCardData = {
            title: store[this.titleKey],
            subtitle: store[this.typeLabelKey],
            description: store[this.descriptionKey],
            icon: store[this.iconCardKey],
            linkLabel: getDictionary().get(this.labelCtaCards),
            jsonModal: JSON.stringify(store),
        };
        return runTemplate(storeCardHtml, storeCardData);
    }

    paginateStoreCards(stores) {
        this.pagination.splice(0, this.pagination.length);
        for (const store of stores) {
            const storeCard = this._getStoreCard(store);
            this.pagination.push(storeCard);
        }
        this.numPages = Math.ceil(this.pagination.length / this.PAGE_SIZE);
        this.currentPage = 1;
    }

    renderResults(pageIndex = 1) {
        if (!this.numPages || this.numPages <= 0) {
            console.warn('Missing or invalid numPages');
            return;
        }
        const start = (Math.min(pageIndex, this.numPages) - 1) * this.PAGE_SIZE;
        const end = start + this.PAGE_SIZE;
        const page = this.pagination.slice(start, end);
        for (const card of page) {
            this.results.append(card);
        }
    }

    _checkLoadMore() {
        if (this.currentPage >= this.numPages) {
            this.loadMore.classList.add(this.LOAD_MORE_HIDDEN);
        } else {
            this.loadMore.classList.remove(this.LOAD_MORE_HIDDEN);
        }
    }

    setNumResults(num = 0) {
        if (!num || num <= 0) {
            this.numResultsCount.innerText = '0';
            this.numResults.classList.remove(this.NUM_RESULTS_SHOW);
            return;
        }
        this.numResultsCount.innerText = `${num}`;
        this.numResults.classList.add(this.NUM_RESULTS_SHOW);
    }
}



import './style.scss';
import Component from '../../../../../libs/components/component';
import { generateShareLinks } from '../../../../../libs/utils';
import { getTrackingManager } from '../../../../../libs/tracking-manager';

export default class Advice extends Component {
    constructor(name, root) {
        super(name, root);
        this.share = this._dEl('ctaShare');
        this.trackingManager = getTrackingManager();

        this._init();
    }

    async _init() {
        await this._initShare();
        this._addEventListeners();
    }

    
    _addEventListeners() {

        this._addListener(
            'click',
            (ev) => {
                ev.preventDefault();
                if (!this.shareId) return;
                const event = new CustomEvent('showShareLinks', { bubbles: true });
                event.data = {
                    root: this._dEl('ctaBookmark'),
                };
                const popup = document.getElementById(this.shareId);
                popup.dispatchEvent(event);
                this.trackingManager.track(this.root, {
                    event: 'Condividi',
                    CustomLink: 'Click Condividi'
                });
                ev.stopPropagation();
            },
            this.share
        );
    }

    async _initShare() {
        this.shareId = await generateShareLinks(this.root.dataset.link, this.root.dataset.title);
    }
}



import { getRegister } from '../../../../../../libs/register';

export const getPopupObjByName = (name) => {
    const modal = document.querySelector(`.ru003-popup[data-name=${name}]`);
    if (!modal) return null;
    const modalObj = getRegister().getClass(modal);
    if (!modalObj) return null;
    return modalObj;
};

export const getCurrentPopup = (element) => {
    const modalEl = element.closest('.ru003-popup');
    if (!modalEl) return;
    const modalObj = getRegister().getClass(modalEl);
    if (!modalObj) return;
    return modalObj;
};

import { getApiProvider } from '../../../../../libs/api-provider';
import './style.scss';
import Component from '../../../../../libs/components/component';
import { getUserService } from '../../../../../libs/user-service';
import { closeLoader, emptyElement, htmlToElement, openLoader } from '../../../../../libs/utils';
import { getTrackingManager } from '../../../../../libs/tracking-manager';

export default class DisaggregatedFlyer extends Component {
    constructor(name, root) {
        super(name, root);

        let url = new URL(window.location.href);
        if (url.searchParams.has('pageIndex')) {
            const elMeta = document.querySelector('meta[name="robots"]');
            if (elMeta) {
                elMeta.setAttribute('robots', 'noindex, nofollow');
            } else {
                const mMeta = document.createElement('meta');
                mMeta.setAttribute('name', 'robots');
                mMeta.setAttribute('content', 'noindex, nofollow');
                document.head.appendChild(mMeta);
            }
        }

        this.loaderEndpoint = this.root.dataset.loaderEndpoint;
        this.totalSlices = this.root.dataset.numberSlices;

        if (this.root.dataset.aggCode) this.aggCode = this.root.dataset.aggCode;
        if (this.root.dataset.filters) this.filtersJson = JSON.parse(this.root.dataset.filters);
        if (this.root.dataset.categories) this.categoriesJson = JSON.parse(this.root.dataset.categories);

        this.triggerOffset = 400;
        this.nextIndex = 1;
        this.thereIsAPendingRequest = false;
        this.filters = {};
        this.categoriesFilter = {};
        this.catLabels = [];
        this.orderBy = {};
        this.indexScrollPosition = [0];
        this.pageIndex = 0;
        this.registerBox = this._dEl('registerBox');
        this.productsSuggestedWrapper = this._dEl('registerBox--productsSuggested');
        this.wrapperElBox = this._dEl('registerBox--wrapperEl');

        this.apiProvider = getApiProvider();
        this.trackingMananger = getTrackingManager();

        this._getElements();
        this._addEventListeners();
        this._initFiltersModal();
        this._initBoxRegisterUser();
    }

    _getElements() {
        this.filtersWrapper = this._dEl('filtersWrapper');
        this.content = this._dEl('wrapper');
        this.orderByWrapper = this._dEl('orderByWrapper');
    }

    _initFiltersModal() {
        if (this.filtersJson) this._emit('reloadFlyerFilters', this.filtersJson);
        if (this.categoriesJson) {
            const arrayOfCategories = Object.entries(this.categoriesJson).reduce(
                (acc, currentEntry) => [...acc, currentEntry[1]],
                []
            );
            this._emit('loadCategoryFilters', arrayOfCategories);
        }
    }

    async _initBoxRegisterUser() {
        const user = await getUserService().getUser();

        if (this.registerBox) {
            if (user) {
                let store = await getUserService().getStore();
                const url = this.loaderEndpoint;
                const pattern = /\.html/g;
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                let anacanId = urlParams.get('anacanId');
                if (store && store.anacanId && store.anacanId == anacanId) {
                    let urlTemp = url.replace(pattern, `.anacan-${store.anacanId}.html`);
                    urlTemp = urlTemp + '?userPersonalizedProduct=' + user.utenzaId;
                    const response = await this.apiProvider.loaderGet(urlTemp, {});
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(response, 'text/html');
                    const tr = doc.querySelector(this._el('wrapper', true));
                    let cards = Array.from(doc.querySelectorAll('.rt213-card-product-flyer'));

                    if (cards.length > 0) {
                        if (tr) {
                            let disaggregatedId = tr.dataset.volDisaggregatedId;
                            let anacanId = store.anacanId;
                            this._emit('PropsCurrentFlyer', { anacanId, disaggregatedId });
                        }
                        this._appendCardsSuggested(cards);
                    } else {
                        this.registerBox.classList.add('rt150-disaggregated-flyer__registerBox--remove');
                        setTimeout(() => {
                            this.registerBox.remove();
                        }, 1000);
                    }
                } else {
                    this.registerBox.classList.add('rt150-disaggregated-flyer__registerBox--remove');
                    setTimeout(() => {
                        this.registerBox.remove();
                    }, 1000);
                }
            } else {
                this.registerBox.classList.remove('rt150-disaggregated-flyer__registerBox--hide');
            }
        }
    }

    _appendCardsSuggested(cards) {
        this.wrapperElBox.classList.remove('rt150-disaggregated-flyer__registerBox--wrapperEl');
        this.wrapperElBox.classList.add('rt150-disaggregated-flyer__registerBox--wrapperSuggestedText');
        if (this.productsSuggestedWrapper.dataset.suggestedTitle) {
            this._dEl('registerBox-title').innerHTML = this.productsSuggestedWrapper.dataset.suggestedTitle;
        } else {
            this._dEl('registerBox-title').remove();
            this._dEl('registerBox-separator').remove();
        }
        this._dEl('registerBox-text').remove();
        this._dEl('registerBox-cta').remove();
        this.productsSuggestedWrapper.append(...cards);
        this.registerBox.classList.remove('rt150-disaggregated-flyer__registerBox--hide');
    }

    _addEventListeners() {
        // Scroll listener
        this._addListener(
            'scroll',
            () => {
                const wrapperElement = this._dEl('wrapper');

                if (!wrapperElement || !wrapperElement.offsetParent) return;

                const offset =
                    wrapperElement.getBoundingClientRect().top -
                    wrapperElement.offsetParent.getBoundingClientRect().top;
                const top = window.pageYOffset + window.innerHeight - offset;
                if (top >= wrapperElement.scrollHeight - this.triggerOffset && this.nextIndex < this.totalSlices) {
                    this._downloadResults(this.nextIndex);
                }
            },
            window,
            { passive: false }
        );

        this._addListener(
            'scroll',
            () => {
                const currentScroll = window.scrollY;
                let newPageIndex = 0;
                for (let i = 0; i < this.indexScrollPosition.length; i++) {
                    if (currentScroll >= this.indexScrollPosition[i]) {
                        newPageIndex = i;
                    }
                }

                if (this.pageIndex != newPageIndex) {
                    // change url
                    let fUrl = new URL(window.location.href);
                    if (newPageIndex && newPageIndex > 0) {
                        fUrl.searchParams.set('pageIndex', newPageIndex);
                    } else if (fUrl.searchParams.has('pageIndex')) {
                        fUrl.searchParams.delete('pageIndex');
                    }
                    history.replaceState({}, '', fUrl);
                    this.pageIndex = newPageIndex;
                }
            },
            window,
            { passive: true }
        );

        this._addListener(
            'rcInputChanged',
            async (e) => {
                this.orderBy = { orderBy: e.data.value };
                await this._updateWithFilters();
            },
            this.orderByWrapper
        );

        this._addStoreListener('rt150ExplodeFilters', (path, data) => {
            this.trackingMananger.track(this.root, {
                funnel: {
                    stepFunnel: 'Filtro',
                },
                event: 'EsplodiFiltro',
                CustomLink: 'Esplodi filtro',
                filtro: {
                    NomeFiltro: data.filterName,
                    CategorieProdottoVolantino: this._trackArrayString(this.catLabels),
                },
            });
        });

        this._addStoreListener('rt150Filters', async (path, data) => {
            this.filters = data.filters;
            this.trackingMananger.track(this.root, {
                funnel: {
                    stepFunnel: 'Filtro',
                },
                event: 'ApplicaFiltro',
                CustomLink: 'Applica filtro',
                filtro: {
                    NomeFiltro: data.currentFilterName,
                    CategorieProdottoVolantino: this._trackArrayString(this.catLabels),
                    Marchi: this._trackArrayString(this.filters.brand_string),
                    Promozioni: this._trackArrayString(this.filters.meccanicaPromozione_string),
                    CarteConad: this._trackArrayString(this.filters.endPrezzo),
                    Vantaggi: this._trackArrayString(this.filters.vantaggi),
                },
            });
            await this._updateWithFilters();
            if (this.filtersWrapper) this.filtersWrapper.focus();
        });

        this._addStoreListener('rt150CategoriesFilters', async (path, data) => {
            this.categoriesFilter = data.filters;
            this.catLabels = data.filterLabels;
            this.trackingMananger.track(this.root, {
                funnel: {
                    stepFunnel: 'Scelta Categorie prodotto',
                },
                event: 'SceltaCategorieProdotto',
                CustomLink: 'Click Categoria Prodotto',
                filtro: {
                    CategorieProdottoVolantino: this._trackArrayString(this.catLabels),
                },
            });

            await this._updateWithFilters();
            this._emit('071UpdateSwiper', {});
            if (this.filtersWrapper) this.filtersWrapper.focus();
        });
    }

    async _updateWithFilters() {
        openLoader('main');
        await this._downloadResults(0, true);
        closeLoader('main');
    }

    async _downloadResults(sliceIndex = 0, loadFromFilters = false) {
        const url = this.loaderEndpoint;
        const data = {
            sliceIndex: sliceIndex,
            aggregatorCode: this.aggCode,
            ...this.filters,
            ...this.categoriesFilter,
            ...this.orderBy,
        };
        if (this.aggCode) data['aggregatorCode'] = this.aggCode;

        if (this._areFiltersActive()) data['disflyer'] = true;

        try {
            if (!this.thereIsAPendingRequest) {
                this.thereIsAPendingRequest = true;

                const loader = this._dElMod('loader', 'hidden');
                if (!loadFromFilters) {
                    loader.classList.remove(this._elMod('loader', 'hidden'));
                    loader.classList.add(this._el('loader'));
                }

                //reset indexScrollPosition if sliceIndex = 0
                if (sliceIndex == 0) {
                    this.indexScrollPosition = [0];
                } else {
                    this.indexScrollPosition[sliceIndex] = window.scrollY;
                }

                const response = await this.apiProvider.loaderGet(url, data, { unwrap: false });

                this.nextIndex = sliceIndex == 0 ? 1 : this.nextIndex + 1;
                this._appendResults(response, sliceIndex);
                this._release(loadFromFilters);
            }
        } catch (error) {
            console.error(error);
            this._release(loadFromFilters);
        }
    }

    _release(loadFromFilters) {
        const loader = this._dEl('loader');
        if (!loadFromFilters) {
            loader.classList.remove(this._el('loader'));
            loader.classList.add(this._elMod('loader', 'hidden'));
        }
        this.thereIsAPendingRequest = false;
    }

    _appendResults(html, sliceIndex) {
        const element = htmlToElement(html);
        if (!element) return;

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        if (sliceIndex == 0) {
            emptyElement(this.content);

            const maxSlices = doc.querySelector(this._el('numberOfSlices', true)).textContent;
            this.totalSlices = maxSlices;

            const fCount = doc.querySelector(this._el('appliedFilterCount', true)).textContent;
            const prodCount = doc.querySelector(this._el('productsCount', true)).textContent;

            this._emit('updateProductsNumber', prodCount);
            this._emit('updateFilterNumber', fCount);

            const filters = element.querySelector(this._el('filters', true));

            if (this.filtersWrapper && filters) {
                emptyElement(this.filtersWrapper);
                this.filtersWrapper.appendChild(filters);
                this._emit('reloadFlyerFilters', JSON.parse(element.dataset.filters));
                this._emit('loadCategoryFilters', JSON.parse(element.dataset.categories));
            }

            this._allND();
        }

        let newResults = this._areFiltersActive()
            ? doc.querySelectorAll('.rt072-disaggregated-block__card')
            : doc.querySelectorAll(this._el('block', true));
        this._dEl('wrapper').append(...newResults);
        this._addRemoveSpaceBottom();
    }

    _addRemoveSpaceBottom() {
        if (this._areFiltersActive() || this.nextIndex != this.totalSlices) return;

        // if the last block is of type promo remove the space bottom
        let action = 'show';
        const allBloks = this.root.querySelectorAll(this._el('block', true));
        if (allBloks && allBloks[allBloks.length - 1].hasAttribute('data-promo-name')) {
            action = 'hide';
        }
        const cEvent = new CustomEvent('rl1flyerSwitchSpaceBottom', { bubbles: true });
        cEvent.data = {
            action: action,
            htmlTarget: this.root.parentNode.parentNode,
        };
        this.root.dispatchEvent(cEvent);
    }

    _areFiltersActive() {
        const promFiltersActive =
            Object.keys(this.filters) != 0 &&
            Object.values(this.filters).reduce((acc, current) => (acc = acc || current.length != 0), false);
        const catFiltersActive =
            this.categoriesFilter['cat_lev0_id'] && !this.categoriesFilter['cat_lev0_id'].includes('__all');
        const orderByActive = this.orderBy['orderBy'];
        return promFiltersActive || catFiltersActive || orderByActive;
    }

    _allND() {
        if (this._areFiltersActive()) {
            for (const card of this.root.querySelectorAll('.rt072-disaggregated-block__card'))
                this._dEl('wrapper').appendChild(card);

            this.root.querySelectorAll(this._el('block', true)).forEach((box) => box.remove());
            this.root.querySelectorAll('.rt072-disaggregated-block__wrapper').forEach((box) => box.remove());

            this._dEl('wrapper').classList.add(this._elMod('wrapper', 'grid'));
        } else {
            this._dEl('wrapper').classList.remove(this._elMod('wrapper', 'grid'));
        }
    }

    _trackArrayString(arr) {
        return arr?.reduce((acc, curr) => (acc == '' ? curr : acc + '|' + curr), '');
    }
}



/*
 * Copyright 2018 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

/* eslint-disable */

module.exports = function main($) {

  const htl = $.globals;
  let data = $.globals["data"];

  let render;
  render = function* _template_global_render(args) { 
    let uniqueid = args[1]['uniqueId'] || '';
    let extraclasses = args[1]['extraClasses'] || '';
    let tablelabels = args[1]['tableLabels'] || '';
    let scontrino = args[1]['scontrino'] || '';
    let $t, $n = args[0];
    $.dom.text($n,"\n    ");
    $t = $.dom.create("div",false,false);
    const var_attrValue0 = ("rt073-assicurazioni-form__table ") + (extraclasses);
    if (!$.col.empty(var_attrValue0)) {
      $.dom.attr($t, 'class', var_attrValue0, 'attribute');
    }
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n        ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "rt073-assicurazioni-form__tableContent border-t-[1px] w-full border-slate-200 font-body text-[14px] text-left leading-[14px]\n                      overflow-auto text-[rgba(0,0,2,0.6)] mt-[25px]", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "rt073-assicurazioni-form__tableContentDesktop hidden lg:block", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    $.dom.rem($n," row column names ");
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "grid grid-cols-4", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                    ");
    $.dom.rem($n," columns ");
    $.dom.text($n,"\n                    ");
    const var_collectionVar1 = $.col.init(tablelabels);
    const var_size2 = $.col.len(var_collectionVar1);
    if (var_size2) {
      for (const var_index3 of $.col.keys(var_collectionVar1)) {
        const item = $.col.get(var_collectionVar1, var_index3);
        const itemlist = $.listInfo(var_index3, var_size2);
        $.dom.text($n,"\n                        ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "py-[16px] pl-[8px] font-bold", 'attribute');
        $n = $.dom.push($n,$t);
        const var_4 = yield $.xss(tablelabels[item], "html");
        $.dom.append($n, var_4);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
      }
    }
    $.dom.text($n,"\n                ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    $.dom.rem($n," row data ");
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "grid grid-cols-4", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                    ");
    $.dom.rem($n," columns ");
    $.dom.text($n,"\n                    ");
    const var_collectionVar5 = $.col.init(scontrino);
    const var_size6 = $.col.len(var_collectionVar5);
    if (var_size6) {
      for (const var_index7 of $.col.keys(var_collectionVar5)) {
        const item = $.col.get(var_collectionVar5, var_index7);
        const itemlist = $.listInfo(var_index7, var_size6);
        $.dom.text($n,"\n                        ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "bg-[--color-secondary3] flex items-center h-[60px] pl-[8px]", 'attribute');
        $n = $.dom.push($n,$t);
        const var_8 = yield $.xss(scontrino[item], "html");
        $.dom.append($n, var_8);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
      }
    }
    $.dom.text($n,"\n                ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "rt073-assicurazioni-form__tableContentMobile lg:hidden block", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                ");
    $.dom.rem($n," row column names ");
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "grid grid-cols-2", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                    ");
    $.dom.rem($n," columns ");
    $.dom.text($n,"\n                    ");
    const var_collectionVar9 = $.col.init(tablelabels,{"begin":"0","end":"1"});
    const var_size10 = $.col.len(var_collectionVar9);
    if (var_size10) {
      for (const var_index11 of $.col.keys(var_collectionVar9)) {
        const item = $.col.get(var_collectionVar9, var_index11);
        const itemlist = $.listInfo(var_index11, var_size10);
        $.dom.text($n,"\n                        ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "py-[16px] pl-[8px] font-bold", 'attribute');
        $n = $.dom.push($n,$t);
        const var_12 = yield $.xss(tablelabels[item], "html");
        $.dom.append($n, var_12);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
      }
    }
    $.dom.text($n,"\n                ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    $.dom.rem($n," row data ");
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "grid grid-cols-2", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                    ");
    $.dom.rem($n," columns ");
    $.dom.text($n,"\n                    ");
    const var_collectionVar13 = $.col.init(scontrino,{"begin":"0","end":"1"});
    const var_size14 = $.col.len(var_collectionVar13);
    if (var_size14) {
      for (const var_index15 of $.col.keys(var_collectionVar13)) {
        const item = $.col.get(var_collectionVar13, var_index15);
        const itemlist = $.listInfo(var_index15, var_size14);
        $.dom.text($n,"\n                        ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "bg-[--color-secondary3] flex items-center h-[60px] pl-[8px]", 'attribute');
        $n = $.dom.push($n,$t);
        const var_16 = yield $.xss(scontrino[item], "html");
        $.dom.append($n, var_16);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
      }
    }
    $.dom.text($n,"\n                ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    $.dom.rem($n," row column names ");
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "grid grid-cols-2", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                    ");
    $.dom.rem($n," columns ");
    $.dom.text($n,"\n                    ");
    const var_collectionVar17 = $.col.init(tablelabels,{"begin":"2"});
    const var_size18 = $.col.len(var_collectionVar17);
    if (var_size18) {
      for (const var_index19 of $.col.keys(var_collectionVar17)) {
        const item = $.col.get(var_collectionVar17, var_index19);
        const itemlist = $.listInfo(var_index19, var_size18);
        $.dom.text($n,"\n                        ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "pl-[8px] flex items-center h-[60px] font-bold", 'attribute');
        $n = $.dom.push($n,$t);
        const var_20 = yield $.xss(tablelabels[item], "html");
        $.dom.append($n, var_20);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
      }
    }
    $.dom.text($n,"\n                ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n                ");
    $.dom.rem($n," row data ");
    $.dom.text($n,"\n                ");
    $t = $.dom.create("div",false,false);
    $.dom.attr($t, 'class', "grid grid-cols-2", 'attribute');
    $n = $.dom.push($n,$t);
    $.dom.text($n,"\n                    ");
    $.dom.rem($n," columns ");
    $.dom.text($n,"\n                    ");
    const var_collectionVar21 = $.col.init(scontrino,{"begin":"2"});
    const var_size22 = $.col.len(var_collectionVar21);
    if (var_size22) {
      for (const var_index23 of $.col.keys(var_collectionVar21)) {
        const item = $.col.get(var_collectionVar21, var_index23);
        const itemlist = $.listInfo(var_index23, var_size22);
        $.dom.text($n,"\n                        ");
        $t = $.dom.create("div",false,false);
        $.dom.attr($t, 'class', "bg-[--color-secondary3] flex items-center pl-[8px] h-[60px]", 'attribute');
        $n = $.dom.push($n,$t);
        const var_24 = yield $.xss(scontrino[item], "html");
        $.dom.append($n, var_24);
        $n = $.dom.pop($n);
        $.dom.text($n,"\n                    ");
      }
    }
    $.dom.text($n,"\n                ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n            ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n        ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n    ");
    $n = $.dom.pop($n);
    $.dom.text($n,"\n");
  };
  $.template('global', 'render', render);

  return $.run(function* () {
    let $t, $n = $.dom.start();
    return $.dom.end();

  });
};
